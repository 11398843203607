import {Link} from "react-router-dom";
import Backdrop from "bootstrap/js/src/util/backdrop";
import {ModuleButton} from "../components/ModuleButton";
import {paths} from "../utils/pages";

export const Moduli = () => {
    return (
        <>
            <div className="remove-left-padding vh-100 bg-white pt-4">
                <div className="text-center">
                    <Link to="/">
                        <img src="/logo.svg" height={30} alt=""/>
                    </Link>
                </div>
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-12">
                            <h3><b>Seleziona i moduli</b></h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-4 col-lg-4 col-md-6">
                            <ModuleButton text={'Trust'} />
                        </div>
                        <div className="mt-4 col-lg-4 col-md-6">
                            <ModuleButton iconColor={'#0075C4'} text={'Benessere'} borderColor={'#0075C4'} textColor={'#0075C4'} />
                        </div>
                        <div className="mt-4 col-lg-4 col-md-6">
                            <ModuleButton iconColor={'#fff'} text={'Inclusione'} borderColor={'#05438A'} bgColor={'#05438A'} textColor={'#fff'} />
                        </div>
                        <div className="mt-4 col-lg-4 col-md-6">
                            <ModuleButton iconColor={'#0B3954'} text={'Leadership, clima e identificazione'} borderColor={'#0B3954'} textColor={'#0B3954'} />
                        </div>
                        <div className="mt-4 col-lg-4 col-md-6">
                            <ModuleButton iconColor={'#fff'} text={'Bisogni lavorativi'} borderColor={'#05438A'} bgColor={'#05438A'} textColor={'#fff'} />
                        </div>
                        <div className="mt-4 col-lg-4 col-md-6">
                            <ModuleButton iconColor={'#0075C4'} text={'Valori e integrità'} borderColor={'#0075C4'} textColor={'#0075C4'} />
                        </div>
                        <div className="mt-4 col-lg-4 col-md-6">
                            <ModuleButton text={'Lead e discovery'} />
                        </div>
                        <div className="mt-4 col-lg-4 col-md-6">
                            <ModuleButton iconColor={'#0075C4'} text={'SNA e Identificazione dei promotori del cambiamento'} borderColor={'#0075C4'} textColor={'#0075C4'} />
                        </div>
                        <div className="mt-4 col-lg-4 col-md-6">
                            <ModuleButton iconColor={'#fff'} text={'Propensione alla sostenibilità'} borderColor={'#05438A'} bgColor={'#05438A'} textColor={'#fff'} />
                        </div>
                        <div className="mt-4 col-lg-4 col-md-6">
                            <ModuleButton iconColor={'#fff'} text={'Smart working KPI'} borderColor={'#0B3954'} bgColor={'#0B3954'} textColor={'#fff'} />
                        </div>
                        <div className="mt-4 col-lg-4 col-md-6">
                            <ModuleButton iconColor={'#05438A'} text={'Misurazione abilità cognitive, relazionali, motivazionali'} borderColor={'#05438A'} bgColor={'#fff'} textColor={'#05438A'} />
                        </div>
                        <div className="mt-4 col-lg-4 col-md-6">
                            <ModuleButton iconColor={'#fff'} text={'Misurazione abilità cognitive, relazionali, motivazionali'} borderColor={'#0075C4'} bgColor={'#0075C4'} textColor={'#fff'} />
                        </div>
                        <div className="mt-4 col-lg-4 col-md-6">
                            <ModuleButton iconColor={'#fff'} text={'La tua survey'} borderColor={'#009DDD'} bgColor={'#009DDD'} textColor={'#fff'} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mt-5 text-end">
                            <a href={paths.partecipanti} className="simple">Avanti</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

import {TablePartecipanti} from "../components/TablePartecipanti";
import {ButtonWithIcon} from "../components/ButtonWithIcon";
import {PrevNext} from "../components/PrevNext";
import {buttonIcons} from "../utils/buttonIcons";

export const Partecipanti = () => {

    return (
        <>
            <div className="p-4">
                <TablePartecipanti />
            </div>
            <div className="text-end pe-5">
                <ButtonWithIcon text={"Conferma"} iconName={buttonIcons.check} />
                <div className="d-inline-block mx-3">
                    <ButtonWithIcon bgColor={'transparent'} text={"Modifica manuale"} color={'#0B3954'} borderColor={'#0B3954'} iconName={buttonIcons.edit} />
                </div>
                <ButtonWithIcon text={"Carica nuovo file dati"} iconName={buttonIcons.upload} />
            </div>
            <PrevNext />
        </>
    )

}

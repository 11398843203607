export const ButtonWithIcon = ({text, iconName, bgColor = '#05438A', color = '#fff', borderColor, iconPosition = 'left'}) => {
    return (
        <>
            <button className="simple" style={{
                border: borderColor ? "solid 2px " + borderColor : 'none',
                background: bgColor
            }}>
                {
                    iconName && iconPosition == 'left' && <img style={{marginRight: 10, verticalAlign: 'middle'}} src={'/icons/' + iconName + '.svg'} alt=""/>
                }
                <span style={{verticalAlign: 'middle', color: color}}>{text}</span>
                {
                    iconName && iconPosition == 'right' && <img style={{marginLeft: 10, verticalAlign: 'middle'}} src={'/icons/' + iconName + '.svg'} alt=""/>
                }
            </button>
        </>
    )
}

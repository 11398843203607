import Filters from "../components/Filters";

const Network = () => {
    return (
        <>
        <div className="container-fluid px-0">
            <Filters filters={['Network generico', 'Network team']}></Filters>
        </div>
        </>
    )
}

export default Network
import Chip from "./Chip";

const ActiveFilters = ({filters = ['complessivo']}) => {
    function onChipClosed(e) {
        console.log(e)
    }

    return (
        <div className="row align-items-center">
            <div className="col-auto">
                filtra per
            </div>
            {
                filters.map((e, i) => {
                    return (
                        <div key={'chip_' + i} className="col-auto">
                            <Chip onClose={onChipClosed} text={e}/>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ActiveFilters

import {useEffect, useState} from "react";
import {useWindowSize} from "../utils/windowSize";
import * as d3 from "d3";
import {benessere} from "../fake/Benessere";
import Plot from "react-plotly.js";

const ScatterPlotlyGrouped = () => {
    const [data, setData] = useState([])
    const size = useWindowSize()
    useEffect(() => {
        d3.csv("/data/leadership_style.csv").then((rows) => {

            var trace1 = {
                y: rows.filter(e => e.contesto == 'ideale').map(e => e.costrutto),
                x: rows.filter(e => e.contesto == 'ideale').map(e => e.score),
                name: 'Ideale',
                type: 'scatter',
                mode: 'markers',
                marker: {size: 20},
                opacity: 0.80
            };

            var trace2 = {
                y: rows.filter(e => e.contesto == 'azienda').map(e => e.costrutto),
                x: rows.filter(e => e.contesto == 'azienda').map(e => e.score),
                name: 'Azienda',
                type: 'scatter',
                mode: 'markers',
                marker: {size: 15},
                opacity: 0.55
            };

            var trace3 = {
                y: rows.filter(e => e.contesto == 'team').map(e => e.costrutto),
                x: rows.filter(e => e.contesto == 'team').map(e => e.score),
                name: 'Team',
                type: 'scatter',
                mode: 'markers',
                marker: {size: 10},
                opacity: 0.30
            };

            var data = [trace1, trace2, trace3];
            setData(data)
        })
    }, [])

    // linea di tendenza con dei valori calcolati
    // un grafico su un altro

    return <div className="col-12">
        <div className="overflow-hidden box  mt-4">
            <Plot
                style={{width: '100%'}}
                useResizeHandler={true}
                data={data}
                config={{editable: false}}
                layout={{
                    scattermode: 'group',
                    dragMode: false,
                    // showlegend: false,
                    xaxis: {
                        // tickformat: ',.0%',
                        title: 'Score',
                        // range: [0.075, .525]
                    },
                    // yaxis: {
                    //     tickformat: ',.0%',
                    //     title: '',
                    //     range: [0, 1]
                    // },
                    // responsive: true,
                    // autosize: true,
                    width: '100%',
                    // title: 'Data Labels on the Plot'
                }}
            />
        </div>
    </div>
}
export default ScatterPlotlyGrouped

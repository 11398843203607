import {useEffect, useState} from "react";
import {useWindowSize} from "../utils/windowSize";
import * as d3 from "d3";
import {benessere} from "../fake/Benessere";
import Plot from "react-plotly.js";

const ScatterPlotlyTrendlineHorizontal = ({}) => {
    const [data, setData] = useState([])
    const size = useWindowSize()
    useEffect(() => {
        d3.csv("/data/leader_disc_performance.csv").then((rows) => {
            var trace1 = []
            var h = [{v: 'negativo', c: 'red'}, {v: 'basso', c: 'blue'}, {v: 'normale', c: 'orange'}, {
                v: 'positivo',
                c: 'purple'
            }]
            h.forEach(h => {
                trace1.push(
                    {
                        x: rows.filter(e => e.status == h.v).map(e => e.totale),
                        y: rows.filter(e => e.status == h.v).map(e => e.scarti_pct),
                        mode: 'markers+text',
                        type: 'scatter',
                        name: h.v,
                        // text: ['A-1', 'A-2', 'A-3', 'A-4', 'A-5'],
                        textposition: 'top center',
                        marker: {size: 3, color: h.c}
                    }
                )
            })

            // var trace2 = {
            //     x: [.15, .25, .35, .45, .55],
            //     y: [0.4, 0.1, 0.7, 0.1, 0.4],
            //     mode: 'markers+text',
            //     type: 'scatter',
            //     name: 'Team B',
            //     text: ['B-a', 'B-b', 'B-c', 'B-d', 'B-e'],
            //     textposition: 'bottom center',
            //     marker: {size: 10, color: 'black'}
            // };

            var trace2 = {
                y: rows.map(e => e.sd_scartipct/2),
                x: rows.map(e => e.totale),
                mode: 'lines',
                type: 'scatter',
                name: 'Area di variazione normale',
                text: ['B-a', 'B-b', 'B-c', 'B-d', 'B-e'],
                textposition: 'bottom center',
            };

            var trace3 = {
                y: rows.map(e => -e.sd_scartipct/2),
                x: rows.map(e => e.totale),
                mode: 'lines',
                type: 'scatter',
                name: 'Area di variazione normale',
                text: ['B-a', 'B-b', 'B-c', 'B-d', 'B-e'],
                textposition: 'bottom center',
            };

            var data = [trace2, trace3, ...trace1];
            setData(data)
        })
    }, [])


    return <div className="col-12">
        <div className="overflow-hidden box  mt-4">
            <Plot
                style={{width: '100%'}}
                useResizeHandler={true}
                data={data}
                config={{editable: false}}
                layout={{
                    dragMode: false,
                    // showlegend: false,
                    xaxis: {
                        // tickformat: ',.0%',
                        title: 'N. preferenze ricevibili',
                        // range: [0.075, .525]
                        range: [0, 250]
                    },
                    yaxis: {
                        tickformat: ',.0%',
                        // title: 'Presenza del comportamento in azienda',
                        title: 'Performance',
                        range: [-1.5, 1.5]
                        // range: [0, 250]
                    },
                    responsive: true,
                    autosize: true,
                    width: '100%',
                    // title: 'Data Labels on the Plot'
                }}
            />
        </div>
    </div>
}
export default ScatterPlotlyTrendlineHorizontal

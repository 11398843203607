export const InfoIcon = ({color = '#009ddd'}) => {

    return (
        <div className="info-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
                <g id="Icon_feather-info" transform="translate(-1.5 -1.5)">
                    <path id="Tracciato_234" d="M29,16A13,13,0,1,1,16,3,13,13,0,0,1,29,16Z" fill="none" stroke={color}
                          strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                    <path id="Tracciato_235" d="M18,23.2V18" transform="translate(-2 -2)" fill="none" stroke={color}
                          strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                    <path id="Tracciato_236" d="M18,12h0" transform="translate(-2 -1.2)" fill="none" stroke={color}
                          strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                </g>
            </svg>
        </div>
    )

}

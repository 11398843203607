import ActiveFilters from "../components/ActiveFilters";
import Filters from "../components/Filters";
import DonutsLayout from "../components/DonutsLayout";
import RadarChart from "../components/RadarChart";
import ViolinLayout from "../components/ViolinLayout";
import {ViolinChart} from "../components/ViolinChart";
import Network from "../components/Network";
import Wellbeing from "../components/Wellbeing";
import Inclusion from "../components/inclusion";
import Trust from "../components/trust";
import Integrity from "../components/integrity";
import JobSatisfaction from "../components/job_satisfaction";
import Leadership from "../components/leadership";
import ScatterPlotly from "../components/ScatterPlotlyTrendline";
import BarChart from "../components/BarChart";
import ScatterPlotlyGrouped from "../components/ScatterPlotlyGrouped";
import ScatterPlotlyTrendline from "../components/ScatterPlotlyTrendline";
import ScatterPlotlyTrendlineHorizontal from "../components/ScatterPlotlyTrendlineHorizontal";

const InDeep = () => {
    return (
        <>
            <Filters
                filters={['Wellbeing', 'Inclusion', 'Trust', 'Integrity', 'Job satisfaction', 'Leadership | OI | Climate']}/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 py-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ActiveFilters/>
                                </div>
                                <DonutsLayout/>
                                <ScatterPlotlyTrendline/>
                                <ScatterPlotlyTrendlineHorizontal/>
                                <RadarChart unique={1}/>
                                <div className="col-12 mt-4">
                                    <div className="box">
                                        <ViolinChart multiple={true}
                                                     xName={'durata_minuti'}
                                                     data_url={'/data/tempi_di_compilazione.csv'}/>
                                    </div>
                                </div>
                                <div className="col-12 mt-4">
                                    <div className="box">
                                        <ViolinChart multiple={true}
                                                     xName={'completed_on'}
                                                     data_url={'/data/distribuzione_temporale_risposte.csv'}/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <BarChart/>
                                </div>
                                <ViolinLayout/>
                                <ScatterPlotlyGrouped/>
                                <Network/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InDeep

const PeopleDiscovery = () => {
    return (
        <>
            <div className="container mt-3">
                <div className="box">
                    People discovery
                </div>
            </div>
        </>
    )

}

export default PeopleDiscovery
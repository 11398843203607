import {ButtonWithIcon} from "./ButtonWithIcon";
import {buttonIcons} from "../utils/buttonIcons";

export const TablePartecipanti = () => {

    const head = [
        'Nome', 'Cognome', 'Genere', 'Indirizzo mail', 'Variabile 1', 'Variabile 2', 'Variabile 3', 'Variabile 4'
    ]
    const colW = []

    function getw(i) {
        return i === 2 ? 100 : 250
    }

    return (
        <>
            <div className="table-outer pb-3" style={{overflowX: 'auto'}}>
                <div className="head" style={{whiteSpace: 'nowrap', fontSize: '1.1rem'}}>
                    {
                        head.map((e, i) => <div style={{width: getw(i)}}
                                                className=" px-2 d-inline-block title color-blue-dark">
                            <b>{e}</b>
                        </div>)
                    }
                </div>
                {
                    [0, 1, 2, 3, 4, 5].map((e, i) => {
                        return (
                            <div className="body-row mt-3" style={{whiteSpace: 'nowrap', fontSize: '1.1rem'}}>
                                {
                                    head.map((e, i) => {
                                        return <div className=" px-2 d-inline-block" style={{width: getw(i)}}>
                                            <input type="text"/>
                                        </div>
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
            <div className="mt-4">
                <div className="me-3 d-inline-block">
                    <ButtonWithIcon iconName={buttonIcons.plus} text={'Aggiungi riga'} />
                </div>
                <ButtonWithIcon iconName={buttonIcons.trash} bgColor={'#FE5F55'} text={'Elimina'} />
            </div>
        </>
    )
}

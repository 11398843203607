import {useEffect, useState} from "react";
import * as d3 from "d3";
import Plot from 'react-plotly.js';
import {benessere} from "../fake/Benessere";
import {useWindowSize} from "../utils/windowSize";

export const ViolinChart = ({unique, data_url = null, multiple = false, xName = ''}) => {
    const [data, setData] = useState([])
    const size = useWindowSize()
    useEffect(() => {
        d3.csv(data_url ? data_url : "/data/ottimismo.csv").then((rows) => {
            if (data_url) {
                console.log(rows)
            }

            function unpack(rows, key) {
                return rows.map(function (row) {
                    return row[key]
                });
            }

            var trace = {
                type: 'violin',
                points: false,
                pointpos: 0,
                box: {
                    visible: false
                },
                boxpoints: false,
                line: {
                    color: 'rgba(0,157,221,1)'
                },
                fillcolor: 'rgba(0,157,221,.44)',
                opacity: 1,
                showlegend: false,
                jitter: 1,
                meanline: {
                    visible: true
                },
                y0: "Score"
            }

            var data = []
            if (multiple) {
                // test from https://plotly.com/javascript/violin/#multiple-traces-violin-plot
                // data[0]['transforms'] = [{
                //     type: 'groupby',
                //     groups: unpack(rows, 'parte'),
                //     styles: [
                //         {target: '1', value: {line: {color: 'blue'}}},
                //         {target: '2', value: {line: {color: 'orange'}}},
                //         {target: '3', value: {line: {color: 'green'}}},
                //         {target: '4', value: {line: {color: 'red'}}}
                //     ]
                // }]
                // data[0]['x'] = unpack(rows, 'parte');
                data.push({
                    ...trace,
                    x: unpack(rows.filter(e => e.parte == '1'), xName),
                    y0: '1'
                })
                data.push({
                    ...trace,
                    x: unpack(rows.filter(e => e.parte == '2'), xName),
                    y0: '2'
                })
                data.push({
                    ...trace,
                    x: unpack(rows.filter(e => e.parte == '3'), xName),
                    y0: '3'
                })
                data.push({
                    ...trace,
                    x: unpack(rows.filter(e => e.parte == '4'), xName),
                    y0: '4'
                })
                console.log(data)
                // data[0]['x'] = unpack(rows, xName);
            } else {
                data.push(trace)
                data[0]['x'] = unpack(benessere, 'score');
            }
            setData(data)
        });
    }, [])
// +39 334 1632368

    return (<div className="overflow-hidden">
        <Plot
            style={{width: '100%'}}
            useResizeHandler={true}
            data={data}
            config={{editable: false}}
            layout={{
                width: '100%',
                xaxis: {
                    zeroline: false
                }
            }}
        />
    </div>)
}

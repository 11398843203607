import {Chart} from "chart.js";
import 'chart.js/auto'
import {useEffect} from "react";

const RadarChart = ({unique}) => {

    const color1 = '#FE5F55'
    const color2 = '#009DDD'
    useEffect(() => {
        const ctx = document.getElementById(unique);
        const c = new Chart(ctx, {
            type: 'radar',
            data: {
                labels: [
                    'Autoritario',
                    'Partecipativo',
                    'Amichevole',
                ],
                datasets: [{
                    data: [65, 59, 90],
                    fill: false,
                    borderColor: color1,
                    pointBackgroundColor: color1,
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: color1,
                }, {
                    data: [28, 48, 40],
                    fill: false,
                    borderColor: color2,
                    pointBackgroundColor: color2,
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: color2,
                }]
            },
            options: {
                scales: { // <-- Note change in options from scale to scales
                    r: {
                        grid: {
                            circular: true
                        },
                        beginAtZero: true
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    },
                },
                elements: {
                    line: {
                        borderWidth: 3
                    }
                }
            },
        });
        return () => {
            c.destroy()
        }
    })

    const legendStyle = {
        height: 30,
        width: 30,
        borderRadius: '50%',
        marginLeft: 30
    }

    return (
        <div className="col-12 mt-3">
            <div className="box">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="box-title">Stile di leadership atteso</div>
                        <div className="h-100 d-flex align-items-center justify-content-start">
                            <div className="">
                                <div className="d-flex align-items-center justify-content-center">
                                    Stile di leadership percepito
                                    <div className="" style={{
                                        backgroundColor: color1,
                                        ...legendStyle
                                    }}></div>
                                </div>
                                <div className="" style={{height: 30}}></div>
                                <div className="d-flex align-items-center justify-content-center">
                                    Stile di leadership percepito
                                    <div className="" style={{
                                        backgroundColor: color2,
                                        ...legendStyle
                                    }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="radar-outer me-auto">
                            <canvas id={unique}></canvas>
                        </div>
                    </div>
                    <div className="col-lg-3"></div>
                </div>
            </div>
        </div>
    )
}

export default RadarChart

import {Outlet, Link, ScrollRestoration, useLocation} from "react-router-dom";
import {useState} from "react";
import {pages, paths} from "../utils/pages";

const Layout = () => {
    const [filtersState, setFiltersState] = useState({opacity: 0, pointerEvents: 'none'})
    const location = useLocation();
    const icons_height = 27
    const filters = [
        'Complessivo', 'Sedi', 'Unità organizzative', 'Fasce di età', 'Linee gerarchiche', 'Seniority', 'Fasce reddituali', 'Lingue', 'Giorni assenza'
    ]

    function isNavVisible(): boolean {
        return location.pathname !== paths.moduli && location.pathname !== paths.partecipanti && location.pathname !== paths.presentazione
        && location.pathname !== paths.valori && location.pathname !== paths.somministrazioni //...
    }

    function isTopNavVisible(): boolean {
        return location.pathname !== paths.moduli //...
    }

    function areDashboardFiltersVisible(): boolean {
        return location.pathname !== paths.moduli && location.pathname !== paths.partecipanti //...
    }

    function areSurverySidebarVisible(): boolean {
        return location.pathname === paths.partecipanti || location.pathname === paths.presentazione || location.pathname === paths.valori || location.pathname === paths.somministrazioni
    }

    function fullNav() {
        return location.pathname === paths.partecipanti || location.pathname === paths.presentazione || location.pathname === paths.valori || location.pathname === paths.somministrazioni
    }

    return (
        <>
            <div className="top-nav">
                {
                    isTopNavVisible() && <div className={(fullNav() ? 'container-fluid' : 'container') + ' py-3'}>
                        <div className="row justify-content-between align-content-center">
                            <div className="col-auto">
                                <Link to="/">
                                    <img src="/logo.svg" height={30} alt=""/>
                                </Link>
                            </div>
                            <div className="col-auto">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <a href={paths.moduli}>
                                            <img src="/icons/plus.svg" height={icons_height} alt=""/>
                                        </a>
                                    </div>
                                    <div className="col-auto">
                                        <img src="/icons/bell.svg" height={icons_height + 5} alt=""/>
                                    </div>
                                    <div className="col-auto">
                                        <img src="/icons/settings.svg" height={icons_height} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    isNavVisible() && <nav className="nav-filters py-3 bg-blue" style={{overflowX: 'auto'}}>
                        <div className="container">
                            <div className="row flex-nowrap">
                                {
                                    pages.map((e, i) => {
                                        return (
                                            <div key={'nav_' + i} className={'col-auto'}>
                                                <div
                                                    className={'px-0 ' + (i === 0 ? 'pe-lg-4' : i === pages.length - 1 ? 'ps-lg-4' : 'px-lg-4')}>
                                                    <Link to={e.link}
                                                          className={'color-white text-decoration-none ' + (location.pathname === e.link ? 'selected' : '')}>
                                                        <strong>{e.name}</strong>
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </nav>
                }
            </div>
            {
                areDashboardFiltersVisible() && <div className="filters-nav">
                    <img src="/icons/filtri.svg" onClick={() => setFiltersState({opacity: 1, pointerEvents: 'all'})} alt=""
                         className="filter-icon"/>
                    <div className="filter-list" style={filtersState}>
                        <img onClick={() => setFiltersState({opacity: 0, pointerEvents: 'none'})}
                             src="/icons/close-filters.svg" alt="" className="close-filters"/>
                        {
                            filters.map((e, i) => {
                                return <div key={'filter_' + i} className="filter">{e}</div>
                            })
                        }
                    </div>
                </div>
            }
            {
                areSurverySidebarVisible() && <div className="filters-nav survey-filters">
                    {
                        [paths.partecipanti, paths.presentazione, '', paths.valori, paths.somministrazioni, ''].map((e, i) => {
                            return (
                                <a key={'a_' + i} href={e} className="d-block w-100 text-center">
                                    <img src={'/icons/survey-icon-' + (i + 1) + '.svg'} alt="" className="filter-icon"/>
                                </a>
                            )
                        })
                    }
                </div>
            }

            <Outlet/>
            <ScrollRestoration/>
        </>
    )
};

export default Layout;

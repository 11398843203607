import {ButtonWithIcon} from "./ButtonWithIcon";
import {buttonIcons} from "../utils/buttonIcons";

export const PrevNext = ({padding}) => {
    return (
        <div className={padding ? padding : 'p-5'}>
            <div className="d-flex align-items-center justify-content-between">
                <ButtonWithIcon bgColor={'#FAAA8D'} text={'Indietro'} iconName={buttonIcons.left} />
                <ButtonWithIcon bgColor={'#17B890'} text={'Avanti'} iconName={buttonIcons.right} />
            </div>
        </div>
    )
}

import Graph from 'react-vis-network-graph';
import network from "../fake/Network";
import {networkLink} from "../fake/NetworkLink";
import {useCallback, useEffect, useMemo, useState} from "react";

const Network = () => {
    var graph = {
        nodes: network,
        edges: networkLink
    };

    var options = {
        nodes: {
            shape: "dot",
            fixed: {
                x: true, y: true
            },
            size: 20
        },
        edges: {
            color: {
                opacity: 0.2
            }
        },
        physics: false,
    };

    var events = {
        select: function (event) {
            var {nodes, edges} = event;
            console.log(event)
        }
    };


    return (
        <div style={{height: 1000}}>
            <Graph
                graph={graph}
                options={options}
                events={events}
                // style={style}
                // getNetwork={this.getNetwork}
                // getEdges={this.getEdges}
                // getNodes={this.getNodes}
                vis={vis => {
                    console.log(vis)
                }}
            />
        </div>
    )
}

export default Network

import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from "react-router-dom";
import Menu from "./components/Menu";
import React, {lazy, Suspense} from "react";
import Loader from "./components/Loader";
import './styles/global.scss'
import InDeep from "./pages/InDeep";
import {Moduli} from "./pages/Moduli";
import {paths} from "./utils/pages";
import {Partecipanti} from "./pages/Partecipanti";
import {Presentazione} from "./pages/Presentazione";
import {Valori} from "./pages/Valori";
import {Somministrazioni} from "./pages/Somministrazioni";
import Network from "./pages/Network";
import LeaderDiscovery from "./pages/leader_discovery";
import Analytics from "./pages/analytics";

const Home = lazy(() => import('./pages/Home'));
const NoPage = lazy(() => import('./pages/NoPage'));
const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Menu/>}>
            <Route index element={<Home/>}/>
            <Route path={paths.dashboard} element={<Home/>}/>
            <Route path={paths.in_deep} element={<InDeep/>}/>
            <Route path={paths.moduli} element={<Moduli/>}/>
            <Route path={paths.valori} element={<Valori/>}/>
            <Route path={paths.somministrazioni} element={<Somministrazioni/>}/>
            <Route path={paths.partecipanti} element={<Partecipanti/>}/>
            <Route path={paths.presentazione} element={<Presentazione/>}/>
            <Route path={paths.network} element={<Network/>}/>
            <Route path={paths.leader_discovery} element={<LeaderDiscovery/>}/>
            <Route path={paths.analytics} element={<Analytics/>}/>
            <Route path="*" element={<NoPage/>}/>
        </Route>
    )
);
const App = () => {
    return (
        <Suspense fallback={<Loader/>}>
            <RouterProvider router={router}/>
        </Suspense>
    );
}

export default App;

import {ButtonWithIcon} from "../components/ButtonWithIcon";
import {PrevNext} from "../components/PrevNext";

export const Somministrazioni = () => {
    return (
        <>
            <div className="container-fluid" style={{paddingTop: 200, paddingBottom: 50}}>
                <div className="row">
                    <div className="col-lg-6 text-center">
                        <div className="m-auto" style={{maxWidth: 500}}>
                            <div className="color-blue-dark" style={{fontSize: '1.7rem'}}>
                                <b>In quante parti vuoi somministrare (e scomporre) le interviste?</b>
                            </div>
                            <div className=" mt-4  text-center d-flex align-items-center justify-content-center">
                                <div className="number-circle">1</div>
                                <div className="number-circle selected">2</div>
                                <div className="number-circle">3</div>
                                <div className="number-circle">4</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 text-center">
                        <div className="color-blue-dark m-auto mt-5 mt-lg-0" style={{maxWidth: 500}}>
                            <div className="">
                                <div className="color-blue-dark" style={{fontSize: '1.7rem'}}>
                                    <b>Stima tempo complessivo</b>
                                </div>
                                <div className="mt-5" style={{fontWeight: 'bold', fontSize: '5rem'}}>
                                    45 Minuti
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pn-shadow bg-white" style={{position: 'fixed', left: 70, bottom: 0, right: 0}}>
                <PrevNext padding={'p-4'}/>
            </div>
        </>
    )
}

import DonutChart, {GoogleDonutChart} from "./DonutChart";

const DonutsLayout = () => {
    const charts = [1, 2, 3]
    return (
        <div className="container">
            <div className="row">
                {
                    charts.map((e, i) => {
                        return (
                            <div key={'d_' + i} className={'col-xl-4 mt-4'}>
                                <div className="box">
                                    <GoogleDonutChart/>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default DonutsLayout

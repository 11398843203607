import Filters from "../components/Filters";

const LeaderDiscovery = () => {
    return (
        <>
            <div className="container-fluid px-0">
                <Filters filters={['Leader discovery', 'High Impact People Discovery']}></Filters>
            </div>
        </>
    )
}

export default LeaderDiscovery
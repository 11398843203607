import Stars from "./Stars";

const Leadership = () => {
    return (
        <>
            <div className="container mt-3">
                <div className="box">
                    <Stars points={3} title="Leadership | OI | Climate"/>
                </div>
            </div>
        </>
    )

}

export default Leadership
import Stars from "./Stars";

const Inclusion = () => {
    return (
        <>
            <div className="container mt-3">
                <div className="box">
                    <Stars points={5} title="Inclusion"/>
                </div>
            </div>
        </>
    )

}

export default Inclusion
import {useEffect, useState} from "react";
import {useWindowSize} from "../utils/windowSize";
import * as d3 from "d3";
import {benessere} from "../fake/Benessere";
import Plot from "react-plotly.js";

const BarChart = () => {
    const [data, setData] = useState([])
    const size = useWindowSize()
    useEffect(() => {
        d3.csv("/data/tasso_di_partecipazione.csv").then((rows) => {
            var x = []
            var text1 = []
            var y = []
            var x2 = []
            var y2 = []
            rows.reverse().forEach(e => {
                x.push(e.complete)
                text1.push(e.label.replace('\n', ' - '))
                y.push(e.parte)
                x2.push(e.totali - e.complete)
                y2.push(e.parte)
            })
            var trace1 = {
                x: x,
                y: y,
                text: text1,
                name: 'Complete',
                orientation: 'h',
                marker: {
                    color: 'rgba(0,157,221,1)',
                    width: 1
                },
                type: 'bar'
            };
            var trace2 = {
                x: x2,
                y: y2,
                name: 'Incomplete',
                orientation: 'h',
                type: 'bar',
                marker: {
                    color: 'rgba(204,204,204,0.53)',
                    width: 1
                }
            };
            setData([trace1, trace2])
        })
    }, [])


    return <div className="col-12">
        <div className="overflow-hidden box mt-4">
            <Plot
                style={{width: '100%'}}
                useResizeHandler={true}
                data={data}
                config={{editable: false}}
                layout={{
                    showlegend: false,
                    barmode: 'stack'
                }}
            />
        </div>
    </div>
}
export default BarChart

import {InfoIcon} from "./InfoIcon";

export const ModuleButton = ({text, bgColor = '#fff', borderColor = '#009DDD', textColor = '#009DDD', iconColor = '#009DDD'}) => {

    return (
        <>
            <button className="w-100 module-button h-100 d-flex align-items-center justify-content-center"
                style={{
                    background: bgColor,
                    borderColor: borderColor,
                    color: textColor
                }}
            >
                {text}
                <InfoIcon color={iconColor} />
            </button>
        </>
    )

}

import {PieColors} from "../utils/PieColors";

const Stars = ({points = 5, n_stars = 6, title = 'Benessere', layout = 1, color = 'light-blue', perc = null, withTitle = true, percColor = PieColors.lightBlue.dark_code}) => {

    const stars = []

    function getStarIcon(icon_color) {
        return '/icons/star-' + icon_color + '.svg'
    }

    for (var i = 0; i < n_stars; i++) {
        stars.push((
            <img key={'star_ ' + i} className="star" src={i < points ? getStarIcon(color) : '/icons/star.svg'}
                 height={22} alt=""/>
        ))
    }

    return (
        <div className={'stars d-flex ' + (layout === 2 ? 'layout-2 flex-column align-items-start justify-content-center' : 'align-items-center')}>
            <div className={'d-flex align-items-center align-self-center layout-1 ' + (layout === 2 || layout === 3 ? 'order-2 layout-2-3' : '')}>
                {stars}
            </div>
            {
                withTitle && <div
                    className={'color-blue star-title ' + (layout === 2 || layout === 3 ? 'layout-2-3 align-self-center order-1 p-0' : '') + (layout === 3 ? 'layout-3 pe-3 ' : '')}>
                    {title}
                </div>
            }
            {perc && <div className="order-3 star-title" style={{color: percColor}}><b>{perc}</b></div>}
        </div>
    )
}

export default Stars

const Chip = ({
                  classes = 'bg-light-blue color-white',
                  text = 'no text',
                  onClose
              }) => {
    return (
        <div className={'chip d-flex align-items-center ' + classes}>
            <div className="">{text}</div>
            <div className="">
                <img onClick={() => {
                    onClose(text)
                }
                } alt={'Close this chip'} src="/icons/close-chip.svg" className="d-block"
                     style={{height: '1.2em', paddingLeft: '.6em', cursor: 'pointer'}}/>
            </div>
        </div>
    )
}

export default Chip

import Wellbeing from "./Wellbeing";
import Inclusion from "./inclusion";
import Trust from "./trust";
import Integrity from "./integrity";
import JobSatisfaction from "./job_satisfaction";
import Leadership from "./leadership";
import {useState} from "react";
import NetworkGenerico from "./NetworkGenerico";
import NetworkTeam from "./NetworkTeam";
import TassoPartecipazione from "./TassoPartecipazione";
import TempiCompilazione from "./TempiCompilazione";
import DistribuzioneRisposte from "./DistribuzioneRisposte";
import PeopleDiscovery from "./PeopleDiscovery";
import Leader_discovery from "../pages/leader_discovery";
import LeaderDiscoveryComponent from "./LeaderDiscoveryComponent";


const Filters = ({filters = []}) => {
    // const filters = [
    //     'Wellbeing', 'Inclusion', 'Trust', 'Integrity', 'Job satisfaction', 'Leadership | OI | Climate'
    // ]
    const [selected, setSelected] = useState(null)
    const [isActive, setIsActive] = useState(false)

    const changeSelect = (e) => {
        setSelected(e)
        setIsActive(true)
    }

    return [
        <nav className="nav-filters-secondary" style={{overflowX: 'auto'}}>
            <div className="container">
                <div className="row flex-nowrap">
                    {
                        filters.map((e, i) => {
                            return <div key={'f_' + i} className="col-auto" onClick={() => changeSelect(e)}>
                                <div
                                    className={'px-0 ' + (i === 0 ? 'pe-lg-3' : i === filters.length - 1 ? 'ps-lg-3' : 'px-lg-3')}>
                                    <div className={'a ' + (selected == e ? "active" : "")}>
                                        {e}
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </nav>,
        <div className="container">
            {/* TODO: mettere id dei selezionati */}
            {
                selected == 'Wellbeing' &&
                <Wellbeing/>
            }
            {
                selected == 'Inclusion' &&
                <Inclusion/>
            }
            {
                selected == 'Trust' &&
                <Trust/>
            }
            {
                selected == 'Integrity' &&
                <Integrity/>
            }
            {
                selected == 'Job satisfaction' &&
                <JobSatisfaction/>
            }
            {
                selected == 'Leadership | OI | Climate' &&
                <Leadership/>
            }
            {
                selected == 'Network generico' &&
                <NetworkGenerico/>
            }
            {
                selected == 'Network team' &&
                <NetworkTeam></NetworkTeam>
            }
            {
                selected == 'Leader discovery' &&
                <LeaderDiscoveryComponent/>
            }
            {
                selected == 'High Impact People Discovery' &&
                <PeopleDiscovery/>
            }
            {
                selected == 'Tasso di partecipazione' &&
                <TassoPartecipazione/>
            }
            {
                selected == 'Tempi di compilazione' &&
                <TempiCompilazione/>
            }
            {
                selected == 'Distribuzione temporale delle risposte' &&
                <DistribuzioneRisposte/>
            }

        </div>
    ]
}

export default Filters

import {PrevNext} from "../components/PrevNext";

export const Valori = () => {
    return (
        <>
            <div className="container-fluid" style={{paddingTop: 80, paddingBottom: 100}}>
                <div className="row">
                    <div className="col-6">
                        <div className="title-valori px-4 py-3">
                            Nome valore aziendale
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="title-valori px-4 py-3">
                            Descrizione valore aziendale
                        </div>
                    </div>
                </div>
                {
                    ['', '', '', '', '', '', '', '', '', ''].map((e, i) => {
                        return (
                            <div className={'row '}
                                 style={{background: i % 2 === 0 ? 'rgba(0,0,0,.05)' : 'transparent'}}>
                                <div className="col-6">
                                    <div className="p-4">
                                        <div className="p-4" style={{border: 'solid 1px #707070', borderRadius: 20}}>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                            eirmod
                                            tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                                            At vero
                                            eos et accusam et justo.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="p-4">
                                        <div className="p-4" style={{border: 'solid 1px #707070', borderRadius: 20}}>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                            eirmod
                                            tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                                            At vero
                                            eos et accusam et justo.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="pn-shadow bg-white" style={{position: 'fixed', left: 70, bottom: 0, right: 0}}>
                <PrevNext padding={'p-4'}/>
            </div>
        </>
    )
}

var factor = 1800
var network = [
    {
        "id": "collaboratore261",
        "livelli": 1,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "21/7/2015",
        "anni_anz_conv": 7,
        "centro": "centro2",
        "gruppo": "B04 - Fotovoltaico a concentrazione",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede2",
        "mansione": "Esperto",
        "data_di_nascita": "10/9/1970",
        "eta": 52,
        "titolo_di_studio": "Chimica Industr.",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 252,
        "generazione": "GenX",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#4CB400",
        "label": "collaboratore261",
        "x": factor *  -0.26535459577633025,
        "y": factor *  -0.7986789228837214
    },
    {
        "id": "collaboratore295",
        "livelli": 2,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "8/11/2021",
        "anni_anz_conv": 1,
        "centro": "centro7",
        "gruppo": "D11 - Geologia e Sicurezza Strutturale",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "7/5/1991",
        "eta": 31,
        "titolo_di_studio": "Ing.Ambientale",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 7796,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#A08CFF",
        "label": "collaboratore295",
        "x": factor *  0.17688929246919094,
        "y": factor *  -0.4756729467762524
    },
    {
        "id": "collaboratore101",
        "livelli": 3,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "20/10/1986",
        "anni_anz_conv": 36,
        "centro": "centro8",
        "gruppo": "A05 - Efficienza energetica",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede2",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "29/3/1964",
        "eta": 58,
        "titolo_di_studio": "Geometra",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -2104,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#D98F00",
        "label": "collaboratore101",
        "x": factor *  0.0007747087200093983,
        "y": factor *  -0.37883651485137526
    },
    {
        "id": "collaboratore126",
        "livelli": 4,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/5/2020",
        "anni_anz_conv": 2,
        "centro": "centro2",
        "gruppo": "B04 - Fotovoltaico a concentrazione",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede2",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "1/12/1989",
        "eta": 33,
        "titolo_di_studio": "Chimica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 7274,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#4CB400",
        "label": "collaboratore126",
        "x": factor *  -0.23792197826179506,
        "y": factor *  -0.6836987966351418
    },
    {
        "id": "collaboratore142",
        "livelli": 5,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "20/12/1984",
        "anni_anz_conv": 38,
        "centro": "centro7",
        "gruppo": "D11 - Geologia e Sicurezza Strutturale",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "26/10/1963",
        "eta": 59,
        "titolo_di_studio": "P.I.Elettronico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -2259,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#A08CFF",
        "label": "collaboratore142",
        "x": factor *  0.2596616839844905,
        "y": factor *  -0.5934506463776863
    },
    {
        "id": "collaboratore303",
        "livelli": 6,
        "ruolo": "Vice capo progetto",
        "gender": "Femminile",
        "data_anz_conv": "1/4/2019",
        "anni_anz_conv": 3,
        "centro": "centro7",
        "gruppo": "D06 - Qualità dell´aria e Modelli",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "14/2/1983",
        "eta": 39,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 4792,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00ADFB",
        "label": "collaboratore303",
        "x": factor *  0.4140177523546871,
        "y": factor *  -0.46009067305433937
    },
    {
        "id": "collaboratore094",
        "livelli": 7,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/1/2020",
        "anni_anz_conv": 2,
        "centro": "centro7",
        "gruppo": "D07 - ART Analisi risorse e territorio",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "15/1/1990",
        "eta": 32,
        "titolo_di_studio": "Ing.Ambientale",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 7319,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#1EA3FF",
        "label": "collaboratore094",
        "x": factor *  0.345333748892505,
        "y": factor *  -0.4342517739111983
    },
    {
        "id": "collaboratore129",
        "livelli": 8,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/1/2012",
        "anni_anz_conv": 10,
        "centro": "centro7",
        "gruppo": "D07 - ART Analisi risorse e territorio",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "17/6/1978",
        "eta": 44,
        "titolo_di_studio": "Scienze Ambientali",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 3089,
        "generazione": "GenX",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#1EA3FF",
        "label": "collaboratore129",
        "x": factor *  0.48476515652057395,
        "y": factor *  -0.3344446331595944
    },
    {
        "id": "collaboratore072",
        "livelli": 9,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "15/10/2018",
        "anni_anz_conv": 4,
        "centro": "centro3",
        "gruppo": "C12 - Sperimentazione componenti e apparati per sistemi T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "15/7/1968",
        "eta": 54,
        "titolo_di_studio": "P.I.Elettrotecnico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -535,
        "generazione": "GenX",
        "tenure": "4-5 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BECE",
        "label": "collaboratore072",
        "x": factor *  -0.7691900112684759,
        "y": factor *  -0.14852715357197732
    },
    {
        "id": "collaboratore057",
        "livelli": 10,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/1/2022",
        "anni_anz_conv": 0,
        "centro": "centro3",
        "gruppo": "C10 - Tecnologie ICT per gestione e sicurezza informatica reti T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "1/6/1995",
        "eta": 27,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 9282,
        "generazione": "Millennials",
        "tenure": "Meno di un anno",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C1A5",
        "label": "collaboratore057",
        "x": factor *  -0.4066630228732774,
        "y": factor *  0.17399385650441945
    },
    {
        "id": "collaboratore281",
        "livelli": 11,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/9/1995",
        "anni_anz_conv": 27,
        "centro": "centro7",
        "gruppo": "D07 - ART Analisi risorse e territorio",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "13/2/1961",
        "eta": 61,
        "titolo_di_studio": "Ing.Civile Idraulica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -3244,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#1EA3FF",
        "label": "collaboratore281",
        "x": factor *  0.17794552906874217,
        "y": factor *  -0.27395914824522527
    },
    {
        "id": "collaboratore013",
        "livelli": 12,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "26/7/2021",
        "anni_anz_conv": 1,
        "centro": "centro7",
        "gruppo": "D07 - ART Analisi risorse e territorio",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "21/6/1988",
        "eta": 34,
        "titolo_di_studio": "Ingegneria per l´Ambiente e il Territorio",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 6746,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#1EA3FF",
        "label": "collaboratore013",
        "x": factor *  0.4107817028488048,
        "y": factor *  -0.5773771026736365
    },
    {
        "id": "collaboratore269",
        "livelli": 14,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "15/3/2011",
        "anni_anz_conv": 11,
        "centro": "centro7",
        "gruppo": "D11 - Geologia e Sicurezza Strutturale",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "29/8/1981",
        "eta": 41,
        "titolo_di_studio": "Ing.Civile",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 4258,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#A08CFF",
        "label": "collaboratore269",
        "x": factor *  0.521824209486313,
        "y": factor *  0.7959846558317325
    },
    {
        "id": "collaboratore070",
        "livelli": 15,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "22/1/1979",
        "anni_anz_conv": 43,
        "centro": "centro5",
        "gruppo": "R01 - Servizi generali e tecnici",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede2",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "11/3/1960",
        "eta": 62,
        "titolo_di_studio": "P.I.Elettrotecnico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -3583,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FE6E89",
        "label": "collaboratore070",
        "x": factor *  -0.2582412915582122,
        "y": factor *  -0.5979712888374704
    },
    {
        "id": "collaboratore235",
        "livelli": 16,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/4/2011",
        "anni_anz_conv": 11,
        "centro": "centro3",
        "gruppo": "C08 - Sistemi e tecnologie innovative per le reti energetiche",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "8/2/1982",
        "eta": 40,
        "titolo_di_studio": "Ing.Elettrica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 4421,
        "generazione": "Millennials",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BE72",
        "label": "collaboratore235",
        "x": factor *  -0.8007340323948137,
        "y": factor *  -0.3982243904487561
    },
    {
        "id": "collaboratore158",
        "livelli": 17,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "12/1/2015",
        "anni_anz_conv": 7,
        "centro": "centro8",
        "gruppo": "A01 - Reti attive: gestione della distribuzione e della domanda",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "7/12/1980",
        "eta": 42,
        "titolo_di_studio": "Ing.Elettrotecnica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 3993,
        "generazione": "GenX",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#F07F4B",
        "label": "collaboratore158",
        "x": factor *  -0.10045409568031372,
        "y": factor *  -0.08218576692868995
    },
    {
        "id": "collaboratore025",
        "livelli": 18,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/11/2019",
        "anni_anz_conv": 3,
        "centro": "centro2",
        "gruppo": "B04 - Fotovoltaico a concentrazione",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede2",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "21/11/1988",
        "eta": 34,
        "titolo_di_studio": "Ing.Materiali",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 6899,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#4CB400",
        "label": "collaboratore025",
        "x": factor *  -0.24744080416677539,
        "y": factor *  -0.8918077158694016
    },
    {
        "id": "collaboratore127",
        "livelli": 19,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "17/9/2019",
        "anni_anz_conv": 3,
        "centro": "centro7",
        "gruppo": "D11 - Geologia e Sicurezza Strutturale",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "11/1/1987",
        "eta": 35,
        "titolo_di_studio": "Geologia",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 6219,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#A08CFF",
        "label": "collaboratore127",
        "x": factor *  0.13018422374294047,
        "y": factor *  -0.6698596627098344
    },
    {
        "id": "collaboratore005",
        "livelli": 20,
        "ruolo": "Capo Progetto RDS",
        "gender": "Maschile",
        "data_anz_conv": "13/10/1980",
        "anni_anz_conv": 42,
        "centro": "centro7",
        "gruppo": "D11 - Geologia e Sicurezza Strutturale",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "22/1/1959",
        "eta": 63,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile di Progetto RdS",
        "anno_nascita": -3997,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#A08CFF",
        "label": "collaboratore005",
        "x": factor *  0.049175425838926756,
        "y": factor *  -0.3744411763778166
    },
    {
        "id": "collaboratore218",
        "livelli": 21,
        "ruolo": "Capo Progetto RDS",
        "gender": "Maschile",
        "data_anz_conv": "2/1/2007",
        "anni_anz_conv": 15,
        "centro": "centro2",
        "gruppo": "B00 - Staff TGM",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "9/11/1978",
        "eta": 44,
        "titolo_di_studio": "Ing.Meccanica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile di Progetto RdS",
        "anno_nascita": 3234,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#A7A400",
        "label": "collaboratore218",
        "x": factor *  -0.07949233739228179,
        "y": factor *  -0.18485141494126334
    },
    {
        "id": "collaboratore114",
        "livelli": 22,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "13/1/2015",
        "anni_anz_conv": 7,
        "centro": "centro2",
        "gruppo": "B04 - Fotovoltaico a concentrazione",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede2",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "10/6/1969",
        "eta": 53,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -205,
        "generazione": "GenX",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#4CB400",
        "label": "collaboratore114",
        "x": factor *  -0.20446287101716398,
        "y": factor *  -0.6999574712321577
    },
    {
        "id": "collaboratore133",
        "livelli": 23,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "3/12/1990",
        "anni_anz_conv": 32,
        "centro": "centro3",
        "gruppo": "C08 - Sistemi e tecnologie innovative per le reti energetiche",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Assistente",
        "data_di_nascita": "13/7/1968",
        "eta": 54,
        "titolo_di_studio": "P.I.Elettrotecnico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -537,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BE72",
        "label": "collaboratore133",
        "x": factor *  -0.7327012775129123,
        "y": factor *  -0.32966372093521046
    },
    {
        "id": "collaboratore293",
        "livelli": 24,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "4/5/1987",
        "anni_anz_conv": 35,
        "centro": "centro9",
        "gruppo": "Q01 - Information and Communications Technology",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede2",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "1/1/1965",
        "eta": 57,
        "titolo_di_studio": "P.I.Informatico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -1826,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FF62BA",
        "label": "collaboratore293",
        "x": factor *  -0.35064621876565927,
        "y": factor *  -0.3321143177102953
    },
    {
        "id": "collaboratore111",
        "livelli": 25,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/1/2013",
        "anni_anz_conv": 9,
        "centro": "centro3",
        "gruppo": "C12 - Sperimentazione componenti e apparati per sistemi T&D",
        "qualifica": "Operaio",
        "sede_di_lavoro": "sede1",
        "mansione": "Operaio",
        "data_di_nascita": "22/11/1979",
        "eta": 43,
        "titolo_di_studio": "P.I.Elettrotecnico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": 3612,
        "generazione": "GenX",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BECE",
        "label": "collaboratore111",
        "x": factor *  -0.8970216438451603,
        "y": factor *  -0.22736690421334793
    },
    {
        "id": "collaboratore080",
        "livelli": 26,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/12/2020",
        "anni_anz_conv": 2,
        "centro": "centro4",
        "gruppo": "N00 - Staff SCV",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "24/7/1979",
        "eta": 43,
        "titolo_di_studio": "Scienze della Comunicazione",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 3491,
        "generazione": "GenX",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#D873FC",
        "label": "collaboratore080",
        "x": factor *  -0.13598664196541888,
        "y": factor *  -0.028198382288464163
    },
    {
        "id": "collaboratore173",
        "livelli": 27,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/1/2012",
        "anni_anz_conv": 10,
        "centro": "centro2",
        "gruppo": "B01 - Materiali per l´energia",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede2",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "26/1/1980",
        "eta": 42,
        "titolo_di_studio": "Chimica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 3677,
        "generazione": "GenX",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#90AA00",
        "label": "collaboratore173",
        "x": factor *  -0.18587357538519322,
        "y": factor *  -0.6653161168759025
    },
    {
        "id": "collaboratore195",
        "livelli": 28,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/1/2012",
        "anni_anz_conv": 10,
        "centro": "centro7",
        "gruppo": "D06 - Qualità dell´aria e Modelli",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "9/10/1984",
        "eta": 38,
        "titolo_di_studio": "Scienze Ambientali",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 5395,
        "generazione": "Millennials",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00ADFB",
        "label": "collaboratore195",
        "x": factor *  0.07015587418293157,
        "y": factor *  -0.603880839365218
    },
    {
        "id": "collaboratore248",
        "livelli": 29,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/1/2011",
        "anni_anz_conv": 11,
        "centro": "centro3",
        "gruppo": "C08 - Sistemi e tecnologie innovative per le reti energetiche",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "11/9/1981",
        "eta": 41,
        "titolo_di_studio": "Ing.Aeronautica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 4271,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BE72",
        "label": "collaboratore248",
        "x": factor *  -0.41323793945662324,
        "y": factor *  -0.2755537083502686
    },
    {
        "id": "collaboratore202",
        "livelli": 30,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/1/2011",
        "anni_anz_conv": 11,
        "centro": "centro3",
        "gruppo": "C11 - Misure e diagnostica di componenti T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "23/6/1982",
        "eta": 40,
        "titolo_di_studio": "Ing.Elettrica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 4556,
        "generazione": "Millennials",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C0BA",
        "label": "collaboratore202",
        "x": factor *  -0.7018008735878127,
        "y": factor *  -0.12950319353176787
    },
    {
        "id": "collaboratore305",
        "livelli": 31,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "3/6/1985",
        "anni_anz_conv": 37,
        "centro": "centro3",
        "gruppo": "C10 - Tecnologie ICT per gestione e sicurezza informatica reti T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Assistente  con comp. magg. ril.",
        "data_di_nascita": "16/1/1965",
        "eta": 57,
        "titolo_di_studio": "P.I.Elettronico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -1811,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C1A5",
        "label": "collaboratore305",
        "x": factor *  -0.5153272205643801,
        "y": factor *  -0.07903308885634308
    },
    {
        "id": "collaboratore271",
        "livelli": 32,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/1/2020",
        "anni_anz_conv": 2,
        "centro": "centro9",
        "gruppo": "Q00 - Staff ASI",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "13/8/1989",
        "eta": 33,
        "titolo_di_studio": "Economia",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 7164,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FE61CE",
        "label": "collaboratore271",
        "x": factor *  -0.36948659678136975,
        "y": factor *  -0.23999866775092205
    },
    {
        "id": "collaboratore003",
        "livelli": 33,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/11/2021",
        "anni_anz_conv": 1,
        "centro": "centro8",
        "gruppo": "A04 - Sviluppo e sicurezza delle reti",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "26/6/1991",
        "eta": 31,
        "titolo_di_studio": "Ing.Energetica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 7846,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#E58709",
        "label": "collaboratore003",
        "x": factor *  0.0920673926230211,
        "y": factor *  0.02522434415101782
    },
    {
        "id": "collaboratore320",
        "livelli": 34,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/1/2012",
        "anni_anz_conv": 10,
        "centro": "centro8",
        "gruppo": "A05 - Efficienza energetica",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "7/1/1985",
        "eta": 37,
        "titolo_di_studio": "Ing.Energetica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 5485,
        "generazione": "Millennials",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#D98F00",
        "label": "collaboratore320",
        "x": factor *  0.027523579752822158,
        "y": factor *  -0.12597205249043597
    },
    {
        "id": "collaboratore191",
        "livelli": 35,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "27/5/1985",
        "anni_anz_conv": 37,
        "centro": "centro2",
        "gruppo": "B01 - Materiali per l´energia",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Assistente",
        "data_di_nascita": "7/5/1963",
        "eta": 59,
        "titolo_di_studio": "P.I.Chimico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -2431,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#90AA00",
        "label": "collaboratore191",
        "x": factor *  -0.6823434628887797,
        "y": factor *  -0.8848250172384338
    },
    {
        "id": "collaboratore042",
        "livelli": 36,
        "ruolo": "Direttore di dipartimento",
        "gender": "Maschile",
        "data_anz_conv": "1/7/2021",
        "anni_anz_conv": 1,
        "centro": "centro8",
        "gruppo": "A00 - Staff SSE",
        "qualifica": "Dirigente",
        "sede_di_lavoro": "sede1",
        "mansione": "Direttore di Dipartimento",
        "data_di_nascita": "25/9/1962",
        "eta": 60,
        "titolo_di_studio": "Ing.Elettronica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Direttore",
        "anno_nascita": -2655,
        "generazione": "Boomers",
        "tenure": "1-3 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#F8766D",
        "label": "collaboratore042",
        "x": factor *  0.08317243648062855,
        "y": factor *  -0.08912075979992284
    },
    {
        "id": "collaboratore162",
        "livelli": 37,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/3/2008",
        "anni_anz_conv": 14,
        "centro": "centro1",
        "gruppo": "P00 - Staff HRP",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "15/9/1975",
        "eta": 47,
        "titolo_di_studio": "Scienze Politiche",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 2083,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#EA6AF0",
        "label": "collaboratore162",
        "x": factor *  -0.38006684392011814,
        "y": factor *  -0.1083783895465904
    },
    {
        "id": "collaboratore234",
        "livelli": 38,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "3/4/2000",
        "anni_anz_conv": 22,
        "centro": "centro5",
        "gruppo": "R01 - Servizi generali e tecnici",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede2",
        "mansione": "Assistente",
        "data_di_nascita": "19/6/1972",
        "eta": 50,
        "titolo_di_studio": "Geometra",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": 900,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FE6E89",
        "label": "collaboratore234",
        "x": factor *  -0.2763396165247697,
        "y": factor *  -0.6135111197023564
    },
    {
        "id": "collaboratore239",
        "livelli": 39,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/8/1980",
        "anni_anz_conv": 42,
        "centro": "centro8",
        "gruppo": "A05 - Efficienza energetica",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto con particolare responsabilità ed autonomia",
        "data_di_nascita": "27/7/1954",
        "eta": 68,
        "titolo_di_studio": "Ing.Meccanica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -5637,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#D98F00",
        "label": "collaboratore239",
        "x": factor *  0.20433550684510782,
        "y": factor *  0.2553568521076355
    },
    {
        "id": "collaboratore136",
        "livelli": 40,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "15/1/2016",
        "anni_anz_conv": 6,
        "centro": "centro7",
        "gruppo": "D11 - Geologia e Sicurezza Strutturale",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "5/6/1989",
        "eta": 33,
        "titolo_di_studio": "Geologia",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 7095,
        "generazione": "Millennials",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#A08CFF",
        "label": "collaboratore136",
        "x": factor *  0.05596937720982087,
        "y": factor *  -0.557072402421269
    },
    {
        "id": "collaboratore124",
        "livelli": 41,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "16/12/1985",
        "anni_anz_conv": 37,
        "centro": "centro8",
        "gruppo": "A01 - Reti attive: gestione della distribuzione e della domanda",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "26/7/1962",
        "eta": 60,
        "titolo_di_studio": "P.I.Elettrotecnico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -2716,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#F07F4B",
        "label": "collaboratore124",
        "x": factor *  -0.30643559254243247,
        "y": factor *  0.0434527131422453
    },
    {
        "id": "collaboratore006",
        "livelli": 42,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/3/2022",
        "anni_anz_conv": 0,
        "centro": "centro2",
        "gruppo": "B06 - Reti attive: automazione e controllo",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "8/8/1991",
        "eta": 31,
        "titolo_di_studio": "Ing.Informatica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 7889,
        "generazione": "Millennials",
        "tenure": "Meno di un anno",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B825",
        "label": "collaboratore006",
        "x": factor *  0.3050876025400633,
        "y": factor *  -0.16591199561847558
    },
    {
        "id": "collaboratore093",
        "livelli": 43,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/12/2014",
        "anni_anz_conv": 8,
        "centro": "centro3",
        "gruppo": "C10 - Tecnologie ICT per gestione e sicurezza informatica reti T&D",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "10/7/1974",
        "eta": 48,
        "titolo_di_studio": "Ing.Informatica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 1651,
        "generazione": "GenX",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C1A5",
        "label": "collaboratore093",
        "x": factor *  -0.3572528249081476,
        "y": factor *  0.19051290350339323
    },
    {
        "id": "collaboratore236",
        "livelli": 44,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "16/12/1991",
        "anni_anz_conv": 31,
        "centro": "centro3",
        "gruppo": "C08 - Sistemi e tecnologie innovative per le reti energetiche",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "23/9/1962",
        "eta": 60,
        "titolo_di_studio": "Ing.Elettrotecnica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -2657,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BE72",
        "label": "collaboratore236",
        "x": factor *  -0.7861707070134891,
        "y": factor *  -0.2866498835321485
    },
    {
        "id": "collaboratore123",
        "livelli": 45,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "24/2/1997",
        "anni_anz_conv": 25,
        "centro": "centro2",
        "gruppo": "B02 - Impianti e processi",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Assistente  con comp. magg. ril.",
        "data_di_nascita": "5/5/1976",
        "eta": 46,
        "titolo_di_studio": "P.I.Elettrotecnico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": 2316,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#74B000",
        "label": "collaboratore123",
        "x": factor *  -0.13070912454486439,
        "y": factor *  -0.3329066323325708
    },
    {
        "id": "collaboratore326",
        "livelli": 46,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "9/5/2016",
        "anni_anz_conv": 6,
        "centro": "centro7",
        "gruppo": "D05 - CLM Clima e Meteorologia",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "4/9/1985",
        "eta": 37,
        "titolo_di_studio": "Fisica ambientale e biomedica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 5725,
        "generazione": "Millennials",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B4EF",
        "label": "collaboratore326",
        "x": factor *  0.13240878713624182,
        "y": factor *  -0.5198994274853443
    },
    {
        "id": "collaboratore244",
        "livelli": 47,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/12/1986",
        "anni_anz_conv": 36,
        "centro": "centro4",
        "gruppo": "N00 - Staff SCV",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Assistente",
        "data_di_nascita": "3/2/1966",
        "eta": 56,
        "titolo_di_studio": "Licenza Media",
        "tipo": "Media",
        "responsabile": "NA",
        "anno_nascita": -1428,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#D873FC",
        "label": "collaboratore244",
        "x": factor *  -0.2333350845720571,
        "y": factor *  -0.04686907766962667
    },
    {
        "id": "collaboratore273",
        "livelli": 48,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "8/1/1979",
        "anni_anz_conv": 43,
        "centro": "centro3",
        "gruppo": "C12 - Sperimentazione componenti e apparati per sistemi T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "12/1/1957",
        "eta": 65,
        "titolo_di_studio": "P.I.Elettrotecnico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -4737,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BECE",
        "label": "collaboratore273",
        "x": factor *  -0.6326629667492127,
        "y": factor *  -0.1432669903216619
    },
    {
        "id": "collaboratore105",
        "livelli": 49,
        "ruolo": "Responsabile gruppo di ricerca",
        "gender": "Maschile",
        "data_anz_conv": "16/5/1988",
        "anni_anz_conv": 34,
        "centro": "centro8",
        "gruppo": "A05 - Efficienza energetica",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto con particolare responsabilità ed autonomia",
        "data_di_nascita": "8/5/1962",
        "eta": 60,
        "titolo_di_studio": "Chimica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile GdR",
        "anno_nascita": -2795,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#D98F00",
        "label": "collaboratore105",
        "x": factor *  0.12468920335400879,
        "y": factor *  -0.09991082929481387
    },
    {
        "id": "collaboratore186",
        "livelli": 50,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "3/9/2001",
        "anni_anz_conv": 21,
        "centro": "centro3",
        "gruppo": "C11 - Misure e diagnostica di componenti T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "10/7/1974",
        "eta": 48,
        "titolo_di_studio": "Ing.Elettrotecnica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 1651,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C0BA",
        "label": "collaboratore186",
        "x": factor *  -0.5404927581424668,
        "y": factor *  -0.17537193143306973
    },
    {
        "id": "collaboratore175",
        "livelli": 51,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/10/1986",
        "anni_anz_conv": 36,
        "centro": "centro1",
        "gruppo": "P00 - Staff HRP",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Assistente",
        "data_di_nascita": "31/10/1963",
        "eta": 59,
        "titolo_di_studio": "Linguist. Comm.",
        "tipo": "Professionale",
        "responsabile": "NA",
        "anno_nascita": -2254,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#EA6AF0",
        "label": "collaboratore175",
        "x": factor *  -0.40673043796541686,
        "y": factor *  -0.09301742829193449
    },
    {
        "id": "collaboratore319",
        "livelli": 52,
        "ruolo": "Vicedirettore di dipartimento",
        "gender": "Maschile",
        "data_anz_conv": "1/5/1988",
        "anni_anz_conv": 34,
        "centro": "centro3",
        "gruppo": "C00 - Staff TTD",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Vice Direttore di Dipartimento",
        "data_di_nascita": "23/4/1958",
        "eta": 64,
        "titolo_di_studio": "Ing.Nucleare",
        "tipo": "Laurea Magistrale",
        "responsabile": "Vice Direttore",
        "anno_nascita": -4271,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#00BC53",
        "label": "collaboratore319",
        "x": factor *  -0.4584135383310505,
        "y": factor *  -0.07550707356064135
    },
    {
        "id": "collaboratore265",
        "livelli": 53,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "2/3/1981",
        "anni_anz_conv": 41,
        "centro": "centro3",
        "gruppo": "C09 - Tecnologie per le reti attive di distribuzione",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "23/1/1960",
        "eta": 62,
        "titolo_di_studio": "P.I.Elettronico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -3631,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C08D",
        "label": "collaboratore265",
        "x": factor *  -0.6122727953702299,
        "y": factor *  0.14023708526942613
    },
    {
        "id": "collaboratore184",
        "livelli": 54,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/1/2004",
        "anni_anz_conv": 18,
        "centro": "centro7",
        "gruppo": "D08 - LCA Analisi ciclo di vita",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "2/11/1971",
        "eta": 51,
        "titolo_di_studio": "Ing.Ambientale",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 670,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#7498FF",
        "label": "collaboratore184",
        "x": factor *  0.17093880027861164,
        "y": factor *  -0.31068316851486544
    },
    {
        "id": "collaboratore270",
        "livelli": 55,
        "ruolo": "Capo Progetto RDS",
        "gender": "Maschile",
        "data_anz_conv": "22/9/1980",
        "anni_anz_conv": 42,
        "centro": "centro8",
        "gruppo": "A05 - Efficienza energetica",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto con particolare responsabilità ed autonomia",
        "data_di_nascita": "18/5/1960",
        "eta": 62,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile di Progetto RdS",
        "anno_nascita": -3515,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#D98F00",
        "label": "collaboratore270",
        "x": factor *  0.07107312204197158,
        "y": factor *  -0.2456357279996373
    },
    {
        "id": "collaboratore015",
        "livelli": 56,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/4/2020",
        "anni_anz_conv": 2,
        "centro": "centro6",
        "gruppo": "M00 - Staff DG",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "15/6/1987",
        "eta": 35,
        "titolo_di_studio": "Economia",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 6374,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#C07FFF",
        "label": "collaboratore015",
        "x": factor *  -0.5215706270285738,
        "y": factor *  -0.18946768787159918
    },
    {
        "id": "collaboratore131",
        "livelli": 57,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "29/3/2021",
        "anni_anz_conv": 1,
        "centro": "centro7",
        "gruppo": "D08 - LCA Analisi ciclo di vita",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "16/3/1993",
        "eta": 29,
        "titolo_di_studio": "Ing.Ambientale",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 8475,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#7498FF",
        "label": "collaboratore131",
        "x": factor *  0.3182506964525609,
        "y": factor *  -0.3879994459631806
    },
    {
        "id": "collaboratore010",
        "livelli": 58,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "24/8/2020",
        "anni_anz_conv": 2,
        "centro": "centro8",
        "gruppo": "A05 - Efficienza energetica",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "20/11/1993",
        "eta": 29,
        "titolo_di_studio": "Ing.Energetica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 8724,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#D98F00",
        "label": "collaboratore010",
        "x": factor *  0.21698398888650927,
        "y": factor *  -0.1497342368901824
    },
    {
        "id": "collaboratore229",
        "livelli": 59,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "19/5/1980",
        "anni_anz_conv": 42,
        "centro": "centro7",
        "gruppo": "D11 - Geologia e Sicurezza Strutturale",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Assistente  con comp. magg. ril.",
        "data_di_nascita": "12/8/1959",
        "eta": 63,
        "titolo_di_studio": "Licenza Media",
        "tipo": "Media",
        "responsabile": "NA",
        "anno_nascita": -3795,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#A08CFF",
        "label": "collaboratore229",
        "x": factor *  0.3977817162888211,
        "y": factor *  -0.20724557476292693
    },
    {
        "id": "collaboratore321",
        "livelli": 60,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/4/2016",
        "anni_anz_conv": 6,
        "centro": "centro3",
        "gruppo": "C00 - Staff TTD",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "9/10/1991",
        "eta": 31,
        "titolo_di_studio": "Ing.Elettrica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 7951,
        "generazione": "Millennials",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BC53",
        "label": "collaboratore321",
        "x": factor *  -0.23878740563552736,
        "y": factor *  0.015848818054564173
    },
    {
        "id": "collaboratore251",
        "livelli": 61,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/7/2019",
        "anni_anz_conv": 3,
        "centro": "centro2",
        "gruppo": "B04 - Fotovoltaico a concentrazione",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede2",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "15/3/1976",
        "eta": 46,
        "titolo_di_studio": "Chimica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 2265,
        "generazione": "GenX",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#4CB400",
        "label": "collaboratore251",
        "x": factor *  -0.2646450021005142,
        "y": factor *  -0.6490826226905289
    },
    {
        "id": "collaboratore004",
        "livelli": 62,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "16/9/1985",
        "anni_anz_conv": 37,
        "centro": "centro8",
        "gruppo": "A04 - Sviluppo e sicurezza delle reti",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "20/3/1964",
        "eta": 58,
        "titolo_di_studio": "P.I.Elettrotecnico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -2113,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#E58709",
        "label": "collaboratore004",
        "x": factor *  -0.10986106416338737,
        "y": factor *  -0.05786732218731139
    },
    {
        "id": "collaboratore074",
        "livelli": 63,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/10/2003",
        "anni_anz_conv": 19,
        "centro": "centro2",
        "gruppo": "B01 - Materiali per l´energia",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede2",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "23/1/1974",
        "eta": 48,
        "titolo_di_studio": "Ing.Meccanica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 1483,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#90AA00",
        "label": "collaboratore074",
        "x": factor *  -0.3095884778553939,
        "y": factor *  -0.7197856499077541
    },
    {
        "id": "collaboratore213",
        "livelli": 64,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/6/2009",
        "anni_anz_conv": 13,
        "centro": "centro8",
        "gruppo": "A04 - Sviluppo e sicurezza delle reti",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "13/11/1975",
        "eta": 47,
        "titolo_di_studio": "Ing.Elettronica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 2142,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#E58709",
        "label": "collaboratore213",
        "x": factor *  0.009775291635587546,
        "y": factor *  -0.15722649621526685
    },
    {
        "id": "collaboratore033",
        "livelli": 65,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/1/2012",
        "anni_anz_conv": 10,
        "centro": "centro7",
        "gruppo": "D11 - Geologia e Sicurezza Strutturale",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "18/9/1982",
        "eta": 40,
        "titolo_di_studio": "Geologia",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 4643,
        "generazione": "Millennials",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#A08CFF",
        "label": "collaboratore033",
        "x": factor *  0.41175373308138474,
        "y": factor *  -0.5308856564430017
    },
    {
        "id": "collaboratore064",
        "livelli": 66,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/7/1989",
        "anni_anz_conv": 33,
        "centro": "centro2",
        "gruppo": "B01 - Materiali per l´energia",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede2",
        "mansione": "Assistente  con comp. magg. ril.",
        "data_di_nascita": "22/6/1967",
        "eta": 55,
        "titolo_di_studio": "Ipsia Tecnico delle Industrie Meccaniche",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -924,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#90AA00",
        "label": "collaboratore064",
        "x": factor *  -0.3353614602718815,
        "y": factor *  -0.7745254977136738
    },
    {
        "id": "collaboratore030",
        "livelli": 67,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/1/2012",
        "anni_anz_conv": 10,
        "centro": "centro8",
        "gruppo": "A01 - Reti attive: gestione della distribuzione e della domanda",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "21/7/1985",
        "eta": 37,
        "titolo_di_studio": "Ing.Elettrica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 5680,
        "generazione": "Millennials",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#F07F4B",
        "label": "collaboratore030",
        "x": factor *  0.015636483502444065,
        "y": factor *  0.052176726358635905
    },
    {
        "id": "collaboratore047",
        "livelli": 68,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "2/1/1990",
        "anni_anz_conv": 32,
        "centro": "centro9",
        "gruppo": "Q01 - Information and Communications Technology",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "15/3/1963",
        "eta": 59,
        "titolo_di_studio": "P.I.Elettronico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -2484,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FF62BA",
        "label": "collaboratore047",
        "x": factor *  -0.3446332112122451,
        "y": factor *  0.08608591706717617
    },
    {
        "id": "collaboratore327",
        "livelli": 69,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "15/3/1988",
        "anni_anz_conv": 34,
        "centro": "centro2",
        "gruppo": "B06 - Reti attive: automazione e controllo",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "25/7/1958",
        "eta": 64,
        "titolo_di_studio": "Ing.Nucleare",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -4178,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B825",
        "label": "collaboratore327",
        "x": factor *  0.008801291402889655,
        "y": factor *  -0.004513156183770017
    },
    {
        "id": "collaboratore300",
        "livelli": 70,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "15/10/2018",
        "anni_anz_conv": 4,
        "centro": "centro3",
        "gruppo": "C12 - Sperimentazione componenti e apparati per sistemi T&D",
        "qualifica": "Operaio",
        "sede_di_lavoro": "sede1",
        "mansione": "Addetto",
        "data_di_nascita": "3/2/1990",
        "eta": 32,
        "titolo_di_studio": "Ipsia Operatore Elettrico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": 7338,
        "generazione": "Millennials",
        "tenure": "4-5 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BECE",
        "label": "collaboratore300",
        "x": factor *  -0.7435336631504432,
        "y": factor *  -0.17928755602912916
    },
    {
        "id": "collaboratore166",
        "livelli": 71,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/10/2020",
        "anni_anz_conv": 2,
        "centro": "centro2",
        "gruppo": "B06 - Reti attive: automazione e controllo",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "31/1/1993",
        "eta": 29,
        "titolo_di_studio": "Ing.Civile",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 8431,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B825",
        "label": "collaboratore166",
        "x": factor *  0.1606650098774931,
        "y": factor *  -0.23436863058407764
    },
    {
        "id": "collaboratore181",
        "livelli": 72,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/1/2014",
        "anni_anz_conv": 8,
        "centro": "centro2",
        "gruppo": "B02 - Impianti e processi",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "23/9/1981",
        "eta": 41,
        "titolo_di_studio": "Ing.Chimica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 4283,
        "generazione": "GenX",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#74B000",
        "label": "collaboratore181",
        "x": factor *  -0.4176953596519315,
        "y": factor *  -0.8375076191202909
    },
    {
        "id": "collaboratore092",
        "livelli": 73,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "16/10/1989",
        "anni_anz_conv": 33,
        "centro": "centro2",
        "gruppo": "B01 - Materiali per l´energia",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "31/3/1960",
        "eta": 62,
        "titolo_di_studio": "Ing.Meccanica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -3563,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#90AA00",
        "label": "collaboratore092",
        "x": factor *  -0.36596271983567996,
        "y": factor *  -0.5697512538681813
    },
    {
        "id": "collaboratore318",
        "livelli": 74,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/2/2022",
        "anni_anz_conv": 0,
        "centro": "centro3",
        "gruppo": "C09 - Tecnologie per le reti attive di distribuzione",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "19/11/1992",
        "eta": 30,
        "titolo_di_studio": "Ing.Elettrica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 8358,
        "generazione": "Millennials",
        "tenure": "Meno di un anno",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C08D",
        "label": "collaboratore318",
        "x": factor *  -0.5387131362137364,
        "y": factor *  0.2242637406407455
    },
    {
        "id": "collaboratore137",
        "livelli": 75,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/9/1992",
        "anni_anz_conv": 30,
        "centro": "centro2",
        "gruppo": "B02 - Impianti e processi",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "25/7/1965",
        "eta": 57,
        "titolo_di_studio": "Ing.Nucleare",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -1621,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#74B000",
        "label": "collaboratore137",
        "x": factor *  0.12441130092931729,
        "y": factor *  -0.6123848644559735
    },
    {
        "id": "collaboratore274",
        "livelli": 76,
        "ruolo": "Capo Progetto Strategico",
        "gender": "Femminile",
        "data_anz_conv": "16/9/1989",
        "anni_anz_conv": 33,
        "centro": "centro8",
        "gruppo": "A00 - Staff SSE",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "30/7/1963",
        "eta": 59,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile di Progetto Strategico",
        "anno_nascita": -2347,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#F8766D",
        "label": "collaboratore274",
        "x": factor *  -0.20898188170635434,
        "y": factor *  -0.3279736695055172
    },
    {
        "id": "collaboratore024",
        "livelli": 77,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "21/9/2020",
        "anni_anz_conv": 2,
        "centro": "centro3",
        "gruppo": "C10 - Tecnologie ICT per gestione e sicurezza informatica reti T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "8/1/1986",
        "eta": 36,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 5851,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C1A5",
        "label": "collaboratore024",
        "x": factor *  -0.31160694584134496,
        "y": factor *  0.1378577739179323
    },
    {
        "id": "collaboratore306",
        "livelli": 78,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "16/4/1991",
        "anni_anz_conv": 31,
        "centro": "centro8",
        "gruppo": "A04 - Sviluppo e sicurezza delle reti",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "29/12/1962",
        "eta": 60,
        "titolo_di_studio": "Matematica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -2560,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#E58709",
        "label": "collaboratore306",
        "x": factor *  -0.04178334837752817,
        "y": factor *  0.043491338862964524
    },
    {
        "id": "collaboratore073",
        "livelli": 79,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "15/3/1991",
        "anni_anz_conv": 31,
        "centro": "centro3",
        "gruppo": "C10 - Tecnologie ICT per gestione e sicurezza informatica reti T&D",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "4/10/1960",
        "eta": 62,
        "titolo_di_studio": "Ing.Elettronica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -3376,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C1A5",
        "label": "collaboratore073",
        "x": factor *  -0.3710759680604132,
        "y": factor *  0.13213297830756332
    },
    {
        "id": "collaboratore279",
        "livelli": 80,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/11/2019",
        "anni_anz_conv": 3,
        "centro": "centro2",
        "gruppo": "B04 - Fotovoltaico a concentrazione",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede2",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "18/1/1991",
        "eta": 31,
        "titolo_di_studio": "Ing.Energetica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 7687,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#4CB400",
        "label": "collaboratore279",
        "x": factor *  -0.16272707052966662,
        "y": factor *  -0.8634250510135527
    },
    {
        "id": "collaboratore054",
        "livelli": 81,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "3/6/2015",
        "anni_anz_conv": 7,
        "centro": "centro8",
        "gruppo": "A04 - Sviluppo e sicurezza delle reti",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "24/2/1982",
        "eta": 40,
        "titolo_di_studio": "Matematica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 4437,
        "generazione": "Millennials",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#E58709",
        "label": "collaboratore054",
        "x": factor *  0.017359334205206745,
        "y": factor *  -0.10168903180202093
    },
    {
        "id": "collaboratore324",
        "livelli": 82,
        "ruolo": "Vicedirettore di funzione",
        "gender": "Maschile",
        "data_anz_conv": "8/10/1990",
        "anni_anz_conv": 32,
        "centro": "centro1",
        "gruppo": "P00 - Staff HRP",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Capo Gruppo di Ricerca",
        "data_di_nascita": "11/10/1961",
        "eta": 61,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Vice Responsabile",
        "anno_nascita": -3004,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#EA6AF0",
        "label": "collaboratore324",
        "x": factor *  -0.2702173672812137,
        "y": factor *  -0.14742139406001398
    },
    {
        "id": "collaboratore263",
        "livelli": 83,
        "ruolo": "Responsabile di funzione",
        "gender": "Maschile",
        "data_anz_conv": "1/1/2018",
        "anni_anz_conv": 4,
        "centro": "centro4",
        "gruppo": "N00 - Staff SCV",
        "qualifica": "Dirigente",
        "sede_di_lavoro": "sede1",
        "mansione": "Responsabile di Funzione",
        "data_di_nascita": "24/4/1961",
        "eta": 61,
        "titolo_di_studio": "Ing.Elettrica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile",
        "anno_nascita": -3174,
        "generazione": "Boomers",
        "tenure": "4-5 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#D873FC",
        "label": "collaboratore263",
        "x": factor *  -0.14801334610826855,
        "y": factor *  -0.1735277178201433
    },
    {
        "id": "collaboratore160",
        "livelli": 84,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/7/1983",
        "anni_anz_conv": 39,
        "centro": "centro3",
        "gruppo": "C09 - Tecnologie per le reti attive di distribuzione",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede2",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "20/8/1962",
        "eta": 60,
        "titolo_di_studio": "P.I.Elettrotecnico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -2691,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C08D",
        "label": "collaboratore160",
        "x": factor *  -0.1352083465770073,
        "y": factor *  -1
    },
    {
        "id": "collaboratore007",
        "livelli": 85,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "10/11/1986",
        "anni_anz_conv": 36,
        "centro": "centro3",
        "gruppo": "C11 - Misure e diagnostica di componenti T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "2/12/1960",
        "eta": 62,
        "titolo_di_studio": "P.I.Elettrotecnico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -3317,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C0BA",
        "label": "collaboratore007",
        "x": factor *  -0.5005354029226521,
        "y": factor *  -0.19511139225909868
    },
    {
        "id": "collaboratore315",
        "livelli": 86,
        "ruolo": "Responsabile gruppo di ricerca",
        "gender": "Maschile",
        "data_anz_conv": "27/1/1992",
        "anni_anz_conv": 30,
        "centro": "centro3",
        "gruppo": "C09 - Tecnologie per le reti attive di distribuzione",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Capo Gruppo di Ricerca",
        "data_di_nascita": "5/11/1965",
        "eta": 57,
        "titolo_di_studio": "Ing.Elettrica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile GdR",
        "anno_nascita": -1518,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#00C08D",
        "label": "collaboratore315",
        "x": factor *  -0.45727462566768196,
        "y": factor *  0.05961844851420062
    },
    {
        "id": "collaboratore289",
        "livelli": 87,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/10/1990",
        "anni_anz_conv": 32,
        "centro": "centro8",
        "gruppo": "A00 - Staff SSE",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto con particolare responsabilità ed autonomia",
        "data_di_nascita": "26/4/1957",
        "eta": 65,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -4633,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#F8766D",
        "label": "collaboratore289",
        "x": factor *  0.061397208370978174,
        "y": factor *  -0.19110616604774644
    },
    {
        "id": "collaboratore044",
        "livelli": 88,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "3/6/2015",
        "anni_anz_conv": 7,
        "centro": "centro3",
        "gruppo": "C12 - Sperimentazione componenti e apparati per sistemi T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Addetto  con comp. magg. ril.",
        "data_di_nascita": "20/10/1976",
        "eta": 46,
        "titolo_di_studio": "P.I.Elettronico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": 2484,
        "generazione": "GenX",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BECE",
        "label": "collaboratore044",
        "x": factor *  -0.6332233098904279,
        "y": factor *  -0.25464952680218145
    },
    {
        "id": "collaboratore201",
        "livelli": 89,
        "ruolo": "Vicedirettore di funzione",
        "gender": "Maschile",
        "data_anz_conv": "1/6/1983",
        "anni_anz_conv": 39,
        "centro": "centro5",
        "gruppo": "R00 - Staff SQS",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Vice Direttore di Dipartimento",
        "data_di_nascita": "24/8/1956",
        "eta": 66,
        "titolo_di_studio": "Chimica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Vice Responsabile",
        "anno_nascita": -4878,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#FF67A3",
        "label": "collaboratore201",
        "x": factor *  -0.34779959316026177,
        "y": factor *  -0.4862214530065093
    },
    {
        "id": "collaboratore163",
        "livelli": 90,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/10/1998",
        "anni_anz_conv": 24,
        "centro": "centro7",
        "gruppo": "D00 - Staff SFE",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "6/5/1969",
        "eta": 53,
        "titolo_di_studio": "Ing.Nucleare",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -240,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BAE0",
        "label": "collaboratore163",
        "x": factor *  -0.1083170383979637,
        "y": factor *  -0.4869162592372349
    },
    {
        "id": "collaboratore060",
        "livelli": 91,
        "ruolo": "Responsabile gruppo di ricerca",
        "gender": "Maschile",
        "data_anz_conv": "2/5/2003",
        "anni_anz_conv": 19,
        "centro": "centro8",
        "gruppo": "A04 - Sviluppo e sicurezza delle reti",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Capo Gruppo di Ricerca",
        "data_di_nascita": "12/10/1974",
        "eta": 48,
        "titolo_di_studio": "Ing.Elettrotecnica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile GdR",
        "anno_nascita": 1745,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#E58709",
        "label": "collaboratore060",
        "x": factor *  0.03865566578002122,
        "y": factor *  -0.0069091624681650865
    },
    {
        "id": "collaboratore282",
        "livelli": 92,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/5/2017",
        "anni_anz_conv": 5,
        "centro": "centro3",
        "gruppo": "C09 - Tecnologie per le reti attive di distribuzione",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "31/8/1984",
        "eta": 38,
        "titolo_di_studio": "Ing.Elettrica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 5356,
        "generazione": "Millennials",
        "tenure": "4-5 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C08D",
        "label": "collaboratore282",
        "x": factor *  -0.5001470928620795,
        "y": factor *  0.00022379474672518107
    },
    {
        "id": "collaboratore172",
        "livelli": 93,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "12/1/2015",
        "anni_anz_conv": 7,
        "centro": "centro8",
        "gruppo": "A01 - Reti attive: gestione della distribuzione e della domanda",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "30/3/1979",
        "eta": 43,
        "titolo_di_studio": "Ing.Elettrotecnica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 3375,
        "generazione": "GenX",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#F07F4B",
        "label": "collaboratore172",
        "x": factor *  -0.058478759982630746,
        "y": factor *  0.009742474006381618
    },
    {
        "id": "collaboratore139",
        "livelli": 94,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "8/6/2020",
        "anni_anz_conv": 2,
        "centro": "centro2",
        "gruppo": "B06 - Reti attive: automazione e controllo",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "7/4/1992",
        "eta": 30,
        "titolo_di_studio": "Ing.Energetica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 8132,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B825",
        "label": "collaboratore139",
        "x": factor *  0.02130287114840823,
        "y": factor *  -0.19051919688821606
    },
    {
        "id": "collaboratore088",
        "livelli": 95,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/1/2007",
        "anni_anz_conv": 15,
        "centro": "centro7",
        "gruppo": "D05 - CLM Clima e Meteorologia",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "17/2/1978",
        "eta": 44,
        "titolo_di_studio": "Scienze Ambientali",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 2969,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B4EF",
        "label": "collaboratore088",
        "x": factor *  0.0983137525322697,
        "y": factor *  -0.5229053120924814
    },
    {
        "id": "collaboratore212",
        "livelli": 96,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/3/2015",
        "anni_anz_conv": 7,
        "centro": "centro7",
        "gruppo": "D11 - Geologia e Sicurezza Strutturale",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "13/11/1986",
        "eta": 36,
        "titolo_di_studio": "Geologia",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 6160,
        "generazione": "Millennials",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#A08CFF",
        "label": "collaboratore212",
        "x": factor *  0.22559935127437147,
        "y": factor *  -0.5412150194407639
    },
    {
        "id": "collaboratore192",
        "livelli": 97,
        "ruolo": "Capo Progetto RDS",
        "gender": "Maschile",
        "data_anz_conv": "1/1/2013",
        "anni_anz_conv": 9,
        "centro": "centro3",
        "gruppo": "C00 - Staff TTD",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "29/3/1985",
        "eta": 37,
        "titolo_di_studio": "Ing.Energetica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile di Progetto RdS",
        "anno_nascita": 5566,
        "generazione": "Millennials",
        "tenure": "6-10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#00BC53",
        "label": "collaboratore192",
        "x": factor *  -0.16124843144601675,
        "y": factor *  -0.06907536849101947
    },
    {
        "id": "collaboratore190",
        "livelli": 98,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "15/5/2011",
        "anni_anz_conv": 11,
        "centro": "centro2",
        "gruppo": "B04 - Fotovoltaico a concentrazione",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede2",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "1/3/1973",
        "eta": 49,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 1155,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#4CB400",
        "label": "collaboratore190",
        "x": factor *  -0.22909688063817213,
        "y": factor *  -0.7386271227457661
    },
    {
        "id": "collaboratore258",
        "livelli": 99,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/5/1989",
        "anni_anz_conv": 33,
        "centro": "centro2",
        "gruppo": "B06 - Reti attive: automazione e controllo",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "30/8/1962",
        "eta": 60,
        "titolo_di_studio": "Informatica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -2681,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B825",
        "label": "collaboratore258",
        "x": factor *  -0.05961964584723756,
        "y": factor *  -0.10866800198026494
    },
    {
        "id": "collaboratore188",
        "livelli": 100,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/1/2021",
        "anni_anz_conv": 1,
        "centro": "centro7",
        "gruppo": "D06 - Qualità dell´aria e Modelli",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "3/5/1978",
        "eta": 44,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 3044,
        "generazione": "GenX",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00ADFB",
        "label": "collaboratore188",
        "x": factor *  0.34443463178388867,
        "y": factor *  -0.34892276793841737
    },
    {
        "id": "collaboratore107",
        "livelli": 101,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "16/11/2009",
        "anni_anz_conv": 13,
        "centro": "centro5",
        "gruppo": "R01 - Servizi generali e tecnici",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "19/10/1961",
        "eta": 61,
        "titolo_di_studio": "P.I.Elettrotecnico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -2996,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FE6E89",
        "label": "collaboratore107",
        "x": factor *  -0.25841853449785435,
        "y": factor *  -0.39361774748660383
    },
    {
        "id": "collaboratore302",
        "livelli": 102,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "13/10/1980",
        "anni_anz_conv": 42,
        "centro": "centro7",
        "gruppo": "D11 - Geologia e Sicurezza Strutturale",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "7/9/1960",
        "eta": 62,
        "titolo_di_studio": "Biologia",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -3403,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#A08CFF",
        "label": "collaboratore302",
        "x": factor *  -0.42141838705478896,
        "y": factor *  -0.5481439195088511
    },
    {
        "id": "collaboratore063",
        "livelli": 103,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "17/10/1988",
        "anni_anz_conv": 34,
        "centro": "centro8",
        "gruppo": "A05 - Efficienza energetica",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "27/3/1966",
        "eta": 56,
        "titolo_di_studio": "P.I.Termotecnica",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -1376,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#D98F00",
        "label": "collaboratore063",
        "x": factor *  0.08482846194669169,
        "y": factor *  -0.20031308960256455
    },
    {
        "id": "collaboratore051",
        "livelli": 104,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/10/2021",
        "anni_anz_conv": 1,
        "centro": "centro7",
        "gruppo": "D08 - LCA Analisi ciclo di vita",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "7/3/1983",
        "eta": 39,
        "titolo_di_studio": "Ingegneria per l´Ambiente e il Territorio",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 4813,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#7498FF",
        "label": "collaboratore051",
        "x": factor *  0.28029677310328505,
        "y": factor *  -0.4443954392453944
    },
    {
        "id": "collaboratore077",
        "livelli": 105,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "15/12/2021",
        "anni_anz_conv": 1,
        "centro": "centro3",
        "gruppo": "C11 - Misure e diagnostica di componenti T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "29/12/1991",
        "eta": 31,
        "titolo_di_studio": "Ing.Elettronica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 8032,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C0BA",
        "label": "collaboratore077",
        "x": factor *  -0.5918867023815495,
        "y": factor *  -0.05094695796936999
    },
    {
        "id": "collaboratore245",
        "livelli": 106,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "6/10/1992",
        "anni_anz_conv": 30,
        "centro": "centro1",
        "gruppo": "P01 - Procurement",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Aggiunto",
        "data_di_nascita": "5/1/1968",
        "eta": 54,
        "titolo_di_studio": "Licenza Media",
        "tipo": "Media",
        "responsabile": "NA",
        "anno_nascita": -727,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#F763E1",
        "label": "collaboratore245",
        "x": factor *  0.696665777544927,
        "y": factor *  0.6607503604157463
    },
    {
        "id": "collaboratore217",
        "livelli": 107,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/12/2021",
        "anni_anz_conv": 1,
        "centro": "centro8",
        "gruppo": "A05 - Efficienza energetica",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "29/5/1994",
        "eta": 28,
        "titolo_di_studio": "Ing.Meccanica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 8914,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#D98F00",
        "label": "collaboratore217",
        "x": factor *  0.047019909952175754,
        "y": factor *  -0.13673830263447384
    },
    {
        "id": "collaboratore132",
        "livelli": 108,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "8/4/2019",
        "anni_anz_conv": 3,
        "centro": "centro1",
        "gruppo": "P01 - Procurement",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Assistente",
        "data_di_nascita": "29/3/1977",
        "eta": 45,
        "titolo_di_studio": "Liceo Artistico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": 2644,
        "generazione": "GenX",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#F763E1",
        "label": "collaboratore132",
        "x": factor *  -0.35183816757967235,
        "y": factor *  -0.39847777152301633
    },
    {
        "id": "collaboratore179",
        "livelli": 109,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/1/2012",
        "anni_anz_conv": 10,
        "centro": "centro7",
        "gruppo": "D08 - LCA Analisi ciclo di vita",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "1/8/1980",
        "eta": 42,
        "titolo_di_studio": "Ing.Ambientale",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 3865,
        "generazione": "GenX",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#7498FF",
        "label": "collaboratore179",
        "x": factor *  0.16731890314342834,
        "y": factor *  -0.3952747038617147
    },
    {
        "id": "collaboratore113",
        "livelli": 110,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "14/3/2022",
        "anni_anz_conv": 0,
        "centro": "centro8",
        "gruppo": "A07 - Regolazione dei Mercati Energetici",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "29/5/1988",
        "eta": 34,
        "titolo_di_studio": "Matematica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 6723,
        "generazione": "Millennials",
        "tenure": "Meno di un anno",
        "incarichi_resp": "Non ha incarichi",
        "color": "#BA9E00",
        "label": "collaboratore113",
        "x": factor *  0.11422056230971389,
        "y": factor *  -0.06335882669935677
    },
    {
        "id": "collaboratore174",
        "livelli": 111,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/9/2021",
        "anni_anz_conv": 1,
        "centro": "centro7",
        "gruppo": "D06 - Qualità dell´aria e Modelli",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "19/4/1991",
        "eta": 31,
        "titolo_di_studio": "Ingegneria per l´Ambiente e il Territorio",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 7778,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00ADFB",
        "label": "collaboratore174",
        "x": factor *  0.36969001191337014,
        "y": factor *  -0.501580624323668
    },
    {
        "id": "collaboratore019",
        "livelli": 112,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "12/1/2015",
        "anni_anz_conv": 7,
        "centro": "centro7",
        "gruppo": "D08 - LCA Analisi ciclo di vita",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "11/7/1980",
        "eta": 42,
        "titolo_di_studio": "Ing.Chimica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 3844,
        "generazione": "GenX",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#7498FF",
        "label": "collaboratore019",
        "x": factor *  0.3094661578184097,
        "y": factor *  -0.4171974828470273
    },
    {
        "id": "collaboratore200",
        "livelli": 113,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/1/2012",
        "anni_anz_conv": 10,
        "centro": "centro7",
        "gruppo": "D00 - Staff SFE",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Assistente",
        "data_di_nascita": "1/8/1975",
        "eta": 47,
        "titolo_di_studio": "Liceo Classico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": 2038,
        "generazione": "GenX",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BAE0",
        "label": "collaboratore200",
        "x": factor *  0.13886722736871548,
        "y": factor *  -0.43490863382869893
    },
    {
        "id": "collaboratore046",
        "livelli": 114,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/7/1985",
        "anni_anz_conv": 37,
        "centro": "centro3",
        "gruppo": "C08 - Sistemi e tecnologie innovative per le reti energetiche",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "21/11/1960",
        "eta": 62,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -3328,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BE72",
        "label": "collaboratore046",
        "x": factor *  -0.4303353753601492,
        "y": factor *  -0.29486706431352794
    },
    {
        "id": "collaboratore145",
        "livelli": 115,
        "ruolo": "Direttore di dipartimento",
        "gender": "Maschile",
        "data_anz_conv": "1/1/1984",
        "anni_anz_conv": 38,
        "centro": "centro7",
        "gruppo": "D00 - Staff SFE",
        "qualifica": "Dirigente",
        "sede_di_lavoro": "sede1",
        "mansione": "Direttore di Dipartimento",
        "data_di_nascita": "21/9/1959",
        "eta": 63,
        "titolo_di_studio": "Ing.Elettrotecnica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Direttore",
        "anno_nascita": -3755,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#00BAE0",
        "label": "collaboratore145",
        "x": factor *  0.05300516509686237,
        "y": factor *  -0.38892286288533584
    },
    {
        "id": "collaboratore240",
        "livelli": 116,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/5/1989",
        "anni_anz_conv": 33,
        "centro": "centro7",
        "gruppo": "D05 - CLM Clima e Meteorologia",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "3/9/1962",
        "eta": 60,
        "titolo_di_studio": "Ing.Elettronica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -2677,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B4EF",
        "label": "collaboratore240",
        "x": factor *  0.3516384422619736,
        "y": factor *  -0.3923195993791321
    },
    {
        "id": "collaboratore119",
        "livelli": 117,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/1/1991",
        "anni_anz_conv": 31,
        "centro": "centro2",
        "gruppo": "B06 - Reti attive: automazione e controllo",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "20/12/1964",
        "eta": 58,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -1838,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B825",
        "label": "collaboratore119",
        "x": factor *  0.024634155084727105,
        "y": factor *  -0.26736571749035865
    },
    {
        "id": "collaboratore016",
        "livelli": 118,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "8/4/2019",
        "anni_anz_conv": 3,
        "centro": "centro9",
        "gruppo": "Q01 - Information and Communications Technology",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "17/12/1978",
        "eta": 44,
        "titolo_di_studio": "Economia",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 3272,
        "generazione": "GenX",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FF62BA",
        "label": "collaboratore016",
        "x": factor *  -0.40837396725761654,
        "y": factor *  -0.008755752725621568
    },
    {
        "id": "collaboratore262",
        "livelli": 119,
        "ruolo": "Responsabile di funzione",
        "gender": "Maschile",
        "data_anz_conv": "7/9/1987",
        "anni_anz_conv": 35,
        "centro": "centro1",
        "gruppo": "P00 - Staff HRP",
        "qualifica": "Dirigente",
        "sede_di_lavoro": "sede1",
        "mansione": "Responsabile di Funzione",
        "data_di_nascita": "20/8/1958",
        "eta": 64,
        "titolo_di_studio": "Ing.Civile - MBA",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile",
        "anno_nascita": -4152,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#EA6AF0",
        "label": "collaboratore262",
        "x": factor *  -0.32419724780150094,
        "y": factor *  -0.211507632709503
    },
    {
        "id": "collaboratore144",
        "livelli": 120,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/11/2020",
        "anni_anz_conv": 2,
        "centro": "centro1",
        "gruppo": "P01 - Procurement",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "17/12/1988",
        "eta": 34,
        "titolo_di_studio": "Giurisprudenza",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 6925,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#F763E1",
        "label": "collaboratore144",
        "x": factor *  -0.48482799560211143,
        "y": factor *  -0.2744373799719382
    },
    {
        "id": "collaboratore161",
        "livelli": 121,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "18/3/1985",
        "anni_anz_conv": 37,
        "centro": "centro9",
        "gruppo": "Q00 - Staff ASI",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "13/8/1955",
        "eta": 67,
        "titolo_di_studio": "Ragioniere/P.Comm",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -5255,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FE61CE",
        "label": "collaboratore161",
        "x": factor *  -0.27122891680442895,
        "y": factor *  -0.03292676207017975
    },
    {
        "id": "collaboratore164",
        "livelli": 122,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "16/7/1990",
        "anni_anz_conv": 32,
        "centro": "centro3",
        "gruppo": "C00 - Staff TTD",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto con particolare responsabilità ed autonomia",
        "data_di_nascita": "12/6/1963",
        "eta": 59,
        "titolo_di_studio": "Informatica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -2395,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BC53",
        "label": "collaboratore164",
        "x": factor *  -0.5510653524656322,
        "y": factor *  0.06343961241275031
    },
    {
        "id": "collaboratore078",
        "livelli": 123,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/1/2011",
        "anni_anz_conv": 11,
        "centro": "centro2",
        "gruppo": "B01 - Materiali per l´energia",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "2/7/1984",
        "eta": 38,
        "titolo_di_studio": "Ing.Chimica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 5296,
        "generazione": "Millennials",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#90AA00",
        "label": "collaboratore078",
        "x": factor *  -0.27861158578502887,
        "y": factor *  -0.5686275177623412
    },
    {
        "id": "collaboratore035",
        "livelli": 124,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/7/1989",
        "anni_anz_conv": 33,
        "centro": "centro2",
        "gruppo": "B01 - Materiali per l´energia",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede2",
        "mansione": "Assistente  con comp. magg. ril.",
        "data_di_nascita": "4/1/1968",
        "eta": 54,
        "titolo_di_studio": "Analista Chimico",
        "tipo": "Professionale",
        "responsabile": "NA",
        "anno_nascita": -728,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#90AA00",
        "label": "collaboratore035",
        "x": factor *  -0.30458936980353046,
        "y": factor *  -0.9280728297905807
    },
    {
        "id": "collaboratore168",
        "livelli": 125,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "15/6/2021",
        "anni_anz_conv": 1,
        "centro": "centro9",
        "gruppo": "Q01 - Information and Communications Technology",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "29/8/1966",
        "eta": 56,
        "titolo_di_studio": "Matematica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -1221,
        "generazione": "GenX",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FF62BA",
        "label": "collaboratore168",
        "x": factor *  -0.401023131868676,
        "y": factor *  0.07831682930743522
    },
    {
        "id": "collaboratore138",
        "livelli": 126,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "16/6/1991",
        "anni_anz_conv": 31,
        "centro": "centro7",
        "gruppo": "D05 - CLM Clima e Meteorologia",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "5/2/1963",
        "eta": 59,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -2522,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B4EF",
        "label": "collaboratore138",
        "x": factor *  0.0066162096100175205,
        "y": factor *  -0.3529499706837478
    },
    {
        "id": "collaboratore268",
        "livelli": 127,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/3/2008",
        "anni_anz_conv": 14,
        "centro": "centro7",
        "gruppo": "D11 - Geologia e Sicurezza Strutturale",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "5/6/1974",
        "eta": 48,
        "titolo_di_studio": "Ing.Civile",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 1616,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#A08CFF",
        "label": "collaboratore268",
        "x": factor *  0.09579073498663138,
        "y": factor *  -0.2936654308006196
    },
    {
        "id": "collaboratore112",
        "livelli": 128,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/5/2020",
        "anni_anz_conv": 2,
        "centro": "centro8",
        "gruppo": "A04 - Sviluppo e sicurezza delle reti",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "28/7/1973",
        "eta": 49,
        "titolo_di_studio": "Chimica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 1304,
        "generazione": "GenX",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#E58709",
        "label": "collaboratore112",
        "x": factor *  0.16872119402914754,
        "y": factor *  0.17204030671077408
    },
    {
        "id": "collaboratore283",
        "livelli": 129,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/9/1990",
        "anni_anz_conv": 32,
        "centro": "centro9",
        "gruppo": "Q00 - Staff ASI",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "22/7/1963",
        "eta": 59,
        "titolo_di_studio": "Economia",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -2355,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FE61CE",
        "label": "collaboratore283",
        "x": factor *  -0.40977939666893026,
        "y": factor *  -0.12342427708422343
    },
    {
        "id": "collaboratore204",
        "livelli": 130,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/10/1981",
        "anni_anz_conv": 41,
        "centro": "centro1",
        "gruppo": "P00 - Staff HRP",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede2",
        "mansione": "Esperto",
        "data_di_nascita": "4/7/1961",
        "eta": 61,
        "titolo_di_studio": "Giurisprudenza",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -3103,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#EA6AF0",
        "label": "collaboratore204",
        "x": factor *  -0.10011693604329064,
        "y": factor *  -0.7229007738246973
    },
    {
        "id": "collaboratore259",
        "livelli": 131,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "26/11/1990",
        "anni_anz_conv": 32,
        "centro": "centro3",
        "gruppo": "C12 - Sperimentazione componenti e apparati per sistemi T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "7/6/1971",
        "eta": 51,
        "titolo_di_studio": "P.I.Elettrotecnico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": 522,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BECE",
        "label": "collaboratore259",
        "x": factor *  -0.8915213925175071,
        "y": factor *  -0.14669379075775402
    },
    {
        "id": "collaboratore018",
        "livelli": 132,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "3/4/2017",
        "anni_anz_conv": 5,
        "centro": "centro2",
        "gruppo": "B01 - Materiali per l´energia",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede2",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "18/9/1986",
        "eta": 36,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 6104,
        "generazione": "Millennials",
        "tenure": "4-5 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#90AA00",
        "label": "collaboratore018",
        "x": factor *  -0.3284101599583269,
        "y": factor *  -0.6841145914953075
    },
    {
        "id": "collaboratore310",
        "livelli": 133,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "NA",
        "anni_anz_conv": 0,
        "centro": "centro3",
        "gruppo": "C00 - Staff TTD",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "NA",
        "data_di_nascita": "30/8/1994",
        "eta": 28,
        "titolo_di_studio": "Scienze Politiche",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 9007,
        "generazione": "Millennials",
        "tenure": "Meno di un anno",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BC53",
        "label": "collaboratore310",
        "x": factor *  -0.6777898744627466,
        "y": factor *  0.4789738120074334
    },
    {
        "id": "collaboratore230",
        "livelli": 134,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/9/2021",
        "anni_anz_conv": 1,
        "centro": "centro8",
        "gruppo": "A04 - Sviluppo e sicurezza delle reti",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "9/8/1985",
        "eta": 37,
        "titolo_di_studio": "Ingegneria per l´Ambiente e il Territorio",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 5699,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#E58709",
        "label": "collaboratore230",
        "x": factor *  0.08002853133653809,
        "y": factor *  -0.021924993854783192
    },
    {
        "id": "collaboratore198",
        "livelli": 135,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/9/1991",
        "anni_anz_conv": 31,
        "centro": "centro1",
        "gruppo": "P00 - Staff HRP",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "18/1/1963",
        "eta": 59,
        "titolo_di_studio": "Ing.Nucleare",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -2540,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#EA6AF0",
        "label": "collaboratore198",
        "x": factor *  -0.663824966309305,
        "y": factor *  -0.09260232865414053
    },
    {
        "id": "collaboratore316",
        "livelli": 136,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/12/1985",
        "anni_anz_conv": 37,
        "centro": "centro7",
        "gruppo": "D06 - Qualità dell´aria e Modelli",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "19/11/1958",
        "eta": 64,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -4061,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00ADFB",
        "label": "collaboratore316",
        "x": factor *  0.08189724700327283,
        "y": factor *  -0.4560384697405201
    },
    {
        "id": "collaboratore109",
        "livelli": 137,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/1/2020",
        "anni_anz_conv": 2,
        "centro": "centro3",
        "gruppo": "C08 - Sistemi e tecnologie innovative per le reti energetiche",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "28/12/1992",
        "eta": 30,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 8397,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BE72",
        "label": "collaboratore109",
        "x": factor *  -0.7793039551066012,
        "y": factor *  -0.34854471765063033
    },
    {
        "id": "collaboratore091",
        "livelli": 138,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "23/10/1989",
        "anni_anz_conv": 33,
        "centro": "centro3",
        "gruppo": "C12 - Sperimentazione componenti e apparati per sistemi T&D",
        "qualifica": "Operaio",
        "sede_di_lavoro": "sede1",
        "mansione": "Operaio",
        "data_di_nascita": "21/2/1963",
        "eta": 59,
        "titolo_di_studio": "P.I.Meccanico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -2506,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BECE",
        "label": "collaboratore091",
        "x": factor *  -0.8947160676919771,
        "y": factor *  -0.2659230824154186
    },
    {
        "id": "collaboratore297",
        "livelli": 139,
        "ruolo": "CP e Vicedirettore di dipartimento",
        "gender": "Femminile",
        "data_anz_conv": "26/6/2000",
        "anni_anz_conv": 22,
        "centro": "centro7",
        "gruppo": "D00 - Staff SFE",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "1/4/1974",
        "eta": 48,
        "titolo_di_studio": "Ing.Civile",
        "tipo": "Laurea Magistrale",
        "responsabile": "Vice Direttore",
        "anno_nascita": 1551,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#00BAE0",
        "label": "collaboratore297",
        "x": factor *  0.060323308491851746,
        "y": factor *  -0.43056447964493516
    },
    {
        "id": "collaboratore090",
        "livelli": 140,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "24/5/1982",
        "anni_anz_conv": 40,
        "centro": "centro5",
        "gruppo": "R00 - Staff SQS",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "4/11/1962",
        "eta": 60,
        "titolo_di_studio": "P.I.Nucleare",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -2615,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FF67A3",
        "label": "collaboratore090",
        "x": factor *  -0.28141321246868367,
        "y": factor *  -0.35727499652332595
    },
    {
        "id": "collaboratore223",
        "livelli": 141,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "16/10/1989",
        "anni_anz_conv": 33,
        "centro": "centro9",
        "gruppo": "Q01 - Information and Communications Technology",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "3/5/1968",
        "eta": 54,
        "titolo_di_studio": "P.I.Informatico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -608,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FF62BA",
        "label": "collaboratore223",
        "x": factor *  -0.1720781222975143,
        "y": factor *  0.012148367562776263
    },
    {
        "id": "collaboratore039",
        "livelli": 142,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/9/2020",
        "anni_anz_conv": 2,
        "centro": "centro3",
        "gruppo": "C12 - Sperimentazione componenti e apparati per sistemi T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Assistente",
        "data_di_nascita": "13/9/1980",
        "eta": 42,
        "titolo_di_studio": "P.I.Elettrotecnico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": 3908,
        "generazione": "GenX",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BECE",
        "label": "collaboratore039",
        "x": factor *  -0.8093189060095927,
        "y": factor *  0.11867662136418344
    },
    {
        "id": "collaboratore203",
        "livelli": 143,
        "ruolo": "Responsabile gruppo di ricerca",
        "gender": "Femminile",
        "data_anz_conv": "1/6/2017",
        "anni_anz_conv": 5,
        "centro": "centro8",
        "gruppo": "A06 - Scenari e Analisi di Sistema",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "28/12/1977",
        "eta": 45,
        "titolo_di_studio": "Ing.Ambientale",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile GdR",
        "anno_nascita": 2918,
        "generazione": "GenX",
        "tenure": "4-5 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#CB9700",
        "label": "collaboratore203",
        "x": factor *  0.22943391845366556,
        "y": factor *  -0.047231600899566684
    },
    {
        "id": "collaboratore216",
        "livelli": 144,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/9/1986",
        "anni_anz_conv": 36,
        "centro": "centro1",
        "gruppo": "P01 - Procurement",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "19/7/1963",
        "eta": 59,
        "titolo_di_studio": "Ist.Spec.Econ.Diet.",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -2358,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#F763E1",
        "label": "collaboratore216",
        "x": factor *  -0.4515318471458346,
        "y": factor *  -0.17286535965891858
    },
    {
        "id": "collaboratore151",
        "livelli": 145,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "27/2/1991",
        "anni_anz_conv": 31,
        "centro": "centro9",
        "gruppo": "Q00 - Staff ASI",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Assistente  con comp. magg. ril.",
        "data_di_nascita": "23/10/1967",
        "eta": 55,
        "titolo_di_studio": "Add.Segr.D´Azienda",
        "tipo": "Professionale",
        "responsabile": "NA",
        "anno_nascita": -801,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FE61CE",
        "label": "collaboratore151",
        "x": factor *  -0.48241149597984223,
        "y": factor *  -0.07911245389904065
    },
    {
        "id": "collaboratore026",
        "livelli": 146,
        "ruolo": "Capo Progetto RDS",
        "gender": "Femminile",
        "data_anz_conv": "5/5/2008",
        "anni_anz_conv": 14,
        "centro": "centro3",
        "gruppo": "C00 - Staff TTD",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "29/8/1981",
        "eta": 41,
        "titolo_di_studio": "Ing.Elettrica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile di Progetto RdS",
        "anno_nascita": 4258,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#00BC53",
        "label": "collaboratore026",
        "x": factor *  -0.2446937864420724,
        "y": factor *  -0.06923840604684384
    },
    {
        "id": "collaboratore041",
        "livelli": 147,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "16/11/2009",
        "anni_anz_conv": 13,
        "centro": "centro7",
        "gruppo": "D07 - ART Analisi risorse e territorio",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "18/3/1976",
        "eta": 46,
        "titolo_di_studio": "Scienze Ambientali",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 2268,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#1EA3FF",
        "label": "collaboratore041",
        "x": factor *  0.44409326798738125,
        "y": factor *  -0.35911641917021386
    },
    {
        "id": "collaboratore140",
        "livelli": 148,
        "ruolo": "Responsabile gruppo di ricerca",
        "gender": "Femminile",
        "data_anz_conv": "1/1/1991",
        "anni_anz_conv": 31,
        "centro": "centro7",
        "gruppo": "D07 - ART Analisi risorse e territorio",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto con particolare responsabilità ed autonomia",
        "data_di_nascita": "13/9/1965",
        "eta": 57,
        "titolo_di_studio": "Biologia",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile GdR",
        "anno_nascita": -1571,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#1EA3FF",
        "label": "collaboratore140",
        "x": factor *  0.25466788984150734,
        "y": factor *  -0.32371945928000245
    },
    {
        "id": "collaboratore143",
        "livelli": 149,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "16/5/1988",
        "anni_anz_conv": 34,
        "centro": "centro3",
        "gruppo": "C11 - Misure e diagnostica di componenti T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "11/10/1967",
        "eta": 55,
        "titolo_di_studio": "P.I.Elettrotecnico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -813,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C0BA",
        "label": "collaboratore143",
        "x": factor *  -0.6805212855224122,
        "y": factor *  -0.18001039052026202
    },
    {
        "id": "collaboratore049",
        "livelli": 150,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "15/3/1988",
        "anni_anz_conv": 34,
        "centro": "centro3",
        "gruppo": "C10 - Tecnologie ICT per gestione e sicurezza informatica reti T&D",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto con particolare responsabilità ed autonomia",
        "data_di_nascita": "20/8/1959",
        "eta": 63,
        "titolo_di_studio": "Ing.Elettronica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -3787,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C1A5",
        "label": "collaboratore049",
        "x": factor *  -0.3540322318739547,
        "y": factor *  0.03495058365547421
    },
    {
        "id": "collaboratore134",
        "livelli": 151,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/4/1987",
        "anni_anz_conv": 35,
        "centro": "centro9",
        "gruppo": "Q00 - Staff ASI",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Assistente  con comp. magg. ril.",
        "data_di_nascita": "28/1/1961",
        "eta": 61,
        "titolo_di_studio": "Perito Aziendale C.L.E.",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -3260,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FE61CE",
        "label": "collaboratore134",
        "x": factor *  -0.31576158875169646,
        "y": factor *  -0.11155718888034993
    },
    {
        "id": "collaboratore097",
        "livelli": 152,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "2/5/2005",
        "anni_anz_conv": 17,
        "centro": "centro8",
        "gruppo": "A04 - Sviluppo e sicurezza delle reti",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "17/11/1974",
        "eta": 48,
        "titolo_di_studio": "Ing.Elettrotecnica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 1781,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#E58709",
        "label": "collaboratore097",
        "x": factor *  0.0838449137125552,
        "y": factor *  -0.06424669664081317
    },
    {
        "id": "collaboratore214",
        "livelli": 153,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "8/4/2019",
        "anni_anz_conv": 3,
        "centro": "centro2",
        "gruppo": "B02 - Impianti e processi",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Assistente",
        "data_di_nascita": "23/6/1976",
        "eta": 46,
        "titolo_di_studio": "Licenza Media",
        "tipo": "Media",
        "responsabile": "NA",
        "anno_nascita": 2365,
        "generazione": "GenX",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#74B000",
        "label": "collaboratore214",
        "x": factor *  -0.441755821122353,
        "y": factor *  -0.5029873046372402
    },
    {
        "id": "collaboratore079",
        "livelli": 154,
        "ruolo": "Capo Progetto RDS",
        "gender": "Maschile",
        "data_anz_conv": "20/2/1995",
        "anni_anz_conv": 27,
        "centro": "centro8",
        "gruppo": "A00 - Staff SSE",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto con particolare responsabilità ed autonomia",
        "data_di_nascita": "16/5/1970",
        "eta": 52,
        "titolo_di_studio": "Matematica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile di Progetto RdS",
        "anno_nascita": 135,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#F8766D",
        "label": "collaboratore079",
        "x": factor *  0.21669801812818412,
        "y": factor *  -0.122428120540366
    },
    {
        "id": "collaboratore115",
        "livelli": 155,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "15/12/2021",
        "anni_anz_conv": 1,
        "centro": "centro2",
        "gruppo": "B01 - Materiali per l´energia",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "26/6/1991",
        "eta": 31,
        "titolo_di_studio": "Chimica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 7846,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#90AA00",
        "label": "collaboratore115",
        "x": factor *  -0.4127703521938405,
        "y": factor *  -0.6409936524137623
    },
    {
        "id": "collaboratore189",
        "livelli": 156,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "2/4/1991",
        "anni_anz_conv": 31,
        "centro": "centro3",
        "gruppo": "C11 - Misure e diagnostica di componenti T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "2/8/1967",
        "eta": 55,
        "titolo_di_studio": "P.I.Elettrotecnico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -883,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C0BA",
        "label": "collaboratore189",
        "x": factor *  -0.7458153573362665,
        "y": factor *  -0.03954781342800762
    },
    {
        "id": "collaboratore325",
        "livelli": 157,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "2/5/1984",
        "anni_anz_conv": 38,
        "centro": "centro7",
        "gruppo": "D00 - Staff SFE",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto con particolare responsabilità ed autonomia",
        "data_di_nascita": "3/6/1956",
        "eta": 66,
        "titolo_di_studio": "Ing.Nucleare",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -4960,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BAE0",
        "label": "collaboratore325",
        "x": factor *  0.03349486548005598,
        "y": factor *  -0.2232310059437682
    },
    {
        "id": "collaboratore298",
        "livelli": 158,
        "ruolo": "Responsabile Servizio",
        "gender": "Femminile",
        "data_anz_conv": "1/1/2009",
        "anni_anz_conv": 13,
        "centro": "centro1",
        "gruppo": "P01 - Procurement",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Capo Servizio",
        "data_di_nascita": "21/5/1980",
        "eta": 42,
        "titolo_di_studio": "Giurisprudenza",
        "tipo": "Laurea Magistrale",
        "responsabile": "Capo Servizio",
        "anno_nascita": 3793,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#F763E1",
        "label": "collaboratore298",
        "x": factor *  -0.39361270522316705,
        "y": factor *  -0.2325354075940722
    },
    {
        "id": "collaboratore280",
        "livelli": 159,
        "ruolo": "Responsabile gruppo di ricerca",
        "gender": "Maschile",
        "data_anz_conv": "12/3/2001",
        "anni_anz_conv": 21,
        "centro": "centro7",
        "gruppo": "D08 - LCA Analisi ciclo di vita",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "14/10/1973",
        "eta": 49,
        "titolo_di_studio": "Ing.Ambientale",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile GdR",
        "anno_nascita": 1382,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#7498FF",
        "label": "collaboratore280",
        "x": factor *  0.25001082395167273,
        "y": factor *  -0.3875201276995911
    },
    {
        "id": "collaboratore250",
        "livelli": 160,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/9/2020",
        "anni_anz_conv": 2,
        "centro": "centro7",
        "gruppo": "D07 - ART Analisi risorse e territorio",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "30/12/1991",
        "eta": 31,
        "titolo_di_studio": "Ing.Ambientale",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 8033,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#1EA3FF",
        "label": "collaboratore250",
        "x": factor *  0.34179067539772245,
        "y": factor *  0.8920987993130176
    },
    {
        "id": "collaboratore032",
        "livelli": 161,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "16/3/1987",
        "anni_anz_conv": 35,
        "centro": "centro3",
        "gruppo": "C08 - Sistemi e tecnologie innovative per le reti energetiche",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "28/9/1962",
        "eta": 60,
        "titolo_di_studio": "Ing.Nucleare",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -2652,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BE72",
        "label": "collaboratore032",
        "x": factor *  -0.4522371536489336,
        "y": factor *  -0.26860062014692687
    },
    {
        "id": "collaboratore215",
        "livelli": 162,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/4/1990",
        "anni_anz_conv": 32,
        "centro": "centro4",
        "gruppo": "N00 - Staff SCV",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "27/3/1965",
        "eta": 57,
        "titolo_di_studio": "Informatica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -1741,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#D873FC",
        "label": "collaboratore215",
        "x": factor *  -0.0996952450272025,
        "y": factor *  0.2238064395907542
    },
    {
        "id": "collaboratore037",
        "livelli": 163,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "15/6/1987",
        "anni_anz_conv": 35,
        "centro": "centro2",
        "gruppo": "B06 - Reti attive: automazione e controllo",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto con particolare responsabilità ed autonomia",
        "data_di_nascita": "12/1/1959",
        "eta": 63,
        "titolo_di_studio": "Ing.Elettronica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -4007,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B825",
        "label": "collaboratore037",
        "x": factor *  -0.16149240254220276,
        "y": factor *  -0.16000742460701878
    },
    {
        "id": "collaboratore052",
        "livelli": 164,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "25/6/1990",
        "anni_anz_conv": 32,
        "centro": "centro2",
        "gruppo": "B01 - Materiali per l´energia",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "3/12/1964",
        "eta": 58,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -1855,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#90AA00",
        "label": "collaboratore052",
        "x": factor *  -0.30468554770064504,
        "y": factor *  -0.6449369993500653
    },
    {
        "id": "collaboratore008",
        "livelli": 165,
        "ruolo": "Capo Progetto RDS",
        "gender": "Maschile",
        "data_anz_conv": "1/6/1985",
        "anni_anz_conv": 37,
        "centro": "centro2",
        "gruppo": "B00 - Staff TGM",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto con particolare responsabilità ed autonomia",
        "data_di_nascita": "26/3/1955",
        "eta": 67,
        "titolo_di_studio": "Ing.Elettrotecnica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile di Progetto RdS",
        "anno_nascita": -5395,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#A7A400",
        "label": "collaboratore008",
        "x": factor *  -0.11513871650032392,
        "y": factor *  -0.43133413143849975
    },
    {
        "id": "collaboratore267",
        "livelli": 166,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/10/2012",
        "anni_anz_conv": 10,
        "centro": "centro4",
        "gruppo": "N00 - Staff SCV",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "26/2/1961",
        "eta": 61,
        "titolo_di_studio": "Scienze Politiche",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -3231,
        "generazione": "Boomers",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#D873FC",
        "label": "collaboratore267",
        "x": factor *  -0.10141014302753504,
        "y": factor *  0.09871938410360581
    },
    {
        "id": "collaboratore196",
        "livelli": 167,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/6/2003",
        "anni_anz_conv": 19,
        "centro": "centro8",
        "gruppo": "A04 - Sviluppo e sicurezza delle reti",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "21/7/1975",
        "eta": 47,
        "titolo_di_studio": "Ing.Elettrotecnica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 2027,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#E58709",
        "label": "collaboratore196",
        "x": factor *  -0.026198175080978214,
        "y": factor *  0.016415531414863516
    },
    {
        "id": "collaboratore009",
        "livelli": 168,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "21/8/1986",
        "anni_anz_conv": 36,
        "centro": "centro4",
        "gruppo": "N00 - Staff SCV",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "16/5/1961",
        "eta": 61,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -3152,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#D873FC",
        "label": "collaboratore009",
        "x": factor *  -0.4771747700671658,
        "y": factor *  -0.23603216719641262
    },
    {
        "id": "collaboratore253",
        "livelli": 169,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "15/10/2018",
        "anni_anz_conv": 4,
        "centro": "centro3",
        "gruppo": "C12 - Sperimentazione componenti e apparati per sistemi T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Assistente",
        "data_di_nascita": "15/5/1983",
        "eta": 39,
        "titolo_di_studio": "P.I.Elettrotecnico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": 4882,
        "generazione": "Millennials",
        "tenure": "4-5 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BECE",
        "label": "collaboratore253",
        "x": factor *  -1,
        "y": factor *  -0.13065907194492177
    },
    {
        "id": "collaboratore243",
        "livelli": 170,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/1/2010",
        "anni_anz_conv": 12,
        "centro": "centro8",
        "gruppo": "A04 - Sviluppo e sicurezza delle reti",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "1/4/1974",
        "eta": 48,
        "titolo_di_studio": "Ing.Elettrica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 1551,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#E58709",
        "label": "collaboratore243",
        "x": factor *  -0.012900472655909323,
        "y": factor *  0.09936800913208343
    },
    {
        "id": "collaboratore055",
        "livelli": 171,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/1/2011",
        "anni_anz_conv": 11,
        "centro": "centro7",
        "gruppo": "D05 - CLM Clima e Meteorologia",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "20/5/1980",
        "eta": 42,
        "titolo_di_studio": "Scienze Ambientali",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 3792,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B4EF",
        "label": "collaboratore055",
        "x": factor *  0.022927125867518328,
        "y": factor *  -0.4401579670944078
    },
    {
        "id": "collaboratore176",
        "livelli": 172,
        "ruolo": "Vice capo progetto",
        "gender": "Maschile",
        "data_anz_conv": "1/4/2011",
        "anni_anz_conv": 11,
        "centro": "centro8",
        "gruppo": "A06 - Scenari e Analisi di Sistema",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "2/4/1984",
        "eta": 38,
        "titolo_di_studio": "Ing.Elettrica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 5205,
        "generazione": "Millennials",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#CB9700",
        "label": "collaboratore176",
        "x": factor *  0.2322079977177376,
        "y": factor *  -0.00970903640404075
    },
    {
        "id": "collaboratore207",
        "livelli": 173,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "19/10/1987",
        "anni_anz_conv": 35,
        "centro": "centro7",
        "gruppo": "D05 - CLM Clima e Meteorologia",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede2",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "14/1/1966",
        "eta": 56,
        "titolo_di_studio": "P.I.Elettronico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -1448,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B4EF",
        "label": "collaboratore207",
        "x": factor *  -0.020772558639889915,
        "y": factor *  -0.6033122083451643
    },
    {
        "id": "collaboratore029",
        "livelli": 174,
        "ruolo": "Vice capo progetto",
        "gender": "Maschile",
        "data_anz_conv": "1/10/2011",
        "anni_anz_conv": 11,
        "centro": "centro2",
        "gruppo": "B00 - Staff TGM",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "9/3/1984",
        "eta": 38,
        "titolo_di_studio": "Ing.Elettronica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 5181,
        "generazione": "Millennials",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#A7A400",
        "label": "collaboratore029",
        "x": factor *  -0.192547495996096,
        "y": factor *  -0.1832531082563965
    },
    {
        "id": "collaboratore185",
        "livelli": 175,
        "ruolo": "Responsabile di funzione",
        "gender": "Maschile",
        "data_anz_conv": "2/7/1984",
        "anni_anz_conv": 38,
        "centro": "centro9",
        "gruppo": "Q00 - Staff ASI",
        "qualifica": "Dirigente",
        "sede_di_lavoro": "sede1",
        "mansione": "Responsabile di Funzione",
        "data_di_nascita": "4/10/1962",
        "eta": 60,
        "titolo_di_studio": "Ragioniere/P.Comm",
        "tipo": "Diploma",
        "responsabile": "Responsabile",
        "anno_nascita": -2646,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#FE61CE",
        "label": "collaboratore185",
        "x": factor *  -0.3207155826283927,
        "y": factor *  -0.1628436642878739
    },
    {
        "id": "collaboratore241",
        "livelli": 176,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "8/4/2019",
        "anni_anz_conv": 3,
        "centro": "centro4",
        "gruppo": "N00 - Staff SCV",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "14/9/1985",
        "eta": 37,
        "titolo_di_studio": "Lingue",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 5735,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#D873FC",
        "label": "collaboratore241",
        "x": factor *  -0.2739470269533364,
        "y": factor *  -0.21395638842085585
    },
    {
        "id": "collaboratore292",
        "livelli": 177,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "14/11/1977",
        "anni_anz_conv": 45,
        "centro": "centro2",
        "gruppo": "B01 - Materiali per l´energia",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "7/8/1958",
        "eta": 64,
        "titolo_di_studio": "P.I.Elettronico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -4165,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#90AA00",
        "label": "collaboratore292",
        "x": factor *  -0.46556424411962494,
        "y": factor *  -0.681664415494632
    },
    {
        "id": "collaboratore086",
        "livelli": 178,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "5/5/2008",
        "anni_anz_conv": 14,
        "centro": "centro3",
        "gruppo": "C10 - Tecnologie ICT per gestione e sicurezza informatica reti T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "16/9/1981",
        "eta": 41,
        "titolo_di_studio": "Ing.Energetica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 4276,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C1A5",
        "label": "collaboratore086",
        "x": factor *  -0.06459260316601134,
        "y": factor *  0.1283463055567189
    },
    {
        "id": "collaboratore224",
        "livelli": 179,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/10/2020",
        "anni_anz_conv": 2,
        "centro": "centro7",
        "gruppo": "D06 - Qualità dell´aria e Modelli",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "9/8/1990",
        "eta": 32,
        "titolo_di_studio": "Ing.Energetica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 7525,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00ADFB",
        "label": "collaboratore224",
        "x": factor *  0.38087780150490236,
        "y": factor *  -0.4507102157400936
    },
    {
        "id": "collaboratore323",
        "livelli": 180,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/9/2019",
        "anni_anz_conv": 3,
        "centro": "centro1",
        "gruppo": "P00 - Staff HRP",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "15/2/1993",
        "eta": 29,
        "titolo_di_studio": "Scienze Politiche",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 8446,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#EA6AF0",
        "label": "collaboratore323",
        "x": factor *  -0.4795796200326007,
        "y": factor *  0.027379711827580033
    },
    {
        "id": "collaboratore301",
        "livelli": 181,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/1/2011",
        "anni_anz_conv": 11,
        "centro": "centro8",
        "gruppo": "A00 - Staff SSE",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "28/11/1984",
        "eta": 38,
        "titolo_di_studio": "Ing.Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 5445,
        "generazione": "Millennials",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#F8766D",
        "label": "collaboratore301",
        "x": factor *  0.1149471423040842,
        "y": factor *  -0.016829866092948897
    },
    {
        "id": "collaboratore254",
        "livelli": 182,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "5/3/2020",
        "anni_anz_conv": 2,
        "centro": "centro3",
        "gruppo": "C10 - Tecnologie ICT per gestione e sicurezza informatica reti T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "10/5/1988",
        "eta": 34,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 6704,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C1A5",
        "label": "collaboratore254",
        "x": factor *  -0.3626192633670823,
        "y": factor *  0.23820859107391468
    },
    {
        "id": "collaboratore011",
        "livelli": 183,
        "ruolo": "Responsabile gruppo di ricerca",
        "gender": "Maschile",
        "data_anz_conv": "3/7/1989",
        "anni_anz_conv": 33,
        "centro": "centro3",
        "gruppo": "C12 - Sperimentazione componenti e apparati per sistemi T&D",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "21/3/1968",
        "eta": 54,
        "titolo_di_studio": "P.I.Elettrotecnico",
        "tipo": "Diploma",
        "responsabile": "Responsabile GdR",
        "anno_nascita": -651,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#00BECE",
        "label": "collaboratore011",
        "x": factor *  -0.5067031499105901,
        "y": factor *  -0.23125539190522248
    },
    {
        "id": "collaboratore209",
        "livelli": 184,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "NA",
        "anni_anz_conv": 0,
        "centro": "centro2",
        "gruppo": "B01 - Materiali per l´energia",
        "qualifica": "Personale non subordinato",
        "sede_di_lavoro": "sede2",
        "mansione": "NA",
        "data_di_nascita": "8/2/1996",
        "eta": 26,
        "titolo_di_studio": "Scienze dei Materiali",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 9534,
        "generazione": "GenZ",
        "tenure": "Meno di un anno",
        "incarichi_resp": "Non ha incarichi",
        "color": "#90AA00",
        "label": "collaboratore209",
        "x": factor *  -0.4122290388042211,
        "y": factor *  -0.7161468029256932
    },
    {
        "id": "collaboratore252",
        "livelli": 185,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "25/11/1981",
        "anni_anz_conv": 41,
        "centro": "centro2",
        "gruppo": "B04 - Fotovoltaico a concentrazione",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede2",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "27/8/1962",
        "eta": 60,
        "titolo_di_studio": "P.I.Elettronico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -2684,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#4CB400",
        "label": "collaboratore252",
        "x": factor *  -0.23922462736635008,
        "y": factor *  -0.8100964086581773
    },
    {
        "id": "collaboratore155",
        "livelli": 186,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/9/1989",
        "anni_anz_conv": 33,
        "centro": "centro7",
        "gruppo": "D07 - ART Analisi risorse e territorio",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "25/3/1963",
        "eta": 59,
        "titolo_di_studio": "Ing.Civile Idraulica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -2474,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#1EA3FF",
        "label": "collaboratore155",
        "x": factor *  0.20836143184051448,
        "y": factor *  -0.4315703697677731
    },
    {
        "id": "collaboratore246",
        "livelli": 187,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "3/12/1979",
        "anni_anz_conv": 43,
        "centro": "centro3",
        "gruppo": "C11 - Misure e diagnostica di componenti T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "29/10/1956",
        "eta": 66,
        "titolo_di_studio": "P.I.Elettronico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -4812,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C0BA",
        "label": "collaboratore246",
        "x": factor *  -0.5773412326642298,
        "y": factor *  -0.2837775953875218
    },
    {
        "id": "collaboratore069",
        "livelli": 188,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/1/2011",
        "anni_anz_conv": 11,
        "centro": "centro3",
        "gruppo": "C11 - Misure e diagnostica di componenti T&D",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "23/11/1968",
        "eta": 54,
        "titolo_di_studio": "Ing.Elettrotecnica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -404,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C0BA",
        "label": "collaboratore069",
        "x": factor *  -0.3436405791289795,
        "y": factor *  -0.2350977016854392
    },
    {
        "id": "collaboratore023",
        "livelli": 189,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "4/6/1990",
        "anni_anz_conv": 32,
        "centro": "centro4",
        "gruppo": "N00 - Staff SCV",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Addetto",
        "data_di_nascita": "19/9/1963",
        "eta": 59,
        "titolo_di_studio": "Add.Segr.D´Azienda",
        "tipo": "Professionale",
        "responsabile": "NA",
        "anno_nascita": -2296,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#D873FC",
        "label": "collaboratore023",
        "x": factor *  -0.3771666800128395,
        "y": factor *  -0.1640965002787541
    },
    {
        "id": "collaboratore156",
        "livelli": 190,
        "ruolo": "Capo Progetto RDS",
        "gender": "Maschile",
        "data_anz_conv": "1/3/1988",
        "anni_anz_conv": 34,
        "centro": "centro7",
        "gruppo": "D00 - Staff SFE",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Capo Gruppo di Ricerca",
        "data_di_nascita": "12/2/1963",
        "eta": 59,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile di Progetto RdS",
        "anno_nascita": -2515,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#00BAE0",
        "label": "collaboratore156",
        "x": factor *  0.009035155967648034,
        "y": factor *  -0.4858414422432594
    },
    {
        "id": "collaboratore286",
        "livelli": 191,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "8/5/1978",
        "anni_anz_conv": 44,
        "centro": "centro7",
        "gruppo": "D05 - CLM Clima e Meteorologia",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "30/10/1959",
        "eta": 63,
        "titolo_di_studio": "P.I.Elettronico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -3716,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B4EF",
        "label": "collaboratore286",
        "x": factor *  -0.022117744552135443,
        "y": factor *  -0.48990968777562816
    },
    {
        "id": "collaboratore304",
        "livelli": 192,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/7/2015",
        "anni_anz_conv": 7,
        "centro": "centro2",
        "gruppo": "B01 - Materiali per l´energia",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "4/3/1979",
        "eta": 43,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 3349,
        "generazione": "GenX",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#90AA00",
        "label": "collaboratore304",
        "x": factor *  -0.402123691925351,
        "y": factor *  -0.5997535016633546
    },
    {
        "id": "collaboratore147",
        "livelli": 193,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "20/7/2020",
        "anni_anz_conv": 2,
        "centro": "centro7",
        "gruppo": "D08 - LCA Analisi ciclo di vita",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "14/5/1989",
        "eta": 33,
        "titolo_di_studio": "Ing.Ambientale",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 7073,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#7498FF",
        "label": "collaboratore147",
        "x": factor *  0.2758138967644459,
        "y": factor *  -0.3507230688937081
    },
    {
        "id": "collaboratore221",
        "livelli": 194,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/2/1988",
        "anni_anz_conv": 34,
        "centro": "centro4",
        "gruppo": "N00 - Staff SCV",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "24/10/1958",
        "eta": 64,
        "titolo_di_studio": "Biologia",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -4087,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#D873FC",
        "label": "collaboratore221",
        "x": factor *  -0.1289951446914257,
        "y": factor *  -0.21796621125737026
    },
    {
        "id": "collaboratore225",
        "livelli": 195,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "15/12/1988",
        "anni_anz_conv": 34,
        "centro": "centro3",
        "gruppo": "C00 - Staff TTD",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Assistente",
        "data_di_nascita": "23/1/1969",
        "eta": 53,
        "titolo_di_studio": "Amministrativo",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -343,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BC53",
        "label": "collaboratore225",
        "x": factor *  -0.44428847575945174,
        "y": factor *  -0.06372087768383616
    },
    {
        "id": "collaboratore130",
        "livelli": 196,
        "ruolo": "Direttore di dipartimento",
        "gender": "Maschile",
        "data_anz_conv": "1/3/1989",
        "anni_anz_conv": 33,
        "centro": "centro3",
        "gruppo": "C00 - Staff TTD",
        "qualifica": "Dirigente",
        "sede_di_lavoro": "sede1",
        "mansione": "Direttore di Dipartimento",
        "data_di_nascita": "11/10/1960",
        "eta": 62,
        "titolo_di_studio": "Chimica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Direttore",
        "anno_nascita": -3369,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#00BC53",
        "label": "collaboratore130",
        "x": factor *  -0.3540510492338492,
        "y": factor *  -0.1575546819586655
    },
    {
        "id": "collaboratore100",
        "livelli": 197,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/6/2017",
        "anni_anz_conv": 5,
        "centro": "centro4",
        "gruppo": "N00 - Staff SCV",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "3/6/1979",
        "eta": 43,
        "titolo_di_studio": "Ing.Meccanica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 3440,
        "generazione": "GenX",
        "tenure": "4-5 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#D873FC",
        "label": "collaboratore100",
        "x": factor *  -0.07290489924835064,
        "y": factor *  -0.1414777479496241
    },
    {
        "id": "collaboratore103",
        "livelli": 198,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/9/1984",
        "anni_anz_conv": 38,
        "centro": "centro7",
        "gruppo": "D11 - Geologia e Sicurezza Strutturale",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "5/10/1958",
        "eta": 64,
        "titolo_di_studio": "Ing.Civile",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -4106,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#A08CFF",
        "label": "collaboratore103",
        "x": factor *  0.14532645432910862,
        "y": factor *  -0.22029924559885494
    },
    {
        "id": "collaboratore066",
        "livelli": 199,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "9/5/2022",
        "anni_anz_conv": 0,
        "centro": "centro1",
        "gruppo": "P01 - Procurement",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "29/12/1992",
        "eta": 30,
        "titolo_di_studio": "Giurisprudenza",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 8398,
        "generazione": "Millennials",
        "tenure": "Meno di un anno",
        "incarichi_resp": "Non ha incarichi",
        "color": "#F763E1",
        "label": "collaboratore066",
        "x": factor *  -0.5170294757306585,
        "y": factor *  -0.2507140150104751
    },
    {
        "id": "collaboratore308",
        "livelli": 200,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "16/6/2003",
        "anni_anz_conv": 19,
        "centro": "centro5",
        "gruppo": "R00 - Staff SQS",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "1/12/1975",
        "eta": 47,
        "titolo_di_studio": "Economia",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 2160,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FF67A3",
        "label": "collaboratore308",
        "x": factor *  -0.16320672748577458,
        "y": factor *  -0.4420475944491514
    },
    {
        "id": "collaboratore058",
        "livelli": 201,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "6/9/2021",
        "anni_anz_conv": 1,
        "centro": "centro2",
        "gruppo": "B02 - Impianti e processi",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "25/12/1994",
        "eta": 28,
        "titolo_di_studio": "Ing.Energetica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 9124,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#74B000",
        "label": "collaboratore058",
        "x": factor *  0.13385798012063743,
        "y": factor *  -0.17139080024703057
    },
    {
        "id": "collaboratore153",
        "livelli": 202,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "8/4/2019",
        "anni_anz_conv": 3,
        "centro": "centro2",
        "gruppo": "B02 - Impianti e processi",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "8/10/1986",
        "eta": 36,
        "titolo_di_studio": "Ing.Telecomunicazioni",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 6124,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#74B000",
        "label": "collaboratore153",
        "x": factor *  -0.25174769017529475,
        "y": factor *  -0.2991989155509478
    },
    {
        "id": "collaboratore102",
        "livelli": 203,
        "ruolo": "Responsabile gruppo di ricerca",
        "gender": "Maschile",
        "data_anz_conv": "2/5/2001",
        "anni_anz_conv": 21,
        "centro": "centro3",
        "gruppo": "C10 - Tecnologie ICT per gestione e sicurezza informatica reti T&D",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "24/2/1969",
        "eta": 53,
        "titolo_di_studio": "Ing.Nucleare",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile GdR",
        "anno_nascita": -311,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#00C1A5",
        "label": "collaboratore102",
        "x": factor *  -0.38703212287748445,
        "y": factor *  0.09980828856389956
    },
    {
        "id": "collaboratore242",
        "livelli": 204,
        "ruolo": "Responsabile gruppo di ricerca",
        "gender": "Maschile",
        "data_anz_conv": "1/10/1998",
        "anni_anz_conv": 24,
        "centro": "centro3",
        "gruppo": "C11 - Misure e diagnostica di componenti T&D",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Capo Gruppo di Ricerca",
        "data_di_nascita": "28/3/1970",
        "eta": 52,
        "titolo_di_studio": "Ing.Elettrotecnica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile GdR",
        "anno_nascita": 86,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#00C0BA",
        "label": "collaboratore242",
        "x": factor *  -0.4987080824555954,
        "y": factor *  -0.13981836841773787
    },
    {
        "id": "collaboratore095",
        "livelli": 205,
        "ruolo": "Direttore di dipartimento",
        "gender": "Maschile",
        "data_anz_conv": "1/9/1981",
        "anni_anz_conv": 41,
        "centro": "centro2",
        "gruppo": "B00 - Staff TGM",
        "qualifica": "Dirigente",
        "sede_di_lavoro": "sede1",
        "mansione": "Direttore di Dipartimento",
        "data_di_nascita": "22/8/1955",
        "eta": 67,
        "titolo_di_studio": "Ing.Nucleare",
        "tipo": "Laurea Magistrale",
        "responsabile": "Direttore",
        "anno_nascita": -5246,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#A7A400",
        "label": "collaboratore095",
        "x": factor *  -0.18045428151633702,
        "y": factor *  -0.4077033669818586
    },
    {
        "id": "collaboratore313",
        "livelli": 206,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/7/2017",
        "anni_anz_conv": 5,
        "centro": "centro7",
        "gruppo": "D08 - LCA Analisi ciclo di vita",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "17/8/1983",
        "eta": 39,
        "titolo_di_studio": "Economia",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 4976,
        "generazione": "Millennials",
        "tenure": "4-5 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#7498FF",
        "label": "collaboratore313",
        "x": factor *  0.2115773195168671,
        "y": factor *  -0.305997690556978
    },
    {
        "id": "collaboratore182",
        "livelli": 207,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "NA",
        "anni_anz_conv": 0,
        "centro": "centro2",
        "gruppo": "B02 - Impianti e processi",
        "qualifica": "Personale non subordinato",
        "sede_di_lavoro": "sede1",
        "mansione": "NA",
        "data_di_nascita": "4/7/1967",
        "eta": 55,
        "titolo_di_studio": "P.I.Elettrotecnico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -912,
        "generazione": "GenX",
        "tenure": "Meno di un anno",
        "incarichi_resp": "Non ha incarichi",
        "color": "#74B000",
        "label": "collaboratore182",
        "x": factor *  0.1626818940976127,
        "y": factor *  0.9550211771177683
    },
    {
        "id": "collaboratore028",
        "livelli": 208,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/3/2022",
        "anni_anz_conv": 0,
        "centro": "centro1",
        "gruppo": "P01 - Procurement",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "3/10/1978",
        "eta": 44,
        "titolo_di_studio": "Giurisprudenza",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 3197,
        "generazione": "GenX",
        "tenure": "Meno di un anno",
        "incarichi_resp": "Non ha incarichi",
        "color": "#F763E1",
        "label": "collaboratore028",
        "x": factor *  -0.4802791123261084,
        "y": factor *  -0.20344693955428828
    },
    {
        "id": "collaboratore061",
        "livelli": 209,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "28/2/1994",
        "anni_anz_conv": 28,
        "centro": "centro8",
        "gruppo": "A01 - Reti attive: gestione della distribuzione e della domanda",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "26/7/1964",
        "eta": 58,
        "titolo_di_studio": "Ing.Elettrotecnica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -1985,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#F07F4B",
        "label": "collaboratore061",
        "x": factor *  0.07870951172272078,
        "y": factor *  0.13920773356539473
    },
    {
        "id": "collaboratore220",
        "livelli": 210,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/1/2007",
        "anni_anz_conv": 15,
        "centro": "centro2",
        "gruppo": "B06 - Reti attive: automazione e controllo",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "15/3/1978",
        "eta": 44,
        "titolo_di_studio": "Ing.Elettrica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 2995,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B825",
        "label": "collaboratore220",
        "x": factor *  -0.15133140101778475,
        "y": factor *  -0.5449540033959321
    },
    {
        "id": "collaboratore012",
        "livelli": 211,
        "ruolo": "Capo Progetto Strategico",
        "gender": "Maschile",
        "data_anz_conv": "2/5/1994",
        "anni_anz_conv": 28,
        "centro": "centro8",
        "gruppo": "A00 - Staff SSE",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Capo Gruppo di Ricerca",
        "data_di_nascita": "25/5/1965",
        "eta": 57,
        "titolo_di_studio": "Ing.Elettronica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile di Progetto Strategico",
        "anno_nascita": -1682,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#F8766D",
        "label": "collaboratore012",
        "x": factor *  0.043065238695227714,
        "y": factor *  0.09270450572408961
    },
    {
        "id": "collaboratore169",
        "livelli": 212,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "5/5/2008",
        "anni_anz_conv": 14,
        "centro": "centro2",
        "gruppo": "B04 - Fotovoltaico a concentrazione",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede2",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "18/11/1982",
        "eta": 40,
        "titolo_di_studio": "Ing.Elettronica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 4704,
        "generazione": "Millennials",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#4CB400",
        "label": "collaboratore169",
        "x": factor *  -0.2327964708072472,
        "y": factor *  -0.8735735757386933
    },
    {
        "id": "collaboratore082",
        "livelli": 213,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "7/1/2020",
        "anni_anz_conv": 2,
        "centro": "centro7",
        "gruppo": "D08 - LCA Analisi ciclo di vita",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "15/11/1964",
        "eta": 58,
        "titolo_di_studio": "Economia",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -1873,
        "generazione": "Boomers",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#7498FF",
        "label": "collaboratore082",
        "x": factor *  0.24690752766255675,
        "y": factor *  -0.2591244834178039
    },
    {
        "id": "collaboratore159",
        "livelli": 214,
        "ruolo": "Responsabile gruppo di ricerca",
        "gender": "Femminile",
        "data_anz_conv": "1/8/2002",
        "anni_anz_conv": 20,
        "centro": "centro8",
        "gruppo": "A00 - Staff SSE",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Capo Gruppo di Ricerca",
        "data_di_nascita": "4/8/1972",
        "eta": 50,
        "titolo_di_studio": "Ing.Elettronica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile GdR",
        "anno_nascita": 946,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#F8766D",
        "label": "collaboratore159",
        "x": factor *  0.042826557279252775,
        "y": factor *  -0.04700798133498629
    },
    {
        "id": "collaboratore256",
        "livelli": 215,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "8/4/2019",
        "anni_anz_conv": 3,
        "centro": "centro5",
        "gruppo": "R01 - Servizi generali e tecnici",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Assistente",
        "data_di_nascita": "13/6/1984",
        "eta": 38,
        "titolo_di_studio": "Perito Aziendale C.L.E.",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": 5277,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FE6E89",
        "label": "collaboratore256",
        "x": factor *  -0.4808128877328802,
        "y": factor *  -0.4797411543568951
    },
    {
        "id": "collaboratore260",
        "livelli": 216,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "27/5/1985",
        "anni_anz_conv": 37,
        "centro": "centro1",
        "gruppo": "P00 - Staff HRP",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "10/1/1958",
        "eta": 64,
        "titolo_di_studio": "P.I.Chimico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -4374,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#EA6AF0",
        "label": "collaboratore260",
        "x": factor *  0.8479793412897707,
        "y": factor *  0.49331295600868996
    },
    {
        "id": "collaboratore031",
        "livelli": 217,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/1/2011",
        "anni_anz_conv": 11,
        "centro": "centro7",
        "gruppo": "D07 - ART Analisi risorse e territorio",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "8/11/1979",
        "eta": 43,
        "titolo_di_studio": "Ing.Nucleare",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 3598,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#1EA3FF",
        "label": "collaboratore031",
        "x": factor *  0.3091649402100656,
        "y": factor *  -0.29166906492042854
    },
    {
        "id": "collaboratore110",
        "livelli": 218,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "16/11/1989",
        "anni_anz_conv": 33,
        "centro": "centro8",
        "gruppo": "A00 - Staff SSE",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Assistente  con comp. magg. ril.",
        "data_di_nascita": "14/11/1965",
        "eta": 57,
        "titolo_di_studio": "Licenza Media",
        "tipo": "Media",
        "responsabile": "NA",
        "anno_nascita": -1509,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#F8766D",
        "label": "collaboratore110",
        "x": factor *  0.14916412311054206,
        "y": factor *  0.020932749618361557
    },
    {
        "id": "collaboratore199",
        "livelli": 219,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "15/7/1981",
        "anni_anz_conv": 41,
        "centro": "centro1",
        "gruppo": "P00 - Staff HRP",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "28/8/1961",
        "eta": 61,
        "titolo_di_studio": "Perito Aziendale C.L.E.",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -3048,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#EA6AF0",
        "label": "collaboratore199",
        "x": factor *  1,
        "y": factor *  0.2292658298761472
    },
    {
        "id": "collaboratore257",
        "livelli": 220,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "NA",
        "anni_anz_conv": 0,
        "centro": "centro1",
        "gruppo": "P00 - Staff HRP",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "NA",
        "data_di_nascita": "13/5/1991",
        "eta": 31,
        "titolo_di_studio": "Ragioniere/P.Comm",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": 7802,
        "generazione": "Millennials",
        "tenure": "Meno di un anno",
        "incarichi_resp": "Non ha incarichi",
        "color": "#EA6AF0",
        "label": "collaboratore257",
        "x": factor *  -0.3616441172505733,
        "y": factor *  -0.10546664218940816
    },
    {
        "id": "collaboratore108",
        "livelli": 221,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/4/2021",
        "anni_anz_conv": 1,
        "centro": "centro8",
        "gruppo": "A05 - Efficienza energetica",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "12/1/1988",
        "eta": 34,
        "titolo_di_studio": "Ing.Energetica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 6585,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#D98F00",
        "label": "collaboratore108",
        "x": factor *  0.18040316312002713,
        "y": factor *  -0.025089250812253105
    },
    {
        "id": "collaboratore187",
        "livelli": 222,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/1/2016",
        "anni_anz_conv": 6,
        "centro": "centro4",
        "gruppo": "N00 - Staff SCV",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "8/10/1981",
        "eta": 41,
        "titolo_di_studio": "Psicologia",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 4298,
        "generazione": "GenX",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#D873FC",
        "label": "collaboratore187",
        "x": factor *  -0.3042152296551288,
        "y": factor *  -0.13831420213602563
    },
    {
        "id": "collaboratore311",
        "livelli": 223,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/9/2021",
        "anni_anz_conv": 1,
        "centro": "centro9",
        "gruppo": "Q01 - Information and Communications Technology",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Assistente  con comp. magg. ril.",
        "data_di_nascita": "23/3/1977",
        "eta": 45,
        "titolo_di_studio": "Grafico Pubblicitario",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": 2638,
        "generazione": "GenX",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FF62BA",
        "label": "collaboratore311",
        "x": factor *  -0.337935888326491,
        "y": factor *  -0.08891039063397344
    },
    {
        "id": "collaboratore307",
        "livelli": 224,
        "ruolo": "Responsabile di funzione",
        "gender": "Maschile",
        "data_anz_conv": "1/9/1980",
        "anni_anz_conv": 42,
        "centro": "centro5",
        "gruppo": "R00 - Staff SQS",
        "qualifica": "Dirigente",
        "sede_di_lavoro": "sede1",
        "mansione": "Dirigente",
        "data_di_nascita": "27/10/1954",
        "eta": 68,
        "titolo_di_studio": "Ing.Nucleare",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile",
        "anno_nascita": -5545,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#FF67A3",
        "label": "collaboratore307",
        "x": factor *  -0.3880785246404904,
        "y": factor *  -0.3344523714856773
    },
    {
        "id": "collaboratore083",
        "livelli": 225,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "17/6/2002",
        "anni_anz_conv": 20,
        "centro": "centro5",
        "gruppo": "R01 - Servizi generali e tecnici",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Aggiunto",
        "data_di_nascita": "18/12/1972",
        "eta": 50,
        "titolo_di_studio": "Ragioniere/P.Comm",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": 1082,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FE6E89",
        "label": "collaboratore083",
        "x": factor *  -0.5167104073062596,
        "y": factor *  -0.33861492502904755
    },
    {
        "id": "collaboratore294",
        "livelli": 226,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "8/6/2020",
        "anni_anz_conv": 2,
        "centro": "centro2",
        "gruppo": "B02 - Impianti e processi",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "10/1/1992",
        "eta": 30,
        "titolo_di_studio": "Ing.Energetica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 8044,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#74B000",
        "label": "collaboratore294",
        "x": factor *  -0.03025396692488902,
        "y": factor *  0.9929343017546262
    },
    {
        "id": "collaboratore238",
        "livelli": 227,
        "ruolo": "Responsabile gruppo di ricerca",
        "gender": "Maschile",
        "data_anz_conv": "1/2/1989",
        "anni_anz_conv": 33,
        "centro": "centro2",
        "gruppo": "B01 - Materiali per l´energia",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto con particolare responsabilità ed autonomia",
        "data_di_nascita": "28/8/1958",
        "eta": 64,
        "titolo_di_studio": "Chimica Industr.",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile GdR",
        "anno_nascita": -4144,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#90AA00",
        "label": "collaboratore238",
        "x": factor *  -0.2564613084948357,
        "y": factor *  -0.5600476314751179
    },
    {
        "id": "collaboratore288",
        "livelli": 228,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "27/7/2019",
        "anni_anz_conv": 3,
        "centro": "centro8",
        "gruppo": "A07 - Regolazione dei Mercati Energetici",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto con particolare responsabilità ed autonomia",
        "data_di_nascita": "14/9/1975",
        "eta": 47,
        "titolo_di_studio": "Ing.Meccanica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 2082,
        "generazione": "GenX",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#BA9E00",
        "label": "collaboratore288",
        "x": factor *  0.5116171187376455,
        "y": factor *  0.11842304133696424
    },
    {
        "id": "collaboratore149",
        "livelli": 229,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/9/2016",
        "anni_anz_conv": 6,
        "centro": "centro8",
        "gruppo": "A06 - Scenari e Analisi di Sistema",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "19/6/1990",
        "eta": 32,
        "titolo_di_studio": "Ing.Energetica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 7474,
        "generazione": "Millennials",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#CB9700",
        "label": "collaboratore149",
        "x": factor *  -0.05528730970521112,
        "y": factor *  -0.043119783139993095
    },
    {
        "id": "collaboratore197",
        "livelli": 230,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "15/10/2020",
        "anni_anz_conv": 2,
        "centro": "centro8",
        "gruppo": "A06 - Scenari e Analisi di Sistema",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "5/4/1989",
        "eta": 33,
        "titolo_di_studio": "Economia",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 7034,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#CB9700",
        "label": "collaboratore197",
        "x": factor *  0.40009034521628517,
        "y": factor *  0.06326075616858362
    },
    {
        "id": "collaboratore309",
        "livelli": 231,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "17/9/1979",
        "anni_anz_conv": 43,
        "centro": "centro3",
        "gruppo": "C08 - Sistemi e tecnologie innovative per le reti energetiche",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "16/6/1959",
        "eta": 63,
        "titolo_di_studio": "Tecnico Riparatore",
        "tipo": "Professionale",
        "responsabile": "NA",
        "anno_nascita": -3852,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BE72",
        "label": "collaboratore309",
        "x": factor *  -0.5246895861772283,
        "y": factor *  -0.38852963654079886
    },
    {
        "id": "collaboratore148",
        "livelli": 232,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/10/2014",
        "anni_anz_conv": 8,
        "centro": "centro3",
        "gruppo": "C08 - Sistemi e tecnologie innovative per le reti energetiche",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "2/3/1961",
        "eta": 61,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -3227,
        "generazione": "Boomers",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BE72",
        "label": "collaboratore148",
        "x": factor *  -0.6916886999952976,
        "y": factor *  -0.25396032569875604
    },
    {
        "id": "collaboratore120",
        "livelli": 233,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/4/1986",
        "anni_anz_conv": 36,
        "centro": "centro3",
        "gruppo": "C11 - Misure e diagnostica di componenti T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "23/2/1964",
        "eta": 58,
        "titolo_di_studio": "P.I.Elettrotecnico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -2139,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C0BA",
        "label": "collaboratore120",
        "x": factor *  -0.24250299400002973,
        "y": factor *  -0.3583432021193427
    },
    {
        "id": "collaboratore098",
        "livelli": 234,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "4/4/2022",
        "anni_anz_conv": 0,
        "centro": "centro7",
        "gruppo": "D11 - Geologia e Sicurezza Strutturale",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "13/9/1989",
        "eta": 33,
        "titolo_di_studio": "Scienze Politiche",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 7195,
        "generazione": "Millennials",
        "tenure": "Meno di un anno",
        "incarichi_resp": "Non ha incarichi",
        "color": "#A08CFF",
        "label": "collaboratore098",
        "x": factor *  0.07152430363458406,
        "y": factor *  -0.3241811458222813
    },
    {
        "id": "collaboratore177",
        "livelli": 235,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/7/2019",
        "anni_anz_conv": 3,
        "centro": "centro3",
        "gruppo": "C11 - Misure e diagnostica di componenti T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "8/10/1991",
        "eta": 31,
        "titolo_di_studio": "Ing.Elettrica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 7950,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C0BA",
        "label": "collaboratore177",
        "x": factor *  -0.6169841229997728,
        "y": factor *  -0.08059217447270128
    },
    {
        "id": "collaboratore180",
        "livelli": 236,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "9/5/2022",
        "anni_anz_conv": 0,
        "centro": "centro9",
        "gruppo": "Q00 - Staff ASI",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "12/7/1989",
        "eta": 33,
        "titolo_di_studio": "Economia",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 7132,
        "generazione": "Millennials",
        "tenure": "Meno di un anno",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FE61CE",
        "label": "collaboratore180",
        "x": factor *  -0.2797271371138216,
        "y": factor *  -0.09581498316861237
    },
    {
        "id": "collaboratore264",
        "livelli": 237,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/4/2020",
        "anni_anz_conv": 2,
        "centro": "centro3",
        "gruppo": "C10 - Tecnologie ICT per gestione e sicurezza informatica reti T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "20/12/1990",
        "eta": 32,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 7658,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C1A5",
        "label": "collaboratore264",
        "x": factor *  -0.336475189135122,
        "y": factor *  0.2015876489436217
    },
    {
        "id": "collaboratore089",
        "livelli": 238,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/5/2022",
        "anni_anz_conv": 0,
        "centro": "centro1",
        "gruppo": "P00 - Staff HRP",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "28/6/1992",
        "eta": 30,
        "titolo_di_studio": "Lingue per il management internazionale",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 8214,
        "generazione": "Millennials",
        "tenure": "Meno di un anno",
        "incarichi_resp": "Non ha incarichi",
        "color": "#EA6AF0",
        "label": "collaboratore089",
        "x": factor *  -0.28393437478657435,
        "y": factor *  -0.051045566924713515
    },
    {
        "id": "collaboratore228",
        "livelli": 239,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/12/2014",
        "anni_anz_conv": 8,
        "centro": "centro2",
        "gruppo": "B06 - Reti attive: automazione e controllo",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "29/7/1989",
        "eta": 33,
        "titolo_di_studio": "Ing.Elettrica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 7149,
        "generazione": "Millennials",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B825",
        "label": "collaboratore228",
        "x": factor *  -0.1702479224816642,
        "y": factor *  -0.27369063388361814
    },
    {
        "id": "collaboratore206",
        "livelli": 240,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/4/2019",
        "anni_anz_conv": 3,
        "centro": "centro2",
        "gruppo": "B06 - Reti attive: automazione e controllo",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "17/10/1979",
        "eta": 43,
        "titolo_di_studio": "Ing.Elettrica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 3576,
        "generazione": "GenX",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B825",
        "label": "collaboratore206",
        "x": factor *  -0.0828254700595129,
        "y": factor *  -0.3760669867854112
    },
    {
        "id": "collaboratore247",
        "livelli": 241,
        "ruolo": "Capo Progetto RDS",
        "gender": "Maschile",
        "data_anz_conv": "17/9/2001",
        "anni_anz_conv": 21,
        "centro": "centro2",
        "gruppo": "B00 - Staff TGM",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "31/5/1974",
        "eta": 48,
        "titolo_di_studio": "Ing.Meccanica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile di Progetto RdS",
        "anno_nascita": 1611,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#A7A400",
        "label": "collaboratore247",
        "x": factor *  -0.18507307107659543,
        "y": factor *  -0.37479328873465967
    },
    {
        "id": "collaboratore125",
        "livelli": 242,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "21/3/2022",
        "anni_anz_conv": 0,
        "centro": "centro7",
        "gruppo": "D05 - CLM Clima e Meteorologia",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "23/10/1992",
        "eta": 30,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 8331,
        "generazione": "Millennials",
        "tenure": "Meno di un anno",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B4EF",
        "label": "collaboratore125",
        "x": factor *  0.18239175167222932,
        "y": factor *  -0.5705775311261603
    },
    {
        "id": "collaboratore036",
        "livelli": 243,
        "ruolo": "Capo Progetto RDS",
        "gender": "Maschile",
        "data_anz_conv": "5/2/1992",
        "anni_anz_conv": 30,
        "centro": "centro7",
        "gruppo": "D00 - Staff SFE",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto con particolare responsabilità ed autonomia",
        "data_di_nascita": "26/4/1954",
        "eta": 68,
        "titolo_di_studio": "Ing.Civile Idraulica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile di Progetto RdS",
        "anno_nascita": -5729,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#00BAE0",
        "label": "collaboratore036",
        "x": factor *  0.4720405234825933,
        "y": factor *  -0.8104511075510908
    },
    {
        "id": "collaboratore272",
        "livelli": 244,
        "ruolo": "Responsabile Servizio",
        "gender": "Maschile",
        "data_anz_conv": "17/3/1980",
        "anni_anz_conv": 42,
        "centro": "centro9",
        "gruppo": "Q01 - Information and Communications Technology",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Capo Servizio",
        "data_di_nascita": "26/7/1959",
        "eta": 63,
        "titolo_di_studio": "P.I.Elettronico",
        "tipo": "Diploma",
        "responsabile": "Capo Servizio",
        "anno_nascita": -3812,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#FF62BA",
        "label": "collaboratore272",
        "x": factor *  -0.35966865872008935,
        "y": factor *  -0.05451128511843739
    },
    {
        "id": "collaboratore071",
        "livelli": 245,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/7/2019",
        "anni_anz_conv": 3,
        "centro": "centro2",
        "gruppo": "B00 - Staff TGM",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Addetto",
        "data_di_nascita": "17/10/1978",
        "eta": 44,
        "titolo_di_studio": "Operat. Comm.",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": 3211,
        "generazione": "GenX",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#A7A400",
        "label": "collaboratore071",
        "x": factor *  -0.2742339064674617,
        "y": factor *  -0.44754014020907795
    },
    {
        "id": "collaboratore232",
        "livelli": 246,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "8/3/1989",
        "anni_anz_conv": 33,
        "centro": "centro3",
        "gruppo": "C12 - Sperimentazione componenti e apparati per sistemi T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "25/9/1968",
        "eta": 54,
        "titolo_di_studio": "P.I.Elettrotecnico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -463,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BECE",
        "label": "collaboratore232",
        "x": factor *  -0.7118839921208102,
        "y": factor *  -0.21880467091363198
    },
    {
        "id": "collaboratore291",
        "livelli": 247,
        "ruolo": "Capo Progetto RDS",
        "gender": "Maschile",
        "data_anz_conv": "1/2/1988",
        "anni_anz_conv": 34,
        "centro": "centro3",
        "gruppo": "C00 - Staff TTD",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Capo Gruppo di Ricerca",
        "data_di_nascita": "11/1/1962",
        "eta": 60,
        "titolo_di_studio": "Ing.Elettrotecnica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile di Progetto RdS",
        "anno_nascita": -2912,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#00BC53",
        "label": "collaboratore291",
        "x": factor *  -0.4045601437387544,
        "y": factor *  -0.25237554864210754
    },
    {
        "id": "collaboratore284",
        "livelli": 248,
        "ruolo": "Responsabile gruppo di ricerca",
        "gender": "Maschile",
        "data_anz_conv": "1/1/2001",
        "anni_anz_conv": 21,
        "centro": "centro7",
        "gruppo": "D06 - Qualità dell´aria e Modelli",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "12/12/1969",
        "eta": 53,
        "titolo_di_studio": "Ing.Civile",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile GdR",
        "anno_nascita": -20,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#00ADFB",
        "label": "collaboratore284",
        "x": factor *  0.2500224560097064,
        "y": factor *  -0.4867717111018246
    },
    {
        "id": "collaboratore312",
        "livelli": 249,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/1/2011",
        "anni_anz_conv": 11,
        "centro": "centro8",
        "gruppo": "A04 - Sviluppo e sicurezza delle reti",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "2/3/1981",
        "eta": 41,
        "titolo_di_studio": "Ing.Elettrica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 4078,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#E58709",
        "label": "collaboratore312",
        "x": factor *  0.10373634201354043,
        "y": factor *  -0.14426616617130072
    },
    {
        "id": "collaboratore276",
        "livelli": 250,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "21/12/1992",
        "anni_anz_conv": 30,
        "centro": "centro1",
        "gruppo": "P00 - Staff HRP",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Assistente",
        "data_di_nascita": "10/1/1970",
        "eta": 52,
        "titolo_di_studio": "Licenza Media",
        "tipo": "Media",
        "responsabile": "NA",
        "anno_nascita": 9,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#EA6AF0",
        "label": "collaboratore276",
        "x": factor *  -0.03422083103972717,
        "y": factor *  -0.23438840517996584
    },
    {
        "id": "collaboratore165",
        "livelli": 251,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "16/6/1989",
        "anni_anz_conv": 33,
        "centro": "centro7",
        "gruppo": "D00 - Staff SFE",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "12/4/1963",
        "eta": 59,
        "titolo_di_studio": "Ing.Nucleare",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -2456,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BAE0",
        "label": "collaboratore165",
        "x": factor *  -0.11757467129745114,
        "y": factor *  -0.46493784690848206
    },
    {
        "id": "collaboratore067",
        "livelli": 252,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/7/2012",
        "anni_anz_conv": 10,
        "centro": "centro9",
        "gruppo": "Q00 - Staff ASI",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Assistente  con comp. magg. ril.",
        "data_di_nascita": "2/4/1961",
        "eta": 61,
        "titolo_di_studio": "Linguist. Comm.",
        "tipo": "Professionale",
        "responsabile": "NA",
        "anno_nascita": -3196,
        "generazione": "Boomers",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FE61CE",
        "label": "collaboratore067",
        "x": factor *  -0.7086748124099524,
        "y": factor *  0.1196946563644361
    },
    {
        "id": "collaboratore157",
        "livelli": 253,
        "ruolo": "Vicedirettore di dipartimento",
        "gender": "Maschile",
        "data_anz_conv": "21/4/1980",
        "anni_anz_conv": 42,
        "centro": "centro2",
        "gruppo": "B00 - Staff TGM",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Vice Direttore di Dipartimento",
        "data_di_nascita": "23/2/1953",
        "eta": 69,
        "titolo_di_studio": "Ing.Elettronica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Vice Direttore",
        "anno_nascita": -6156,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#A7A400",
        "label": "collaboratore157",
        "x": factor *  -0.22469673893946296,
        "y": factor *  -0.44150838464014075
    },
    {
        "id": "collaboratore034",
        "livelli": 254,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/9/1980",
        "anni_anz_conv": 42,
        "centro": "centro7",
        "gruppo": "D05 - CLM Clima e Meteorologia",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "12/4/1962",
        "eta": 60,
        "titolo_di_studio": "P.I.Elettrotecnico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -2821,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B4EF",
        "label": "collaboratore034",
        "x": factor *  0.08229225795286754,
        "y": factor *  -0.784643411267119
    },
    {
        "id": "collaboratore226",
        "livelli": 255,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "4/5/2020",
        "anni_anz_conv": 2,
        "centro": "centro2",
        "gruppo": "B01 - Materiali per l´energia",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "20/1/1983",
        "eta": 39,
        "titolo_di_studio": "Chimica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 4767,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#90AA00",
        "label": "collaboratore226",
        "x": factor *  -0.33746527440099683,
        "y": factor *  -0.5487695893843255
    },
    {
        "id": "collaboratore117",
        "livelli": 256,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/7/1989",
        "anni_anz_conv": 33,
        "centro": "centro2",
        "gruppo": "B01 - Materiali per l´energia",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "1/4/1963",
        "eta": 59,
        "titolo_di_studio": "Chimica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -2467,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#90AA00",
        "label": "collaboratore117",
        "x": factor *  -0.4727493905104576,
        "y": factor *  -0.5847575618141034
    },
    {
        "id": "collaboratore205",
        "livelli": 257,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/3/2020",
        "anni_anz_conv": 2,
        "centro": "centro3",
        "gruppo": "C09 - Tecnologie per le reti attive di distribuzione",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "29/6/1993",
        "eta": 29,
        "titolo_di_studio": "Ing.Elettrica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 8580,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C08D",
        "label": "collaboratore205",
        "x": factor *  -0.4831910512196633,
        "y": factor *  0.15004081913165002
    },
    {
        "id": "collaboratore278",
        "livelli": 258,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "12/1/2015",
        "anni_anz_conv": 7,
        "centro": "centro8",
        "gruppo": "A04 - Sviluppo e sicurezza delle reti",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "31/3/1982",
        "eta": 40,
        "titolo_di_studio": "Ing.Elettronica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 4472,
        "generazione": "Millennials",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#E58709",
        "label": "collaboratore278",
        "x": factor *  0.036834487239432834,
        "y": factor *  -0.06767165092030802
    },
    {
        "id": "collaboratore287",
        "livelli": 259,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "18/9/2016",
        "anni_anz_conv": 6,
        "centro": "centro8",
        "gruppo": "A05 - Efficienza energetica",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "18/1/1988",
        "eta": 34,
        "titolo_di_studio": "Ing.Energetica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 6591,
        "generazione": "Millennials",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#D98F00",
        "label": "collaboratore287",
        "x": factor *  0.2008339505151946,
        "y": factor *  -0.08967986957646534
    },
    {
        "id": "collaboratore020",
        "livelli": 260,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "3/4/1991",
        "anni_anz_conv": 31,
        "centro": "centro3",
        "gruppo": "C11 - Misure e diagnostica di componenti T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Aggiunto",
        "data_di_nascita": "31/5/1969",
        "eta": 53,
        "titolo_di_studio": "Licenza Media",
        "tipo": "Media",
        "responsabile": "NA",
        "anno_nascita": -215,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C0BA",
        "label": "collaboratore020",
        "x": factor *  -0.25621706321752924,
        "y": factor *  1
    },
    {
        "id": "collaboratore171",
        "livelli": 261,
        "ruolo": "Responsabile gruppo di ricerca",
        "gender": "Maschile",
        "data_anz_conv": "1/2/1987",
        "anni_anz_conv": 35,
        "centro": "centro7",
        "gruppo": "D05 - CLM Clima e Meteorologia",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto con particolare responsabilità ed autonomia",
        "data_di_nascita": "5/4/1959",
        "eta": 63,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile GdR",
        "anno_nascita": -3924,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#00B4EF",
        "label": "collaboratore171",
        "x": factor *  0.026411546181286605,
        "y": factor *  -0.3797885953015677
    },
    {
        "id": "collaboratore167",
        "livelli": 262,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "20/6/1988",
        "anni_anz_conv": 34,
        "centro": "centro2",
        "gruppo": "B04 - Fotovoltaico a concentrazione",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede2",
        "mansione": "Assistente  con comp. magg. ril.",
        "data_di_nascita": "11/10/1967",
        "eta": 55,
        "titolo_di_studio": "P.I.Elettronico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -813,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#4CB400",
        "label": "collaboratore167",
        "x": factor *  -0.23122186150807067,
        "y": factor *  -0.7657043134868808
    },
    {
        "id": "collaboratore233",
        "livelli": 263,
        "ruolo": "Responsabile Servizio",
        "gender": "Maschile",
        "data_anz_conv": "1/7/2019",
        "anni_anz_conv": 3,
        "centro": "centro5",
        "gruppo": "R01 - Servizi generali e tecnici",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "31/12/1968",
        "eta": 54,
        "titolo_di_studio": "Ing.Meccanica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Capo Servizio",
        "anno_nascita": -366,
        "generazione": "GenX",
        "tenure": "1-3 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#FE6E89",
        "label": "collaboratore233",
        "x": factor *  -0.3881910752524268,
        "y": factor *  -0.3985406029061629
    },
    {
        "id": "collaboratore087",
        "livelli": 264,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "15/12/2021",
        "anni_anz_conv": 1,
        "centro": "centro2",
        "gruppo": "B01 - Materiali per l´energia",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "28/12/1995",
        "eta": 27,
        "titolo_di_studio": "Chimica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 9492,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#90AA00",
        "label": "collaboratore087",
        "x": factor *  -0.4734534694114485,
        "y": factor *  -0.6606711616499721
    },
    {
        "id": "collaboratore068",
        "livelli": 265,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/1/2002",
        "anni_anz_conv": 20,
        "centro": "centro7",
        "gruppo": "D05 - CLM Clima e Meteorologia",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "19/12/1961",
        "eta": 61,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -2935,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B4EF",
        "label": "collaboratore068",
        "x": factor *  0.01861687698461134,
        "y": factor *  -0.32612045689146185
    },
    {
        "id": "collaboratore076",
        "livelli": 266,
        "ruolo": "Responsabile gruppo di ricerca",
        "gender": "Maschile",
        "data_anz_conv": "1/1/2011",
        "anni_anz_conv": 11,
        "centro": "centro2",
        "gruppo": "B02 - Impianti e processi",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "1/5/1982",
        "eta": 40,
        "titolo_di_studio": "Ing.Energetica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile GdR",
        "anno_nascita": 4503,
        "generazione": "Millennials",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#74B000",
        "label": "collaboratore076",
        "x": factor *  -0.19849315789233934,
        "y": factor *  -0.4464356152843052
    },
    {
        "id": "collaboratore075",
        "livelli": 267,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/12/2012",
        "anni_anz_conv": 10,
        "centro": "centro8",
        "gruppo": "A01 - Reti attive: gestione della distribuzione e della domanda",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "13/2/1985",
        "eta": 37,
        "titolo_di_studio": "Ing.Elettrica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 5522,
        "generazione": "Millennials",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#F07F4B",
        "label": "collaboratore075",
        "x": factor *  0.054318110939229225,
        "y": factor *  0.06190740637381231
    },
    {
        "id": "collaboratore121",
        "livelli": 268,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "21/3/2011",
        "anni_anz_conv": 11,
        "centro": "centro3",
        "gruppo": "C08 - Sistemi e tecnologie innovative per le reti energetiche",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "10/3/1976",
        "eta": 46,
        "titolo_di_studio": "P.I.Elettronico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": 2260,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00BE72",
        "label": "collaboratore121",
        "x": factor *  -0.6149997731769845,
        "y": factor *  -0.3071280457149276
    },
    {
        "id": "collaboratore255",
        "livelli": 269,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "15/7/2020",
        "anni_anz_conv": 2,
        "centro": "centro8",
        "gruppo": "A01 - Reti attive: gestione della distribuzione e della domanda",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "3/10/1988",
        "eta": 34,
        "titolo_di_studio": "Matematica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 6850,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#F07F4B",
        "label": "collaboratore255",
        "x": factor *  0.12913008112925484,
        "y": factor *  0.09820768083031428
    },
    {
        "id": "collaboratore277",
        "livelli": 270,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/4/2015",
        "anni_anz_conv": 7,
        "centro": "centro5",
        "gruppo": "R00 - Staff SQS",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede2",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "21/1/1979",
        "eta": 43,
        "titolo_di_studio": "Architettura",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 3307,
        "generazione": "GenX",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FF67A3",
        "label": "collaboratore277",
        "x": factor *  -0.16734623763107082,
        "y": factor *  -0.6000525234369549
    },
    {
        "id": "collaboratore062",
        "livelli": 271,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "15/4/2021",
        "anni_anz_conv": 1,
        "centro": "centro7",
        "gruppo": "D05 - CLM Clima e Meteorologia",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "14/12/1973",
        "eta": 49,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 1443,
        "generazione": "GenX",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B4EF",
        "label": "collaboratore062",
        "x": factor *  -0.0364008102125023,
        "y": factor *  -0.5180046286520614
    },
    {
        "id": "collaboratore116",
        "livelli": 272,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "29/6/2018",
        "anni_anz_conv": 4,
        "centro": "centro9",
        "gruppo": "Q00 - Staff ASI",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "19/5/1977",
        "eta": 45,
        "titolo_di_studio": "Economia",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 2695,
        "generazione": "GenX",
        "tenure": "4-5 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FE61CE",
        "label": "collaboratore116",
        "x": factor *  -0.37354024917757445,
        "y": factor *  -0.1901486706237866
    },
    {
        "id": "collaboratore038",
        "livelli": 273,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/2/2022",
        "anni_anz_conv": 0,
        "centro": "centro8",
        "gruppo": "A05 - Efficienza energetica",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "4/3/1985",
        "eta": 37,
        "titolo_di_studio": "Ing.Civile",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 5541,
        "generazione": "Millennials",
        "tenure": "Meno di un anno",
        "incarichi_resp": "Non ha incarichi",
        "color": "#D98F00",
        "label": "collaboratore038",
        "x": factor *  0.2420962992446567,
        "y": factor *  -0.09146808282643171
    },
    {
        "id": "collaboratore285",
        "livelli": 274,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "12/7/1993",
        "anni_anz_conv": 29,
        "centro": "centro5",
        "gruppo": "R00 - Staff SQS",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "9/1/1972",
        "eta": 50,
        "titolo_di_studio": "P.I.Elettronico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": 738,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FF67A3",
        "label": "collaboratore285",
        "x": factor *  -0.42666367550838435,
        "y": factor *  -0.3603754312572236
    },
    {
        "id": "collaboratore208",
        "livelli": 275,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/2/1988",
        "anni_anz_conv": 34,
        "centro": "centro7",
        "gruppo": "D11 - Geologia e Sicurezza Strutturale",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "7/10/1954",
        "eta": 68,
        "titolo_di_studio": "Scienze Agrarie",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -5565,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#A08CFF",
        "label": "collaboratore208",
        "x": factor *  -0.0357871464210715,
        "y": factor *  -0.28961922748896574
    },
    {
        "id": "collaboratore170",
        "livelli": 276,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/4/2021",
        "anni_anz_conv": 1,
        "centro": "centro3",
        "gruppo": "C10 - Tecnologie ICT per gestione e sicurezza informatica reti T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "26/8/1993",
        "eta": 29,
        "titolo_di_studio": "Ing.Elettrica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 8638,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C1A5",
        "label": "collaboratore170",
        "x": factor *  -0.2978728942562072,
        "y": factor *  0.11442553961174795
    },
    {
        "id": "collaboratore290",
        "livelli": 277,
        "ruolo": "Responsabile gruppo di ricerca",
        "gender": "Maschile",
        "data_anz_conv": "1/4/2011",
        "anni_anz_conv": 11,
        "centro": "centro2",
        "gruppo": "B06 - Reti attive: automazione e controllo",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "22/5/1983",
        "eta": 39,
        "titolo_di_studio": "Ing.Automazione",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile GdR",
        "anno_nascita": 4889,
        "generazione": "Millennials",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#00B825",
        "label": "collaboratore290",
        "x": factor *  -0.10800237981219363,
        "y": factor *  -0.3092374015900381
    },
    {
        "id": "collaboratore017",
        "livelli": 278,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "15/12/2021",
        "anni_anz_conv": 1,
        "centro": "centro9",
        "gruppo": "Q01 - Information and Communications Technology",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "25/3/1968",
        "eta": 54,
        "titolo_di_studio": "Informatica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -647,
        "generazione": "GenX",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FF62BA",
        "label": "collaboratore017",
        "x": factor *  -0.31421077420954546,
        "y": factor *  -0.03855290959022273
    },
    {
        "id": "collaboratore183",
        "livelli": 279,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/10/2021",
        "anni_anz_conv": 1,
        "centro": "centro2",
        "gruppo": "B01 - Materiali per l´energia",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede2",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "8/7/1985",
        "eta": 37,
        "titolo_di_studio": "Chimica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 5667,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#90AA00",
        "label": "collaboratore183",
        "x": factor *  -0.30178474974806,
        "y": factor *  -0.8253130842956918
    },
    {
        "id": "collaboratore322",
        "livelli": 280,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "NA",
        "anni_anz_conv": 0,
        "centro": "centro5",
        "gruppo": "R01 - Servizi generali e tecnici",
        "qualifica": "Personale non subordinato",
        "sede_di_lavoro": "sede1",
        "mansione": "NA",
        "data_di_nascita": "11/10/1989",
        "eta": 33,
        "titolo_di_studio": "Ragioniere/P.Comm",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": 7223,
        "generazione": "Millennials",
        "tenure": "Meno di un anno",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FE6E89",
        "label": "collaboratore322",
        "x": factor *  -0.4437550775808361,
        "y": factor *  -0.32166219071105395
    },
    {
        "id": "collaboratore227",
        "livelli": 281,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/1/2021",
        "anni_anz_conv": 1,
        "centro": "centro8",
        "gruppo": "A01 - Reti attive: gestione della distribuzione e della domanda",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "8/3/1994",
        "eta": 28,
        "titolo_di_studio": "Ing.Automazione",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 8832,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#F07F4B",
        "label": "collaboratore227",
        "x": factor *  0.06990013094584024,
        "y": factor *  0.24640799109975853
    },
    {
        "id": "collaboratore118",
        "livelli": 282,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "26/2/1990",
        "anni_anz_conv": 32,
        "centro": "centro3",
        "gruppo": "C11 - Misure e diagnostica di componenti T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "20/4/1968",
        "eta": 54,
        "titolo_di_studio": "P.I.Elettronico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -621,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C0BA",
        "label": "collaboratore118",
        "x": factor *  -0.48628353839197547,
        "y": factor *  0.0693959220739524
    },
    {
        "id": "collaboratore014",
        "livelli": 283,
        "ruolo": "Responsabile gruppo di ricerca",
        "gender": "Femminile",
        "data_anz_conv": "1/1/1990",
        "anni_anz_conv": 32,
        "centro": "centro3",
        "gruppo": "C08 - Sistemi e tecnologie innovative per le reti energetiche",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "23/12/1962",
        "eta": 60,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile GdR",
        "anno_nascita": -2566,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#00BE72",
        "label": "collaboratore014",
        "x": factor *  -0.523588438887375,
        "y": factor *  -0.3058073909712927
    },
    {
        "id": "collaboratore266",
        "livelli": 284,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "6/3/1980",
        "anni_anz_conv": 42,
        "centro": "centro1",
        "gruppo": "P00 - Staff HRP",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto con particolare responsabilità ed autonomia",
        "data_di_nascita": "10/4/1960",
        "eta": 62,
        "titolo_di_studio": "Ragioniere/P.Comm",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -3553,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#EA6AF0",
        "label": "collaboratore266",
        "x": factor *  -0.8558446143236692,
        "y": factor *  0.0186080624966678
    },
    {
        "id": "collaboratore001",
        "livelli": 285,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/1/2012",
        "anni_anz_conv": 10,
        "centro": "centro8",
        "gruppo": "A00 - Staff SSE",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "16/3/1980",
        "eta": 42,
        "titolo_di_studio": "Ing.Aerospaziale",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 3727,
        "generazione": "GenX",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#F8766D",
        "label": "collaboratore001",
        "x": factor *  0.11648264742204528,
        "y": factor *  0.04502759840768755
    },
    {
        "id": "collaboratore194",
        "livelli": 286,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "4/6/1990",
        "anni_anz_conv": 32,
        "centro": "centro1",
        "gruppo": "P00 - Staff HRP",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "2/2/1970",
        "eta": 52,
        "titolo_di_studio": "Add.Segr.D´Azienda",
        "tipo": "Professionale",
        "responsabile": "NA",
        "anno_nascita": 32,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#EA6AF0",
        "label": "collaboratore194",
        "x": factor *  -0.21069388463820538,
        "y": factor *  -0.08243062630385967
    },
    {
        "id": "collaboratore193",
        "livelli": 287,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/2/2020",
        "anni_anz_conv": 2,
        "centro": "centro3",
        "gruppo": "C10 - Tecnologie ICT per gestione e sicurezza informatica reti T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "14/2/1994",
        "eta": 28,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 8810,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C1A5",
        "label": "collaboratore193",
        "x": factor *  -0.38491773021497044,
        "y": factor *  0.19237027581930355
    },
    {
        "id": "collaboratore222",
        "livelli": 288,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "8/2/2021",
        "anni_anz_conv": 1,
        "centro": "centro2",
        "gruppo": "B01 - Materiali per l´energia",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "10/6/1984",
        "eta": 38,
        "titolo_di_studio": "Ing.Materiali",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 5274,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#90AA00",
        "label": "collaboratore222",
        "x": factor *  -0.5414457347955681,
        "y": factor *  -0.787725388621659
    },
    {
        "id": "collaboratore210",
        "livelli": 289,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/12/2021",
        "anni_anz_conv": 1,
        "centro": "centro3",
        "gruppo": "C11 - Misure e diagnostica di componenti T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "23/12/1997",
        "eta": 25,
        "titolo_di_studio": "Ing.Elettrica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 10218,
        "generazione": "GenZ",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C0BA",
        "label": "collaboratore210",
        "x": factor *  -0.6635361993626712,
        "y": factor *  -0.11135793979555764
    },
    {
        "id": "collaboratore299",
        "livelli": 290,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/1/2013",
        "anni_anz_conv": 9,
        "centro": "centro7",
        "gruppo": "D05 - CLM Clima e Meteorologia",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "12/11/1983",
        "eta": 39,
        "titolo_di_studio": "Scienze Ambientali",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 5063,
        "generazione": "Millennials",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B4EF",
        "label": "collaboratore299",
        "x": factor *  0.16430539411884415,
        "y": factor *  -0.4273713200832955
    },
    {
        "id": "collaboratore211",
        "livelli": 291,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "15/9/2020",
        "anni_anz_conv": 2,
        "centro": "centro8",
        "gruppo": "A06 - Scenari e Analisi di Sistema",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "23/7/1985",
        "eta": 37,
        "titolo_di_studio": "Ing.Ambientale",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 5682,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#CB9700",
        "label": "collaboratore211",
        "x": factor *  0.3708036747963155,
        "y": factor *  -0.008605038515835717
    },
    {
        "id": "collaboratore081",
        "livelli": 292,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/5/2015",
        "anni_anz_conv": 7,
        "centro": "centro5",
        "gruppo": "R01 - Servizi generali e tecnici",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "19/12/1984",
        "eta": 38,
        "titolo_di_studio": "Ing.Gestionale",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 5466,
        "generazione": "Millennials",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FE6E89",
        "label": "collaboratore081",
        "x": factor *  -0.360745327784321,
        "y": factor *  -0.3673314428426283
    },
    {
        "id": "collaboratore219",
        "livelli": 293,
        "ruolo": "Vice capo progetto",
        "gender": "Maschile",
        "data_anz_conv": "12/1/2015",
        "anni_anz_conv": 7,
        "centro": "centro7",
        "gruppo": "D08 - LCA Analisi ciclo di vita",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "3/6/1986",
        "eta": 36,
        "titolo_di_studio": "Ing.Ambientale",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 5997,
        "generazione": "Millennials",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#7498FF",
        "label": "collaboratore219",
        "x": factor *  0.2274002220100817,
        "y": factor *  -0.40283355237604357
    },
    {
        "id": "collaboratore085",
        "livelli": 294,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/9/1989",
        "anni_anz_conv": 33,
        "centro": "centro3",
        "gruppo": "C09 - Tecnologie per le reti attive di distribuzione",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto con particolare responsabilità ed autonomia",
        "data_di_nascita": "15/2/1959",
        "eta": 63,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -3973,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C08D",
        "label": "collaboratore085",
        "x": factor *  -0.6712598585602457,
        "y": factor *  0.3603604814976118
    },
    {
        "id": "collaboratore152",
        "livelli": 295,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "24/9/2015",
        "anni_anz_conv": 7,
        "centro": "centro3",
        "gruppo": "C10 - Tecnologie ICT per gestione e sicurezza informatica reti T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "1/8/1981",
        "eta": 41,
        "titolo_di_studio": "Scienze e Tecnologie dell´Informazione",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 4230,
        "generazione": "GenX",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C1A5",
        "label": "collaboratore152",
        "x": factor *  -0.4025960937863061,
        "y": factor *  0.1423249076284312
    },
    {
        "id": "collaboratore314",
        "livelli": 296,
        "ruolo": "Responsabile gruppo di ricerca",
        "gender": "Maschile",
        "data_anz_conv": "1/3/1988",
        "anni_anz_conv": 34,
        "centro": "centro2",
        "gruppo": "B04 - Fotovoltaico a concentrazione",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede2",
        "mansione": "Capo Gruppo di Ricerca",
        "data_di_nascita": "7/4/1961",
        "eta": 61,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile GdR",
        "anno_nascita": -3191,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#4CB400",
        "label": "collaboratore314",
        "x": factor *  -0.20966253964736925,
        "y": factor *  -0.7260017899821507
    },
    {
        "id": "collaboratore099",
        "livelli": 297,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/4/2019",
        "anni_anz_conv": 3,
        "centro": "centro3",
        "gruppo": "C10 - Tecnologie ICT per gestione e sicurezza informatica reti T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "24/1/1973",
        "eta": 49,
        "titolo_di_studio": "Informatica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 1119,
        "generazione": "GenX",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C1A5",
        "label": "collaboratore099",
        "x": factor *  -0.3370635635133341,
        "y": factor *  0.006772828769023853
    },
    {
        "id": "collaboratore128",
        "livelli": 298,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/9/1986",
        "anni_anz_conv": 36,
        "centro": "centro7",
        "gruppo": "D06 - Qualità dell´aria e Modelli",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "4/7/1957",
        "eta": 65,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -4564,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00ADFB",
        "label": "collaboratore128",
        "x": factor *  0.21821915384355517,
        "y": factor *  -0.4859456751983613
    },
    {
        "id": "collaboratore027",
        "livelli": 299,
        "ruolo": "Capo Progetto RDS",
        "gender": "Maschile",
        "data_anz_conv": "1/10/1986",
        "anni_anz_conv": 36,
        "centro": "centro3",
        "gruppo": "C00 - Staff TTD",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Capo Gruppo di Ricerca",
        "data_di_nascita": "20/9/1959",
        "eta": 63,
        "titolo_di_studio": "Ing.Elettronica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile di Progetto RdS",
        "anno_nascita": -3756,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#00BC53",
        "label": "collaboratore027",
        "x": factor *  -0.3597930334988233,
        "y": factor *  0.009768396174708371
    },
    {
        "id": "collaboratore053",
        "livelli": 300,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/10/2011",
        "anni_anz_conv": 11,
        "centro": "centro2",
        "gruppo": "B04 - Fotovoltaico a concentrazione",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede2",
        "mansione": "Esperto",
        "data_di_nascita": "29/5/1962",
        "eta": 60,
        "titolo_di_studio": "Ing.Elettronica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -2774,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#4CB400",
        "label": "collaboratore053",
        "x": factor *  -0.22851426038365952,
        "y": factor *  -0.9667453764129377
    },
    {
        "id": "collaboratore106",
        "livelli": 301,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "16/9/2020",
        "anni_anz_conv": 2,
        "centro": "centro7",
        "gruppo": "D05 - CLM Clima e Meteorologia",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "15/1/1978",
        "eta": 44,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 2936,
        "generazione": "GenX",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B4EF",
        "label": "collaboratore106",
        "x": factor *  0.1148192305064244,
        "y": factor *  -0.3405894070746137
    },
    {
        "id": "collaboratore096",
        "livelli": 302,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "27/5/1985",
        "anni_anz_conv": 37,
        "centro": "centro2",
        "gruppo": "B01 - Materiali per l´energia",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede2",
        "mansione": "Assistente",
        "data_di_nascita": "28/10/1963",
        "eta": 59,
        "titolo_di_studio": "Analista Chimico",
        "tipo": "Professionale",
        "responsabile": "NA",
        "anno_nascita": -2257,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#90AA00",
        "label": "collaboratore096",
        "x": factor *  -0.30037626498392955,
        "y": factor *  -0.6881635424068383
    },
    {
        "id": "collaboratore065",
        "livelli": 303,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/1/2013",
        "anni_anz_conv": 9,
        "centro": "centro3",
        "gruppo": "C10 - Tecnologie ICT per gestione e sicurezza informatica reti T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "13/10/1979",
        "eta": 43,
        "titolo_di_studio": "Ing.Automazione",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 3572,
        "generazione": "GenX",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C1A5",
        "label": "collaboratore065",
        "x": factor *  -0.22293609262802505,
        "y": factor *  0.1007469545637456
    },
    {
        "id": "collaboratore275",
        "livelli": 304,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "15/3/2021",
        "anni_anz_conv": 1,
        "centro": "centro2",
        "gruppo": "B02 - Impianti e processi",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "28/2/1995",
        "eta": 27,
        "titolo_di_studio": "Ing.Energetica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 9189,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#74B000",
        "label": "collaboratore275",
        "x": factor *  0.3193038842545235,
        "y": factor *  0.023302347009525937
    },
    {
        "id": "collaboratore045",
        "livelli": 305,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/6/2020",
        "anni_anz_conv": 2,
        "centro": "centro7",
        "gruppo": "D11 - Geologia e Sicurezza Strutturale",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "25/4/1983",
        "eta": 39,
        "titolo_di_studio": "Geologia",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 4862,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#A08CFF",
        "label": "collaboratore045",
        "x": factor *  0.40953804861109244,
        "y": factor *  -0.7326525762707397
    },
    {
        "id": "collaboratore122",
        "livelli": 306,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "19/5/1986",
        "anni_anz_conv": 36,
        "centro": "centro9",
        "gruppo": "Q00 - Staff ASI",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Vice Responsabile di Funzione",
        "data_di_nascita": "26/6/1959",
        "eta": 63,
        "titolo_di_studio": "Ing.Elettrotecnica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -3842,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FE61CE",
        "label": "collaboratore122",
        "x": factor *  -0.2538703167439488,
        "y": factor *  -0.18364078021611474
    },
    {
        "id": "collaboratore249",
        "livelli": 307,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "16/3/1990",
        "anni_anz_conv": 32,
        "centro": "centro2",
        "gruppo": "B00 - Staff TGM",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Capo Gruppo di Ricerca",
        "data_di_nascita": "9/2/1964",
        "eta": 58,
        "titolo_di_studio": "Ing.Aeronautica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -2153,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#A7A400",
        "label": "collaboratore249",
        "x": factor *  -0.22241403264421333,
        "y": factor *  -0.30991343892884593
    },
    {
        "id": "collaboratore048",
        "livelli": 308,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/1/1988",
        "anni_anz_conv": 34,
        "centro": "centro8",
        "gruppo": "A05 - Efficienza energetica",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "21/7/1961",
        "eta": 61,
        "titolo_di_studio": "Ing.Meccanica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -3086,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#D98F00",
        "label": "collaboratore048",
        "x": factor *  0.02981123547967157,
        "y": factor *  -0.16634486678332905
    },
    {
        "id": "collaboratore059",
        "livelli": 309,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/4/1990",
        "anni_anz_conv": 32,
        "centro": "centro2",
        "gruppo": "B06 - Reti attive: automazione e controllo",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto con particolare responsabilità ed autonomia",
        "data_di_nascita": "24/4/1963",
        "eta": 59,
        "titolo_di_studio": "Ing.Aeronautica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -2444,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B825",
        "label": "collaboratore059",
        "x": factor *  -0.17257956672661745,
        "y": factor *  -0.31242532463733086
    },
    {
        "id": "collaboratore050",
        "livelli": 310,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "16/9/1989",
        "anni_anz_conv": 33,
        "centro": "centro7",
        "gruppo": "D07 - ART Analisi risorse e territorio",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "17/6/1969",
        "eta": 53,
        "titolo_di_studio": "Perito Aziendale C.L.E.",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -198,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#1EA3FF",
        "label": "collaboratore050",
        "x": factor *  0.3260073911683854,
        "y": factor *  -0.33138068215733374
    },
    {
        "id": "collaboratore021",
        "livelli": 311,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/9/2020",
        "anni_anz_conv": 2,
        "centro": "centro3",
        "gruppo": "C09 - Tecnologie per le reti attive di distribuzione",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "2/6/1992",
        "eta": 30,
        "titolo_di_studio": "Ing.Elettrica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 8188,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C08D",
        "label": "collaboratore021",
        "x": factor *  -0.5779431357401983,
        "y": factor *  0.1980065992401867
    },
    {
        "id": "collaboratore135",
        "livelli": 312,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/1/2012",
        "anni_anz_conv": 10,
        "centro": "centro9",
        "gruppo": "Q01 - Information and Communications Technology",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto",
        "data_di_nascita": "8/5/1982",
        "eta": 40,
        "titolo_di_studio": "Informatica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 4510,
        "generazione": "Millennials",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FF62BA",
        "label": "collaboratore135",
        "x": factor *  -0.2488750501599869,
        "y": factor *  -0.10313687383929215
    },
    {
        "id": "collaboratore237",
        "livelli": 313,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "13/3/1981",
        "anni_anz_conv": 41,
        "centro": "centro8",
        "gruppo": "A05 - Efficienza energetica",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "29/12/1954",
        "eta": 68,
        "titolo_di_studio": "Ing.Civile Edile",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -5482,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#D98F00",
        "label": "collaboratore237",
        "x": factor *  0.18351872415080894,
        "y": factor *  -0.1475136235008767
    },
    {
        "id": "collaboratore141",
        "livelli": 314,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/12/2019",
        "anni_anz_conv": 3,
        "centro": "centro6",
        "gruppo": "M00 - Staff DG",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "15/4/1977",
        "eta": 45,
        "titolo_di_studio": "Giurisprudenza",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 2661,
        "generazione": "GenX",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#C07FFF",
        "label": "collaboratore141",
        "x": factor *  -0.30952076871460055,
        "y": factor *  -0.21179404211856856
    },
    {
        "id": "collaboratore178",
        "livelli": 315,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "12/1/2015",
        "anni_anz_conv": 7,
        "centro": "centro8",
        "gruppo": "A01 - Reti attive: gestione della distribuzione e della domanda",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "19/7/1989",
        "eta": 33,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 7139,
        "generazione": "Millennials",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#F07F4B",
        "label": "collaboratore178",
        "x": factor *  0.020088289885259458,
        "y": factor *  0.10529204194684971
    },
    {
        "id": "collaboratore002",
        "livelli": 316,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/4/1981",
        "anni_anz_conv": 41,
        "centro": "centro9",
        "gruppo": "Q00 - Staff ASI",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Assistente",
        "data_di_nascita": "11/12/1961",
        "eta": 61,
        "titolo_di_studio": "Licenza Media",
        "tipo": "Media",
        "responsabile": "NA",
        "anno_nascita": -2943,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FE61CE",
        "label": "collaboratore002",
        "x": factor *  -0.4541069541674617,
        "y": factor *  -0.13096310725432114
    },
    {
        "id": "collaboratore104",
        "livelli": 317,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/11/2020",
        "anni_anz_conv": 2,
        "centro": "centro7",
        "gruppo": "D05 - CLM Clima e Meteorologia",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "30/5/1988",
        "eta": 34,
        "titolo_di_studio": "Ing.Ambientale",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 6724,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B4EF",
        "label": "collaboratore104",
        "x": factor *  0.2484202107007576,
        "y": factor *  -0.5319040452598769
    },
    {
        "id": "collaboratore040",
        "livelli": 318,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "12/1/2015",
        "anni_anz_conv": 7,
        "centro": "centro7",
        "gruppo": "D07 - ART Analisi risorse e territorio",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista Esperto con comp. magg. ril.",
        "data_di_nascita": "3/2/1984",
        "eta": 38,
        "titolo_di_studio": "Ing.Ambientale",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 5146,
        "generazione": "Millennials",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#1EA3FF",
        "label": "collaboratore040",
        "x": factor *  0.28961001598804437,
        "y": factor *  -0.39884909450989847
    },
    {
        "id": "collaboratore317",
        "livelli": 319,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "6/6/1988",
        "anni_anz_conv": 34,
        "centro": "centro3",
        "gruppo": "C09 - Tecnologie per le reti attive di distribuzione",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "7/12/1964",
        "eta": 58,
        "titolo_di_studio": "P.I.Elettrotecnico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -1851,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C08D",
        "label": "collaboratore317",
        "x": factor *  -0.45629190730354807,
        "y": factor *  0.21931340500413765
    },
    {
        "id": "collaboratore084",
        "livelli": 320,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "16/1/2004",
        "anni_anz_conv": 18,
        "centro": "centro1",
        "gruppo": "P00 - Staff HRP",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Addetto  con comp. magg. ril.",
        "data_di_nascita": "2/8/1962",
        "eta": 60,
        "titolo_di_studio": "Ragioniere/P.Comm",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -2709,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#EA6AF0",
        "label": "collaboratore084",
        "x": factor *  -0.2825166262302735,
        "y": factor *  -0.2528956658689735
    },
    {
        "id": "collaboratore043",
        "livelli": 321,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "NA",
        "anni_anz_conv": 0,
        "centro": "centro2",
        "gruppo": "B06 - Reti attive: automazione e controllo",
        "qualifica": "Personale non subordinato",
        "sede_di_lavoro": "sede1",
        "mansione": "NA",
        "data_di_nascita": "12/3/1970",
        "eta": 52,
        "titolo_di_studio": "P.I.Elettronico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": 70,
        "generazione": "GenX",
        "tenure": "Meno di un anno",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00B825",
        "label": "collaboratore043",
        "x": factor *  -0.4995133933897882,
        "y": factor *  -0.5138768428819351
    },
    {
        "id": "collaboratore296",
        "livelli": 322,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "12/3/1979",
        "anni_anz_conv": 43,
        "centro": "centro8",
        "gruppo": "A05 - Efficienza energetica",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto",
        "data_di_nascita": "3/9/1960",
        "eta": 62,
        "titolo_di_studio": "Fisica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile di Progetto Strategico",
        "anno_nascita": -3407,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#D98F00",
        "label": "collaboratore296",
        "x": factor *  0.006129694589221968,
        "y": factor *  -0.068924077806098
    },
    {
        "id": "collaboratore022",
        "livelli": 323,
        "ruolo": "NA",
        "gender": "Femminile",
        "data_anz_conv": "1/9/2015",
        "anni_anz_conv": 7,
        "centro": "centro9",
        "gruppo": "Q00 - Staff ASI",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "27/8/1975",
        "eta": 47,
        "titolo_di_studio": "Economia",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 2064,
        "generazione": "GenX",
        "tenure": "6-10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#FE61CE",
        "label": "collaboratore022",
        "x": factor *  -0.23739489458669105,
        "y": factor *  -0.1729643771931001
    },
    {
        "id": "collaboratore150",
        "livelli": 324,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "20/7/1981",
        "anni_anz_conv": 41,
        "centro": "centro2",
        "gruppo": "B02 - Impianti e processi",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Esperto con particolare responsabilità ed autonomia",
        "data_di_nascita": "2/3/1955",
        "eta": 67,
        "titolo_di_studio": "Ing.Meccanica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": -5419,
        "generazione": "Boomers",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#74B000",
        "label": "collaboratore150",
        "x": factor *  -0.09390254160098821,
        "y": factor *  -0.6043113156916714
    },
    {
        "id": "collaboratore154",
        "livelli": 325,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/1/2011",
        "anni_anz_conv": 11,
        "centro": "centro8",
        "gruppo": "A07 - Regolazione dei Mercati Energetici",
        "qualifica": "Quadro",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "21/9/1981",
        "eta": 41,
        "titolo_di_studio": "Ing.Informatica",
        "tipo": "Laurea Magistrale",
        "responsabile": "Responsabile GdR",
        "anno_nascita": 4281,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Responsabilità",
        "color": "#BA9E00",
        "label": "collaboratore154",
        "x": factor *  0.005219545961431482,
        "y": factor *  0.1294825397256767
    },
    {
        "id": "collaboratore056",
        "livelli": 326,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "3/6/1991",
        "anni_anz_conv": 31,
        "centro": "centro3",
        "gruppo": "C10 - Tecnologie ICT per gestione e sicurezza informatica reti T&D",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "8/1/1966",
        "eta": 56,
        "titolo_di_studio": "P.I.Elettrotecnico",
        "tipo": "Diploma",
        "responsabile": "NA",
        "anno_nascita": -1454,
        "generazione": "GenX",
        "tenure": "Più di 10 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C1A5",
        "label": "collaboratore056",
        "x": factor *  -0.27747300695623367,
        "y": factor *  0.031196860650539282
    },
    {
        "id": "collaboratore231",
        "livelli": 327,
        "ruolo": "NA",
        "gender": "Maschile",
        "data_anz_conv": "1/2/2020",
        "anni_anz_conv": 2,
        "centro": "centro3",
        "gruppo": "C09 - Tecnologie per le reti attive di distribuzione",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista  con comp. magg. ril.",
        "data_di_nascita": "28/5/1991",
        "eta": 31,
        "titolo_di_studio": "Ing.Elettrica",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 7817,
        "generazione": "Millennials",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#00C08D",
        "label": "collaboratore231",
        "x": factor *  -0.5336307856307998,
        "y": factor *  0.13065656440146922
    },
    {
        "id": "collaboratore146",
        "livelli": 328,
        "ruolo": "Vice capo progetto",
        "gender": "Maschile",
        "data_anz_conv": "7/1/2020",
        "anni_anz_conv": 2,
        "centro": "centro8",
        "gruppo": "A01 - Reti attive: gestione della distribuzione e della domanda",
        "qualifica": "Impiegato",
        "sede_di_lavoro": "sede1",
        "mansione": "Tecnico Specialista",
        "data_di_nascita": "5/6/1979",
        "eta": 43,
        "titolo_di_studio": "Scienze Ambientali",
        "tipo": "Laurea Magistrale",
        "responsabile": "NA",
        "anno_nascita": 3442,
        "generazione": "GenX",
        "tenure": "1-3 anni",
        "incarichi_resp": "Non ha incarichi",
        "color": "#F07F4B",
        "label": "collaboratore146",
        "x": factor *  -0.07488869813109222,
        "y": factor *  -0.25818931573197457
    }
]
export default network

import Stars from "./Stars";
import ScatterChart from "./ScatterChart";
import {PieColors} from "../utils/PieColors";
import {ViolinChart} from "./ViolinChart";

const ViolinLayout = () => {

    const scatters = [1, 2, 3, 4]

    return (
        <div className="container">
            <div className="row my-5">
                <div className="col-12">
                    <div className="d-flex justify-content-center">
                        <Stars layout={3} perc="50%"/>
                    </div>
                </div>
            </div>
            <div className="row">
                {
                    scatters.map((e, i) => (
                        <div key={'scatter_' + i} className="col-lg-6 mt-3">
                            <div className="box">
                                <div className="box-title">Età percepita - età effettiva</div>
                                <div className="mt-3">
                                    <Stars percColor={PieColors.lightBlue.light_code} withTitle={false} layout={3} perc="50%" />
                                </div>
                                <ViolinChart unique={'scatter_' + i}/>
                                <p className="text-center" style={{fontSize: 25}}>N=10</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default ViolinLayout

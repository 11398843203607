import {useEffect, useRef, useState} from "react";
import {Chart} from "chart.js";
import 'chart.js/auto'
import {Chart as GoogleChart} from "react-google-charts";
import {useWindowSize} from "../utils/windowSize";
import {ReactGoogleChartEvent} from "react-google-charts";
import {GoogleChartWrapper} from "react-google-charts";
import {PieColors} from "../utils/PieColors";


export const GoogleDonutChart = ({color}) => {
    const values = [
        ["1", 6],
        ["2", 11],
        ["3", 11],
        ["4", 11],
        ["5", 11],
        ["6", 11],
        ["7", 11],
        ["8", 11],
    ]
    const [data, setData] = useState([
        ["Task", "Hours per Day"],
        ...values
    ])
    const colors = [PieColors.lightBlue.dark_code, PieColors.blue.dark_code, PieColors.red.light_code, PieColors.red.dark_code]
    const options = {
        pieHole: 0.3,
        legend: {position: 'none'},
        colors: colors,
        backgroundColor: {fill: 'transparent'},
        pieSliceBorderColor: "transparent",
        width: '100%',
        height: '100%',
        chartArea: {'width': '80%', 'height': '100%'},
    };
    return (
        <>
            <div className="">
                <div className="box-title text-center">Title</div>
            </div>
            <div className="donut-legend">
                {
                    values.map((e, i) => (
                        <div key={'gdc_' + i} className="donut-legend-item-outer">
                            <div className="donut-legend-item"
                                 style={{backgroundColor: colors[i % colors.length]}}></div>
                            <div className="donut-legend-item-title">{e[0]}</div>
                        </div>
                    ))
                }
            </div>
            <div style={{height: 400, position: 'relative', left: '-7%'}}>
                <GoogleChart
                    chartType="PieChart"
                    data={data}
                    options={options}
                />
            </div>
        </>
    );
}

const DonutChart = ({unique}) => {
    useEffect(() => {
        const ctx = document.getElementById(unique);
        const c = new Chart(ctx, {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: [300, 50, 100, 300, 50, 100, 100, 100],
                    backgroundColor: [
                        'rgb(255, 99, 132)',
                        'rgb(54, 162, 235)',
                        'rgb(255, 205, 86)',
                        'rgb(255, 99, 132)',
                        'rgb(54, 162, 235)',
                        'rgb(255, 205, 86)',
                        'rgb(54, 162, 235)',
                        'rgb(255, 205, 86)',
                    ],
                    hoverOffset: 4
                }]
            },
        });
        return () => {
            c.destroy()
        }
    })
    return (<div className="pie-outer">
        <canvas id={unique}></canvas>
    </div>)
}

export default DonutChart

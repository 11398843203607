export const paths = {
    dashboard: '/',
    in_deep: '/in-deep',
    moduli: '/moduli',
    partecipanti: '/partecipanti',
    presentazione: '/presentazione',
    valori: '/valori',
    somministrazioni: '/somministrazioni',
    network: '/network',
    leader_discovery: '/leader_discovery',
    analytics: '/analytics'
}

export const pages = [
    {
        name: 'Dashboard',
        link: '/'
    },
    {
        name: 'In deep',
        link: '/in-deep'
    },
    {
        name: 'Network',
        link: '/network'
    },
    {
        name: 'Leader discovery',
        link: '/leader_discovery'
    },
    {
        name: 'Analytics',
        link: '/analytics'
    },
    {
        name: 'Wiki',
        link: '#'
    },
    {
        name: 'Formazione e servizi',
        link: '#'
    },
]

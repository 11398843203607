export const networkLink = [
    {
        "from": "collaboratore196",
        "to": "collaboratore004"
    },
    {
        "from": "collaboratore196",
        "to": "collaboratore213"
    },
    {
        "from": "collaboratore196",
        "to": "collaboratore306"
    },
    {
        "from": "collaboratore196",
        "to": "collaboratore054"
    },
    {
        "from": "collaboratore196",
        "to": "collaboratore060"
    },
    {
        "from": "collaboratore196",
        "to": "collaboratore026"
    },
    {
        "from": "collaboratore196",
        "to": "collaboratore243"
    },
    {
        "from": "collaboratore196",
        "to": "collaboratore278"
    },
    {
        "from": "collaboratore196",
        "to": "collaboratore001"
    },
    {
        "from": "collaboratore174",
        "to": "collaboratore295"
    },
    {
        "from": "collaboratore174",
        "to": "collaboratore303"
    },
    {
        "from": "collaboratore174",
        "to": "collaboratore013"
    },
    {
        "from": "collaboratore174",
        "to": "collaboratore188"
    },
    {
        "from": "collaboratore174",
        "to": "collaboratore224"
    },
    {
        "from": "collaboratore174",
        "to": "collaboratore147"
    },
    {
        "from": "collaboratore174",
        "to": "collaboratore284"
    },
    {
        "from": "collaboratore174",
        "to": "collaboratore128"
    },
    {
        "from": "collaboratore082",
        "to": "collaboratore042"
    },
    {
        "from": "collaboratore082",
        "to": "collaboratore289"
    },
    {
        "from": "collaboratore082",
        "to": "collaboratore179"
    },
    {
        "from": "collaboratore082",
        "to": "collaboratore200"
    },
    {
        "from": "collaboratore082",
        "to": "collaboratore203"
    },
    {
        "from": "collaboratore082",
        "to": "collaboratore079"
    },
    {
        "from": "collaboratore082",
        "to": "collaboratore280"
    },
    {
        "from": "collaboratore082",
        "to": "collaboratore313"
    },
    {
        "from": "collaboratore082",
        "to": "collaboratore208"
    },
    {
        "from": "collaboratore082",
        "to": "collaboratore211"
    },
    {
        "from": "collaboratore313",
        "to": "collaboratore303"
    },
    {
        "from": "collaboratore313",
        "to": "collaboratore129"
    },
    {
        "from": "collaboratore313",
        "to": "collaboratore184"
    },
    {
        "from": "collaboratore313",
        "to": "collaboratore192"
    },
    {
        "from": "collaboratore313",
        "to": "collaboratore179"
    },
    {
        "from": "collaboratore313",
        "to": "collaboratore041"
    },
    {
        "from": "collaboratore313",
        "to": "collaboratore280"
    },
    {
        "from": "collaboratore313",
        "to": "collaboratore100"
    },
    {
        "from": "collaboratore313",
        "to": "collaboratore082"
    },
    {
        "from": "collaboratore313",
        "to": "collaboratore290"
    },
    {
        "from": "collaboratore152",
        "to": "collaboratore057"
    },
    {
        "from": "collaboratore152",
        "to": "collaboratore305"
    },
    {
        "from": "collaboratore152",
        "to": "collaboratore164"
    },
    {
        "from": "collaboratore152",
        "to": "collaboratore049"
    },
    {
        "from": "collaboratore152",
        "to": "collaboratore254"
    },
    {
        "from": "collaboratore152",
        "to": "collaboratore102"
    },
    {
        "from": "collaboratore152",
        "to": "collaboratore027"
    },
    {
        "from": "collaboratore152",
        "to": "collaboratore065"
    },
    {
        "from": "collaboratore152",
        "to": "collaboratore056"
    },
    {
        "from": "collaboratore233",
        "to": "collaboratore070"
    },
    {
        "from": "collaboratore233",
        "to": "collaboratore234"
    },
    {
        "from": "collaboratore233",
        "to": "collaboratore201"
    },
    {
        "from": "collaboratore233",
        "to": "collaboratore107"
    },
    {
        "from": "collaboratore233",
        "to": "collaboratore256"
    },
    {
        "from": "collaboratore233",
        "to": "collaboratore307"
    },
    {
        "from": "collaboratore233",
        "to": "collaboratore083"
    },
    {
        "from": "collaboratore233",
        "to": "collaboratore322"
    },
    {
        "from": "collaboratore233",
        "to": "collaboratore081"
    },
    {
        "from": "collaboratore278",
        "to": "collaboratore004"
    },
    {
        "from": "collaboratore278",
        "to": "collaboratore213"
    },
    {
        "from": "collaboratore278",
        "to": "collaboratore054"
    },
    {
        "from": "collaboratore278",
        "to": "collaboratore060"
    },
    {
        "from": "collaboratore278",
        "to": "collaboratore119"
    },
    {
        "from": "collaboratore278",
        "to": "collaboratore230"
    },
    {
        "from": "collaboratore278",
        "to": "collaboratore026"
    },
    {
        "from": "collaboratore278",
        "to": "collaboratore097"
    },
    {
        "from": "collaboratore278",
        "to": "collaboratore001"
    },
    {
        "from": "collaboratore278",
        "to": "collaboratore296"
    },
    {
        "from": "collaboratore252",
        "to": "collaboratore261"
    },
    {
        "from": "collaboratore252",
        "to": "collaboratore070"
    },
    {
        "from": "collaboratore252",
        "to": "collaboratore025"
    },
    {
        "from": "collaboratore252",
        "to": "collaboratore114"
    },
    {
        "from": "collaboratore252",
        "to": "collaboratore251"
    },
    {
        "from": "collaboratore252",
        "to": "collaboratore064"
    },
    {
        "from": "collaboratore252",
        "to": "collaboratore190"
    },
    {
        "from": "collaboratore252",
        "to": "collaboratore169"
    },
    {
        "from": "collaboratore252",
        "to": "collaboratore314"
    },
    {
        "from": "collaboratore252",
        "to": "collaboratore053"
    },
    {
        "from": "collaboratore268",
        "to": "collaboratore295"
    },
    {
        "from": "collaboratore268",
        "to": "collaboratore005"
    },
    {
        "from": "collaboratore268",
        "to": "collaboratore229"
    },
    {
        "from": "collaboratore268",
        "to": "collaboratore200"
    },
    {
        "from": "collaboratore268",
        "to": "collaboratore297"
    },
    {
        "from": "collaboratore268",
        "to": "collaboratore155"
    },
    {
        "from": "collaboratore268",
        "to": "collaboratore103"
    },
    {
        "from": "collaboratore268",
        "to": "collaboratore312"
    },
    {
        "from": "collaboratore268",
        "to": "collaboratore165"
    },
    {
        "from": "collaboratore292",
        "to": "collaboratore214"
    },
    {
        "from": "collaboratore292",
        "to": "collaboratore115"
    },
    {
        "from": "collaboratore292",
        "to": "collaboratore052"
    },
    {
        "from": "collaboratore292",
        "to": "collaboratore304"
    },
    {
        "from": "collaboratore292",
        "to": "collaboratore256"
    },
    {
        "from": "collaboratore292",
        "to": "collaboratore238"
    },
    {
        "from": "collaboratore078",
        "to": "collaboratore074"
    },
    {
        "from": "collaboratore078",
        "to": "collaboratore181"
    },
    {
        "from": "collaboratore078",
        "to": "collaboratore092"
    },
    {
        "from": "collaboratore078",
        "to": "collaboratore274"
    },
    {
        "from": "collaboratore078",
        "to": "collaboratore132"
    },
    {
        "from": "collaboratore078",
        "to": "collaboratore018"
    },
    {
        "from": "collaboratore078",
        "to": "collaboratore238"
    },
    {
        "from": "collaboratore078",
        "to": "collaboratore165"
    },
    {
        "from": "collaboratore078",
        "to": "collaboratore076"
    },
    {
        "from": "collaboratore078",
        "to": "collaboratore290"
    },
    {
        "from": "collaboratore173",
        "to": "collaboratore136"
    },
    {
        "from": "collaboratore173",
        "to": "collaboratore074"
    },
    {
        "from": "collaboratore173",
        "to": "collaboratore160"
    },
    {
        "from": "collaboratore173",
        "to": "collaboratore035"
    },
    {
        "from": "collaboratore173",
        "to": "collaboratore090"
    },
    {
        "from": "collaboratore173",
        "to": "collaboratore055"
    },
    {
        "from": "collaboratore173",
        "to": "collaboratore183"
    },
    {
        "from": "collaboratore173",
        "to": "collaboratore096"
    },
    {
        "from": "collaboratore173",
        "to": "collaboratore146"
    },
    {
        "from": "collaboratore097",
        "to": "collaboratore042"
    },
    {
        "from": "collaboratore097",
        "to": "collaboratore004"
    },
    {
        "from": "collaboratore097",
        "to": "collaboratore213"
    },
    {
        "from": "collaboratore097",
        "to": "collaboratore289"
    },
    {
        "from": "collaboratore097",
        "to": "collaboratore060"
    },
    {
        "from": "collaboratore097",
        "to": "collaboratore079"
    },
    {
        "from": "collaboratore097",
        "to": "collaboratore159"
    },
    {
        "from": "collaboratore097",
        "to": "collaboratore110"
    },
    {
        "from": "collaboratore097",
        "to": "collaboratore278"
    },
    {
        "from": "collaboratore097",
        "to": "collaboratore194"
    },
    {
        "from": "collaboratore264",
        "to": "collaboratore093"
    },
    {
        "from": "collaboratore264",
        "to": "collaboratore024"
    },
    {
        "from": "collaboratore264",
        "to": "collaboratore049"
    },
    {
        "from": "collaboratore264",
        "to": "collaboratore254"
    },
    {
        "from": "collaboratore264",
        "to": "collaboratore102"
    },
    {
        "from": "collaboratore264",
        "to": "collaboratore193"
    },
    {
        "from": "collaboratore264",
        "to": "collaboratore099"
    },
    {
        "from": "collaboratore264",
        "to": "collaboratore027"
    },
    {
        "from": "collaboratore264",
        "to": "collaboratore056"
    },
    {
        "from": "collaboratore046",
        "to": "collaboratore305"
    },
    {
        "from": "collaboratore046",
        "to": "collaboratore186"
    },
    {
        "from": "collaboratore046",
        "to": "collaboratore138"
    },
    {
        "from": "collaboratore046",
        "to": "collaboratore049"
    },
    {
        "from": "collaboratore046",
        "to": "collaboratore214"
    },
    {
        "from": "collaboratore046",
        "to": "collaboratore032"
    },
    {
        "from": "collaboratore046",
        "to": "collaboratore304"
    },
    {
        "from": "collaboratore046",
        "to": "collaboratore311"
    },
    {
        "from": "collaboratore046",
        "to": "collaboratore291"
    },
    {
        "from": "collaboratore046",
        "to": "collaboratore117"
    },
    {
        "from": "collaboratore146",
        "to": "collaboratore158"
    },
    {
        "from": "collaboratore146",
        "to": "collaboratore218"
    },
    {
        "from": "collaboratore146",
        "to": "collaboratore042"
    },
    {
        "from": "collaboratore146",
        "to": "collaboratore263"
    },
    {
        "from": "collaboratore146",
        "to": "collaboratore139"
    },
    {
        "from": "collaboratore146",
        "to": "collaboratore241"
    },
    {
        "from": "collaboratore146",
        "to": "collaboratore221"
    },
    {
        "from": "collaboratore146",
        "to": "collaboratore159"
    },
    {
        "from": "collaboratore146",
        "to": "collaboratore208"
    },
    {
        "from": "collaboratore146",
        "to": "collaboratore122"
    },
    {
        "from": "collaboratore237",
        "to": "collaboratore101"
    },
    {
        "from": "collaboratore237",
        "to": "collaboratore105"
    },
    {
        "from": "collaboratore237",
        "to": "collaboratore063"
    },
    {
        "from": "collaboratore237",
        "to": "collaboratore217"
    },
    {
        "from": "collaboratore237",
        "to": "collaboratore159"
    },
    {
        "from": "collaboratore237",
        "to": "collaboratore108"
    },
    {
        "from": "collaboratore237",
        "to": "collaboratore038"
    },
    {
        "from": "collaboratore231",
        "to": "collaboratore319"
    },
    {
        "from": "collaboratore231",
        "to": "collaboratore315"
    },
    {
        "from": "collaboratore231",
        "to": "collaboratore026"
    },
    {
        "from": "collaboratore231",
        "to": "collaboratore085"
    },
    {
        "from": "collaboratore231",
        "to": "collaboratore027"
    },
    {
        "from": "collaboratore231",
        "to": "collaboratore021"
    },
    {
        "from": "collaboratore231",
        "to": "collaboratore317"
    },
    {
        "from": "collaboratore017",
        "to": "collaboratore293"
    },
    {
        "from": "collaboratore017",
        "to": "collaboratore080"
    },
    {
        "from": "collaboratore017",
        "to": "collaboratore244"
    },
    {
        "from": "collaboratore017",
        "to": "collaboratore047"
    },
    {
        "from": "collaboratore017",
        "to": "collaboratore168"
    },
    {
        "from": "collaboratore017",
        "to": "collaboratore223"
    },
    {
        "from": "collaboratore017",
        "to": "collaboratore185"
    },
    {
        "from": "collaboratore017",
        "to": "collaboratore272"
    },
    {
        "from": "collaboratore017",
        "to": "collaboratore135"
    },
    {
        "from": "collaboratore017",
        "to": "collaboratore141"
    },
    {
        "from": "collaboratore113",
        "to": "collaboratore003"
    },
    {
        "from": "collaboratore113",
        "to": "collaboratore320"
    },
    {
        "from": "collaboratore113",
        "to": "collaboratore306"
    },
    {
        "from": "collaboratore113",
        "to": "collaboratore176"
    },
    {
        "from": "collaboratore113",
        "to": "collaboratore290"
    },
    {
        "from": "collaboratore113",
        "to": "collaboratore001"
    },
    {
        "from": "collaboratore113",
        "to": "collaboratore299"
    },
    {
        "from": "collaboratore113",
        "to": "collaboratore154"
    },
    {
        "from": "collaboratore066",
        "to": "collaboratore015"
    },
    {
        "from": "collaboratore066",
        "to": "collaboratore044"
    },
    {
        "from": "collaboratore066",
        "to": "collaboratore132"
    },
    {
        "from": "collaboratore066",
        "to": "collaboratore144"
    },
    {
        "from": "collaboratore066",
        "to": "collaboratore216"
    },
    {
        "from": "collaboratore066",
        "to": "collaboratore298"
    },
    {
        "from": "collaboratore066",
        "to": "collaboratore028"
    },
    {
        "from": "collaboratore066",
        "to": "collaboratore116"
    },
    {
        "from": "collaboratore018",
        "to": "collaboratore251"
    },
    {
        "from": "collaboratore018",
        "to": "collaboratore074"
    },
    {
        "from": "collaboratore018",
        "to": "collaboratore064"
    },
    {
        "from": "collaboratore018",
        "to": "collaboratore092"
    },
    {
        "from": "collaboratore018",
        "to": "collaboratore190"
    },
    {
        "from": "collaboratore018",
        "to": "collaboratore078"
    },
    {
        "from": "collaboratore018",
        "to": "collaboratore209"
    },
    {
        "from": "collaboratore018",
        "to": "collaboratore304"
    },
    {
        "from": "collaboratore018",
        "to": "collaboratore238"
    },
    {
        "from": "collaboratore018",
        "to": "collaboratore183"
    },
    {
        "from": "collaboratore261",
        "to": "collaboratore126"
    },
    {
        "from": "collaboratore261",
        "to": "collaboratore070"
    },
    {
        "from": "collaboratore261",
        "to": "collaboratore025"
    },
    {
        "from": "collaboratore261",
        "to": "collaboratore114"
    },
    {
        "from": "collaboratore261",
        "to": "collaboratore251"
    },
    {
        "from": "collaboratore261",
        "to": "collaboratore064"
    },
    {
        "from": "collaboratore261",
        "to": "collaboratore190"
    },
    {
        "from": "collaboratore261",
        "to": "collaboratore252"
    },
    {
        "from": "collaboratore261",
        "to": "collaboratore169"
    },
    {
        "from": "collaboratore261",
        "to": "collaboratore314"
    },
    {
        "from": "collaboratore077",
        "to": "collaboratore202"
    },
    {
        "from": "collaboratore077",
        "to": "collaboratore305"
    },
    {
        "from": "collaboratore077",
        "to": "collaboratore162"
    },
    {
        "from": "collaboratore077",
        "to": "collaboratore319"
    },
    {
        "from": "collaboratore077",
        "to": "collaboratore011"
    },
    {
        "from": "collaboratore077",
        "to": "collaboratore242"
    },
    {
        "from": "collaboratore077",
        "to": "collaboratore177"
    },
    {
        "from": "collaboratore077",
        "to": "collaboratore266"
    },
    {
        "from": "collaboratore077",
        "to": "collaboratore194"
    },
    {
        "from": "collaboratore077",
        "to": "collaboratore210"
    },
    {
        "from": "collaboratore128",
        "to": "collaboratore303"
    },
    {
        "from": "collaboratore128",
        "to": "collaboratore195"
    },
    {
        "from": "collaboratore128",
        "to": "collaboratore188"
    },
    {
        "from": "collaboratore128",
        "to": "collaboratore174"
    },
    {
        "from": "collaboratore128",
        "to": "collaboratore316"
    },
    {
        "from": "collaboratore128",
        "to": "collaboratore224"
    },
    {
        "from": "collaboratore128",
        "to": "collaboratore284"
    },
    {
        "from": "collaboratore030",
        "to": "collaboratore158"
    },
    {
        "from": "collaboratore030",
        "to": "collaboratore042"
    },
    {
        "from": "collaboratore030",
        "to": "collaboratore060"
    },
    {
        "from": "collaboratore030",
        "to": "collaboratore172"
    },
    {
        "from": "collaboratore030",
        "to": "collaboratore061"
    },
    {
        "from": "collaboratore030",
        "to": "collaboratore159"
    },
    {
        "from": "collaboratore030",
        "to": "collaboratore312"
    },
    {
        "from": "collaboratore030",
        "to": "collaboratore075"
    },
    {
        "from": "collaboratore030",
        "to": "collaboratore255"
    },
    {
        "from": "collaboratore030",
        "to": "collaboratore178"
    },
    {
        "from": "collaboratore004",
        "to": "collaboratore123"
    },
    {
        "from": "collaboratore004",
        "to": "collaboratore244"
    },
    {
        "from": "collaboratore004",
        "to": "collaboratore306"
    },
    {
        "from": "collaboratore004",
        "to": "collaboratore060"
    },
    {
        "from": "collaboratore004",
        "to": "collaboratore258"
    },
    {
        "from": "collaboratore004",
        "to": "collaboratore196"
    },
    {
        "from": "collaboratore004",
        "to": "collaboratore243"
    },
    {
        "from": "collaboratore004",
        "to": "collaboratore118"
    },
    {
        "from": "collaboratore004",
        "to": "collaboratore001"
    },
    {
        "from": "collaboratore004",
        "to": "collaboratore056"
    },
    {
        "from": "collaboratore059",
        "to": "collaboratore123"
    },
    {
        "from": "collaboratore059",
        "to": "collaboratore166"
    },
    {
        "from": "collaboratore059",
        "to": "collaboratore029"
    },
    {
        "from": "collaboratore059",
        "to": "collaboratore011"
    },
    {
        "from": "collaboratore059",
        "to": "collaboratore095"
    },
    {
        "from": "collaboratore059",
        "to": "collaboratore228"
    },
    {
        "from": "collaboratore059",
        "to": "collaboratore157"
    },
    {
        "from": "collaboratore059",
        "to": "collaboratore076"
    },
    {
        "from": "collaboratore059",
        "to": "collaboratore290"
    },
    {
        "from": "collaboratore059",
        "to": "collaboratore249"
    },
    {
        "from": "collaboratore289",
        "to": "collaboratore003"
    },
    {
        "from": "collaboratore289",
        "to": "collaboratore042"
    },
    {
        "from": "collaboratore289",
        "to": "collaboratore263"
    },
    {
        "from": "collaboratore289",
        "to": "collaboratore060"
    },
    {
        "from": "collaboratore289",
        "to": "collaboratore138"
    },
    {
        "from": "collaboratore289",
        "to": "collaboratore230"
    },
    {
        "from": "collaboratore289",
        "to": "collaboratore325"
    },
    {
        "from": "collaboratore289",
        "to": "collaboratore055"
    },
    {
        "from": "collaboratore289",
        "to": "collaboratore308"
    },
    {
        "from": "collaboratore289",
        "to": "collaboratore312"
    },
    {
        "from": "collaboratore190",
        "to": "collaboratore261"
    },
    {
        "from": "collaboratore190",
        "to": "collaboratore126"
    },
    {
        "from": "collaboratore190",
        "to": "collaboratore025"
    },
    {
        "from": "collaboratore190",
        "to": "collaboratore114"
    },
    {
        "from": "collaboratore190",
        "to": "collaboratore251"
    },
    {
        "from": "collaboratore190",
        "to": "collaboratore008"
    },
    {
        "from": "collaboratore190",
        "to": "collaboratore252"
    },
    {
        "from": "collaboratore190",
        "to": "collaboratore157"
    },
    {
        "from": "collaboratore190",
        "to": "collaboratore314"
    },
    {
        "from": "collaboratore190",
        "to": "collaboratore053"
    },
    {
        "from": "collaboratore121",
        "to": "collaboratore235"
    },
    {
        "from": "collaboratore121",
        "to": "collaboratore133"
    },
    {
        "from": "collaboratore121",
        "to": "collaboratore236"
    },
    {
        "from": "collaboratore121",
        "to": "collaboratore319"
    },
    {
        "from": "collaboratore121",
        "to": "collaboratore109"
    },
    {
        "from": "collaboratore121",
        "to": "collaboratore090"
    },
    {
        "from": "collaboratore121",
        "to": "collaboratore130"
    },
    {
        "from": "collaboratore121",
        "to": "collaboratore242"
    },
    {
        "from": "collaboratore121",
        "to": "collaboratore148"
    },
    {
        "from": "collaboratore121",
        "to": "collaboratore014"
    },
    {
        "from": "collaboratore188",
        "to": "collaboratore303"
    },
    {
        "from": "collaboratore188",
        "to": "collaboratore184"
    },
    {
        "from": "collaboratore188",
        "to": "collaboratore174"
    },
    {
        "from": "collaboratore188",
        "to": "collaboratore203"
    },
    {
        "from": "collaboratore188",
        "to": "collaboratore079"
    },
    {
        "from": "collaboratore188",
        "to": "collaboratore280"
    },
    {
        "from": "collaboratore188",
        "to": "collaboratore224"
    },
    {
        "from": "collaboratore188",
        "to": "collaboratore082"
    },
    {
        "from": "collaboratore188",
        "to": "collaboratore284"
    },
    {
        "from": "collaboratore188",
        "to": "collaboratore128"
    },
    {
        "from": "collaboratore143",
        "to": "collaboratore072"
    },
    {
        "from": "collaboratore143",
        "to": "collaboratore186"
    },
    {
        "from": "collaboratore143",
        "to": "collaboratore007"
    },
    {
        "from": "collaboratore143",
        "to": "collaboratore253"
    },
    {
        "from": "collaboratore143",
        "to": "collaboratore011"
    },
    {
        "from": "collaboratore143",
        "to": "collaboratore242"
    },
    {
        "from": "collaboratore143",
        "to": "collaboratore291"
    },
    {
        "from": "collaboratore228",
        "to": "collaboratore123"
    },
    {
        "from": "collaboratore228",
        "to": "collaboratore139"
    },
    {
        "from": "collaboratore228",
        "to": "collaboratore029"
    },
    {
        "from": "collaboratore228",
        "to": "collaboratore153"
    },
    {
        "from": "collaboratore228",
        "to": "collaboratore095"
    },
    {
        "from": "collaboratore228",
        "to": "collaboratore220"
    },
    {
        "from": "collaboratore228",
        "to": "collaboratore157"
    },
    {
        "from": "collaboratore228",
        "to": "collaboratore076"
    },
    {
        "from": "collaboratore228",
        "to": "collaboratore290"
    },
    {
        "from": "collaboratore228",
        "to": "collaboratore043"
    },
    {
        "from": "collaboratore031",
        "to": "collaboratore094"
    },
    {
        "from": "collaboratore031",
        "to": "collaboratore129"
    },
    {
        "from": "collaboratore031",
        "to": "collaboratore137"
    },
    {
        "from": "collaboratore031",
        "to": "collaboratore041"
    },
    {
        "from": "collaboratore031",
        "to": "collaboratore140"
    },
    {
        "from": "collaboratore031",
        "to": "collaboratore159"
    },
    {
        "from": "collaboratore031",
        "to": "collaboratore287"
    },
    {
        "from": "collaboratore031",
        "to": "collaboratore050"
    },
    {
        "from": "collaboratore031",
        "to": "collaboratore040"
    },
    {
        "from": "collaboratore320",
        "to": "collaboratore105"
    },
    {
        "from": "collaboratore320",
        "to": "collaboratore274"
    },
    {
        "from": "collaboratore320",
        "to": "collaboratore107"
    },
    {
        "from": "collaboratore320",
        "to": "collaboratore079"
    },
    {
        "from": "collaboratore320",
        "to": "collaboratore241"
    },
    {
        "from": "collaboratore320",
        "to": "collaboratore301"
    },
    {
        "from": "collaboratore320",
        "to": "collaboratore110"
    },
    {
        "from": "collaboratore320",
        "to": "collaboratore108"
    },
    {
        "from": "collaboratore320",
        "to": "collaboratore287"
    },
    {
        "from": "collaboratore320",
        "to": "collaboratore194"
    },
    {
        "from": "collaboratore093",
        "to": "collaboratore254"
    },
    {
        "from": "collaboratore093",
        "to": "collaboratore102"
    },
    {
        "from": "collaboratore093",
        "to": "collaboratore264"
    },
    {
        "from": "collaboratore093",
        "to": "collaboratore193"
    },
    {
        "from": "collaboratore093",
        "to": "collaboratore027"
    },
    {
        "from": "collaboratore093",
        "to": "collaboratore056"
    },
    {
        "from": "collaboratore122",
        "to": "collaboratore263"
    },
    {
        "from": "collaboratore122",
        "to": "collaboratore161"
    },
    {
        "from": "collaboratore122",
        "to": "collaboratore026"
    },
    {
        "from": "collaboratore122",
        "to": "collaboratore134"
    },
    {
        "from": "collaboratore122",
        "to": "collaboratore185"
    },
    {
        "from": "collaboratore122",
        "to": "collaboratore221"
    },
    {
        "from": "collaboratore122",
        "to": "collaboratore242"
    },
    {
        "from": "collaboratore122",
        "to": "collaboratore180"
    },
    {
        "from": "collaboratore122",
        "to": "collaboratore247"
    },
    {
        "from": "collaboratore122",
        "to": "collaboratore022"
    },
    {
        "from": "collaboratore062",
        "to": "collaboratore005"
    },
    {
        "from": "collaboratore062",
        "to": "collaboratore136"
    },
    {
        "from": "collaboratore062",
        "to": "collaboratore326"
    },
    {
        "from": "collaboratore062",
        "to": "collaboratore088"
    },
    {
        "from": "collaboratore062",
        "to": "collaboratore107"
    },
    {
        "from": "collaboratore062",
        "to": "collaboratore132"
    },
    {
        "from": "collaboratore062",
        "to": "collaboratore200"
    },
    {
        "from": "collaboratore062",
        "to": "collaboratore297"
    },
    {
        "from": "collaboratore062",
        "to": "collaboratore055"
    },
    {
        "from": "collaboratore062",
        "to": "collaboratore286"
    },
    {
        "from": "collaboratore306",
        "to": "collaboratore003"
    },
    {
        "from": "collaboratore306",
        "to": "collaboratore244"
    },
    {
        "from": "collaboratore306",
        "to": "collaboratore004"
    },
    {
        "from": "collaboratore306",
        "to": "collaboratore113"
    },
    {
        "from": "collaboratore306",
        "to": "collaboratore196"
    },
    {
        "from": "collaboratore306",
        "to": "collaboratore243"
    },
    {
        "from": "collaboratore306",
        "to": "collaboratore110"
    },
    {
        "from": "collaboratore306",
        "to": "collaboratore001"
    },
    {
        "from": "collaboratore306",
        "to": "collaboratore154"
    },
    {
        "from": "collaboratore108",
        "to": "collaboratore320"
    },
    {
        "from": "collaboratore108",
        "to": "collaboratore105"
    },
    {
        "from": "collaboratore108",
        "to": "collaboratore010"
    },
    {
        "from": "collaboratore108",
        "to": "collaboratore166"
    },
    {
        "from": "collaboratore108",
        "to": "collaboratore058"
    },
    {
        "from": "collaboratore108",
        "to": "collaboratore110"
    },
    {
        "from": "collaboratore108",
        "to": "collaboratore287"
    },
    {
        "from": "collaboratore108",
        "to": "collaboratore227"
    },
    {
        "from": "collaboratore108",
        "to": "collaboratore275"
    },
    {
        "from": "collaboratore108",
        "to": "collaboratore296"
    },
    {
        "from": "collaboratore251",
        "to": "collaboratore261"
    },
    {
        "from": "collaboratore251",
        "to": "collaboratore070"
    },
    {
        "from": "collaboratore251",
        "to": "collaboratore114"
    },
    {
        "from": "collaboratore251",
        "to": "collaboratore201"
    },
    {
        "from": "collaboratore251",
        "to": "collaboratore190"
    },
    {
        "from": "collaboratore251",
        "to": "collaboratore090"
    },
    {
        "from": "collaboratore251",
        "to": "collaboratore252"
    },
    {
        "from": "collaboratore251",
        "to": "collaboratore238"
    },
    {
        "from": "collaboratore251",
        "to": "collaboratore157"
    },
    {
        "from": "collaboratore251",
        "to": "collaboratore314"
    },
    {
        "from": "collaboratore144",
        "to": "collaboratore044"
    },
    {
        "from": "collaboratore144",
        "to": "collaboratore132"
    },
    {
        "from": "collaboratore144",
        "to": "collaboratore262"
    },
    {
        "from": "collaboratore144",
        "to": "collaboratore216"
    },
    {
        "from": "collaboratore144",
        "to": "collaboratore298"
    },
    {
        "from": "collaboratore144",
        "to": "collaboratore011"
    },
    {
        "from": "collaboratore144",
        "to": "collaboratore066"
    },
    {
        "from": "collaboratore144",
        "to": "collaboratore028"
    },
    {
        "from": "collaboratore144",
        "to": "collaboratore307"
    },
    {
        "from": "collaboratore144",
        "to": "collaboratore116"
    },
    {
        "from": "collaboratore242",
        "to": "collaboratore202"
    },
    {
        "from": "collaboratore242",
        "to": "collaboratore186"
    },
    {
        "from": "collaboratore242",
        "to": "collaboratore319"
    },
    {
        "from": "collaboratore242",
        "to": "collaboratore315"
    },
    {
        "from": "collaboratore242",
        "to": "collaboratore026"
    },
    {
        "from": "collaboratore242",
        "to": "collaboratore189"
    },
    {
        "from": "collaboratore242",
        "to": "collaboratore069"
    },
    {
        "from": "collaboratore242",
        "to": "collaboratore177"
    },
    {
        "from": "collaboratore242",
        "to": "collaboratore118"
    },
    {
        "from": "collaboratore242",
        "to": "collaboratore122"
    },
    {
        "from": "collaboratore096",
        "to": "collaboratore173"
    },
    {
        "from": "collaboratore096",
        "to": "collaboratore074"
    },
    {
        "from": "collaboratore096",
        "to": "collaboratore302"
    },
    {
        "from": "collaboratore096",
        "to": "collaboratore132"
    },
    {
        "from": "collaboratore096",
        "to": "collaboratore035"
    },
    {
        "from": "collaboratore096",
        "to": "collaboratore204"
    },
    {
        "from": "collaboratore096",
        "to": "collaboratore018"
    },
    {
        "from": "collaboratore096",
        "to": "collaboratore090"
    },
    {
        "from": "collaboratore096",
        "to": "collaboratore167"
    },
    {
        "from": "collaboratore096",
        "to": "collaboratore183"
    },
    {
        "from": "collaboratore244",
        "to": "collaboratore080"
    },
    {
        "from": "collaboratore244",
        "to": "collaboratore004"
    },
    {
        "from": "collaboratore244",
        "to": "collaboratore306"
    },
    {
        "from": "collaboratore244",
        "to": "collaboratore263"
    },
    {
        "from": "collaboratore244",
        "to": "collaboratore223"
    },
    {
        "from": "collaboratore244",
        "to": "collaboratore023"
    },
    {
        "from": "collaboratore244",
        "to": "collaboratore017"
    },
    {
        "from": "collaboratore244",
        "to": "collaboratore135"
    },
    {
        "from": "collaboratore244",
        "to": "collaboratore141"
    },
    {
        "from": "collaboratore184",
        "to": "collaboratore131"
    },
    {
        "from": "collaboratore184",
        "to": "collaboratore192"
    },
    {
        "from": "collaboratore184",
        "to": "collaboratore051"
    },
    {
        "from": "collaboratore184",
        "to": "collaboratore179"
    },
    {
        "from": "collaboratore184",
        "to": "collaboratore019"
    },
    {
        "from": "collaboratore184",
        "to": "collaboratore280"
    },
    {
        "from": "collaboratore184",
        "to": "collaboratore147"
    },
    {
        "from": "collaboratore184",
        "to": "collaboratore313"
    },
    {
        "from": "collaboratore184",
        "to": "collaboratore219"
    },
    {
        "from": "collaboratore184",
        "to": "collaboratore135"
    },
    {
        "from": "collaboratore309",
        "to": "collaboratore133"
    },
    {
        "from": "collaboratore309",
        "to": "collaboratore248"
    },
    {
        "from": "collaboratore309",
        "to": "collaboratore305"
    },
    {
        "from": "collaboratore309",
        "to": "collaboratore186"
    },
    {
        "from": "collaboratore309",
        "to": "collaboratore092"
    },
    {
        "from": "collaboratore309",
        "to": "collaboratore214"
    },
    {
        "from": "collaboratore309",
        "to": "collaboratore032"
    },
    {
        "from": "collaboratore309",
        "to": "collaboratore291"
    },
    {
        "from": "collaboratore309",
        "to": "collaboratore117"
    },
    {
        "from": "collaboratore309",
        "to": "collaboratore081"
    },
    {
        "from": "collaboratore057",
        "to": "collaboratore305"
    },
    {
        "from": "collaboratore057",
        "to": "collaboratore093"
    },
    {
        "from": "collaboratore057",
        "to": "collaboratore024"
    },
    {
        "from": "collaboratore057",
        "to": "collaboratore049"
    },
    {
        "from": "collaboratore057",
        "to": "collaboratore254"
    },
    {
        "from": "collaboratore057",
        "to": "collaboratore102"
    },
    {
        "from": "collaboratore057",
        "to": "collaboratore193"
    },
    {
        "from": "collaboratore057",
        "to": "collaboratore152"
    },
    {
        "from": "collaboratore057",
        "to": "collaboratore056"
    },
    {
        "from": "collaboratore109",
        "to": "collaboratore235"
    },
    {
        "from": "collaboratore109",
        "to": "collaboratore133"
    },
    {
        "from": "collaboratore109",
        "to": "collaboratore236"
    },
    {
        "from": "collaboratore109",
        "to": "collaboratore148"
    },
    {
        "from": "collaboratore109",
        "to": "collaboratore121"
    },
    {
        "from": "collaboratore109",
        "to": "collaboratore014"
    },
    {
        "from": "collaboratore255",
        "to": "collaboratore112"
    },
    {
        "from": "collaboratore255",
        "to": "collaboratore012"
    },
    {
        "from": "collaboratore255",
        "to": "collaboratore159"
    },
    {
        "from": "collaboratore255",
        "to": "collaboratore312"
    },
    {
        "from": "collaboratore255",
        "to": "collaboratore075"
    },
    {
        "from": "collaboratore255",
        "to": "collaboratore227"
    },
    {
        "from": "collaboratore255",
        "to": "collaboratore001"
    },
    {
        "from": "collaboratore012",
        "to": "collaboratore042"
    },
    {
        "from": "collaboratore012",
        "to": "collaboratore100"
    },
    {
        "from": "collaboratore012",
        "to": "collaboratore159"
    },
    {
        "from": "collaboratore012",
        "to": "collaboratore075"
    },
    {
        "from": "collaboratore012",
        "to": "collaboratore255"
    },
    {
        "from": "collaboratore012",
        "to": "collaboratore001"
    },
    {
        "from": "collaboratore295",
        "to": "collaboratore142"
    },
    {
        "from": "collaboratore295",
        "to": "collaboratore013"
    },
    {
        "from": "collaboratore295",
        "to": "collaboratore136"
    },
    {
        "from": "collaboratore295",
        "to": "collaboratore200"
    },
    {
        "from": "collaboratore295",
        "to": "collaboratore268"
    },
    {
        "from": "collaboratore295",
        "to": "collaboratore297"
    },
    {
        "from": "collaboratore295",
        "to": "collaboratore155"
    },
    {
        "from": "collaboratore295",
        "to": "collaboratore247"
    },
    {
        "from": "collaboratore021",
        "to": "collaboratore318"
    },
    {
        "from": "collaboratore021",
        "to": "collaboratore315"
    },
    {
        "from": "collaboratore021",
        "to": "collaboratore282"
    },
    {
        "from": "collaboratore021",
        "to": "collaboratore205"
    },
    {
        "from": "collaboratore021",
        "to": "collaboratore317"
    },
    {
        "from": "collaboratore021",
        "to": "collaboratore231"
    },
    {
        "from": "collaboratore139",
        "to": "collaboratore158"
    },
    {
        "from": "collaboratore139",
        "to": "collaboratore218"
    },
    {
        "from": "collaboratore139",
        "to": "collaboratore006"
    },
    {
        "from": "collaboratore139",
        "to": "collaboratore010"
    },
    {
        "from": "collaboratore139",
        "to": "collaboratore029"
    },
    {
        "from": "collaboratore139",
        "to": "collaboratore058"
    },
    {
        "from": "collaboratore139",
        "to": "collaboratore228"
    },
    {
        "from": "collaboratore139",
        "to": "collaboratore290"
    },
    {
        "from": "collaboratore139",
        "to": "collaboratore275"
    },
    {
        "from": "collaboratore139",
        "to": "collaboratore146"
    },
    {
        "from": "collaboratore287",
        "to": "collaboratore320"
    },
    {
        "from": "collaboratore287",
        "to": "collaboratore105"
    },
    {
        "from": "collaboratore287",
        "to": "collaboratore010"
    },
    {
        "from": "collaboratore287",
        "to": "collaboratore063"
    },
    {
        "from": "collaboratore287",
        "to": "collaboratore203"
    },
    {
        "from": "collaboratore287",
        "to": "collaboratore176"
    },
    {
        "from": "collaboratore287",
        "to": "collaboratore301"
    },
    {
        "from": "collaboratore287",
        "to": "collaboratore159"
    },
    {
        "from": "collaboratore287",
        "to": "collaboratore031"
    },
    {
        "from": "collaboratore287",
        "to": "collaboratore197"
    },
    {
        "from": "collaboratore178",
        "to": "collaboratore158"
    },
    {
        "from": "collaboratore178",
        "to": "collaboratore030"
    },
    {
        "from": "collaboratore178",
        "to": "collaboratore172"
    },
    {
        "from": "collaboratore178",
        "to": "collaboratore061"
    },
    {
        "from": "collaboratore178",
        "to": "collaboratore159"
    },
    {
        "from": "collaboratore178",
        "to": "collaboratore075"
    },
    {
        "from": "collaboratore087",
        "to": "collaboratore191"
    },
    {
        "from": "collaboratore087",
        "to": "collaboratore214"
    },
    {
        "from": "collaboratore087",
        "to": "collaboratore115"
    },
    {
        "from": "collaboratore087",
        "to": "collaboratore209"
    },
    {
        "from": "collaboratore087",
        "to": "collaboratore304"
    },
    {
        "from": "collaboratore087",
        "to": "collaboratore238"
    },
    {
        "from": "collaboratore087",
        "to": "collaboratore071"
    },
    {
        "from": "collaboratore087",
        "to": "collaboratore226"
    },
    {
        "from": "collaboratore087",
        "to": "collaboratore117"
    },
    {
        "from": "collaboratore087",
        "to": "collaboratore222"
    },
    {
        "from": "collaboratore186",
        "to": "collaboratore072"
    },
    {
        "from": "collaboratore186",
        "to": "collaboratore007"
    },
    {
        "from": "collaboratore186",
        "to": "collaboratore046"
    },
    {
        "from": "collaboratore186",
        "to": "collaboratore143"
    },
    {
        "from": "collaboratore186",
        "to": "collaboratore246"
    },
    {
        "from": "collaboratore186",
        "to": "collaboratore225"
    },
    {
        "from": "collaboratore186",
        "to": "collaboratore242"
    },
    {
        "from": "collaboratore186",
        "to": "collaboratore291"
    },
    {
        "from": "collaboratore186",
        "to": "collaboratore194"
    },
    {
        "from": "collaboratore186",
        "to": "collaboratore027"
    },
    {
        "from": "collaboratore102",
        "to": "collaboratore057"
    },
    {
        "from": "collaboratore102",
        "to": "collaboratore093"
    },
    {
        "from": "collaboratore102",
        "to": "collaboratore319"
    },
    {
        "from": "collaboratore102",
        "to": "collaboratore024"
    },
    {
        "from": "collaboratore102",
        "to": "collaboratore073"
    },
    {
        "from": "collaboratore102",
        "to": "collaboratore049"
    },
    {
        "from": "collaboratore102",
        "to": "collaboratore130"
    },
    {
        "from": "collaboratore102",
        "to": "collaboratore170"
    },
    {
        "from": "collaboratore102",
        "to": "collaboratore152"
    },
    {
        "from": "collaboratore102",
        "to": "collaboratore065"
    },
    {
        "from": "collaboratore179",
        "to": "collaboratore094"
    },
    {
        "from": "collaboratore179",
        "to": "collaboratore127"
    },
    {
        "from": "collaboratore179",
        "to": "collaboratore131"
    },
    {
        "from": "collaboratore179",
        "to": "collaboratore263"
    },
    {
        "from": "collaboratore179",
        "to": "collaboratore051"
    },
    {
        "from": "collaboratore179",
        "to": "collaboratore145"
    },
    {
        "from": "collaboratore179",
        "to": "collaboratore280"
    },
    {
        "from": "collaboratore179",
        "to": "collaboratore008"
    },
    {
        "from": "collaboratore179",
        "to": "collaboratore313"
    },
    {
        "from": "collaboratore179",
        "to": "collaboratore082"
    },
    {
        "from": "collaboratore047",
        "to": "collaboratore016"
    },
    {
        "from": "collaboratore047",
        "to": "collaboratore168"
    },
    {
        "from": "collaboratore047",
        "to": "collaboratore223"
    },
    {
        "from": "collaboratore047",
        "to": "collaboratore272"
    },
    {
        "from": "collaboratore047",
        "to": "collaboratore135"
    },
    {
        "from": "collaboratore047",
        "to": "collaboratore317"
    },
    {
        "from": "collaboratore002",
        "to": "collaboratore271"
    },
    {
        "from": "collaboratore002",
        "to": "collaboratore161"
    },
    {
        "from": "collaboratore002",
        "to": "collaboratore283"
    },
    {
        "from": "collaboratore002",
        "to": "collaboratore151"
    },
    {
        "from": "collaboratore002",
        "to": "collaboratore185"
    },
    {
        "from": "collaboratore002",
        "to": "collaboratore323"
    },
    {
        "from": "collaboratore002",
        "to": "collaboratore023"
    },
    {
        "from": "collaboratore002",
        "to": "collaboratore083"
    },
    {
        "from": "collaboratore002",
        "to": "collaboratore116"
    },
    {
        "from": "collaboratore002",
        "to": "collaboratore084"
    },
    {
        "from": "collaboratore032",
        "to": "collaboratore305"
    },
    {
        "from": "collaboratore032",
        "to": "collaboratore107"
    },
    {
        "from": "collaboratore032",
        "to": "collaboratore046"
    },
    {
        "from": "collaboratore032",
        "to": "collaboratore198"
    },
    {
        "from": "collaboratore032",
        "to": "collaboratore009"
    },
    {
        "from": "collaboratore032",
        "to": "collaboratore225"
    },
    {
        "from": "collaboratore032",
        "to": "collaboratore311"
    },
    {
        "from": "collaboratore032",
        "to": "collaboratore309"
    },
    {
        "from": "collaboratore032",
        "to": "collaboratore291"
    },
    {
        "from": "collaboratore032",
        "to": "collaboratore208"
    },
    {
        "from": "collaboratore225",
        "to": "collaboratore319"
    },
    {
        "from": "collaboratore225",
        "to": "collaboratore321"
    },
    {
        "from": "collaboratore225",
        "to": "collaboratore315"
    },
    {
        "from": "collaboratore225",
        "to": "collaboratore192"
    },
    {
        "from": "collaboratore225",
        "to": "collaboratore026"
    },
    {
        "from": "collaboratore225",
        "to": "collaboratore130"
    },
    {
        "from": "collaboratore225",
        "to": "collaboratore102"
    },
    {
        "from": "collaboratore225",
        "to": "collaboratore242"
    },
    {
        "from": "collaboratore225",
        "to": "collaboratore291"
    },
    {
        "from": "collaboratore225",
        "to": "collaboratore027"
    },
    {
        "from": "collaboratore089",
        "to": "collaboratore080"
    },
    {
        "from": "collaboratore089",
        "to": "collaboratore162"
    },
    {
        "from": "collaboratore089",
        "to": "collaboratore324"
    },
    {
        "from": "collaboratore089",
        "to": "collaboratore144"
    },
    {
        "from": "collaboratore089",
        "to": "collaboratore066"
    },
    {
        "from": "collaboratore089",
        "to": "collaboratore257"
    },
    {
        "from": "collaboratore089",
        "to": "collaboratore187"
    },
    {
        "from": "collaboratore089",
        "to": "collaboratore075"
    },
    {
        "from": "collaboratore089",
        "to": "collaboratore154"
    },
    {
        "from": "collaboratore148",
        "to": "collaboratore133"
    },
    {
        "from": "collaboratore148",
        "to": "collaboratore236"
    },
    {
        "from": "collaboratore148",
        "to": "collaboratore319"
    },
    {
        "from": "collaboratore148",
        "to": "collaboratore198"
    },
    {
        "from": "collaboratore148",
        "to": "collaboratore032"
    },
    {
        "from": "collaboratore148",
        "to": "collaboratore121"
    },
    {
        "from": "collaboratore148",
        "to": "collaboratore014"
    },
    {
        "from": "collaboratore296",
        "to": "collaboratore320"
    },
    {
        "from": "collaboratore296",
        "to": "collaboratore239"
    },
    {
        "from": "collaboratore296",
        "to": "collaboratore105"
    },
    {
        "from": "collaboratore296",
        "to": "collaboratore095"
    },
    {
        "from": "collaboratore296",
        "to": "collaboratore108"
    },
    {
        "from": "collaboratore296",
        "to": "collaboratore287"
    },
    {
        "from": "collaboratore135",
        "to": "collaboratore168"
    },
    {
        "from": "collaboratore135",
        "to": "collaboratore223"
    },
    {
        "from": "collaboratore135",
        "to": "collaboratore216"
    },
    {
        "from": "collaboratore135",
        "to": "collaboratore298"
    },
    {
        "from": "collaboratore135",
        "to": "collaboratore100"
    },
    {
        "from": "collaboratore135",
        "to": "collaboratore272"
    },
    {
        "from": "collaboratore135",
        "to": "collaboratore068"
    },
    {
        "from": "collaboratore135",
        "to": "collaboratore017"
    },
    {
        "from": "collaboratore135",
        "to": "collaboratore317"
    },
    {
        "from": "collaboratore135",
        "to": "collaboratore022"
    },
    {
        "from": "collaboratore056",
        "to": "collaboratore057"
    },
    {
        "from": "collaboratore056",
        "to": "collaboratore305"
    },
    {
        "from": "collaboratore056",
        "to": "collaboratore093"
    },
    {
        "from": "collaboratore056",
        "to": "collaboratore024"
    },
    {
        "from": "collaboratore056",
        "to": "collaboratore268"
    },
    {
        "from": "collaboratore056",
        "to": "collaboratore049"
    },
    {
        "from": "collaboratore056",
        "to": "collaboratore323"
    },
    {
        "from": "collaboratore056",
        "to": "collaboratore102"
    },
    {
        "from": "collaboratore056",
        "to": "collaboratore247"
    },
    {
        "from": "collaboratore056",
        "to": "collaboratore194"
    },
    {
        "from": "collaboratore037",
        "to": "collaboratore327"
    },
    {
        "from": "collaboratore037",
        "to": "collaboratore258"
    },
    {
        "from": "collaboratore037",
        "to": "collaboratore049"
    },
    {
        "from": "collaboratore037",
        "to": "collaboratore029"
    },
    {
        "from": "collaboratore037",
        "to": "collaboratore228"
    },
    {
        "from": "collaboratore037",
        "to": "collaboratore165"
    },
    {
        "from": "collaboratore037",
        "to": "collaboratore157"
    },
    {
        "from": "collaboratore037",
        "to": "collaboratore290"
    },
    {
        "from": "collaboratore037",
        "to": "collaboratore027"
    },
    {
        "from": "collaboratore037",
        "to": "collaboratore296"
    },
    {
        "from": "collaboratore019",
        "to": "collaboratore184"
    },
    {
        "from": "collaboratore019",
        "to": "collaboratore131"
    },
    {
        "from": "collaboratore019",
        "to": "collaboratore212"
    },
    {
        "from": "collaboratore019",
        "to": "collaboratore051"
    },
    {
        "from": "collaboratore019",
        "to": "collaboratore179"
    },
    {
        "from": "collaboratore019",
        "to": "collaboratore280"
    },
    {
        "from": "collaboratore019",
        "to": "collaboratore147"
    },
    {
        "from": "collaboratore019",
        "to": "collaboratore313"
    },
    {
        "from": "collaboratore019",
        "to": "collaboratore082"
    },
    {
        "from": "collaboratore019",
        "to": "collaboratore219"
    },
    {
        "from": "collaboratore065",
        "to": "collaboratore158"
    },
    {
        "from": "collaboratore065",
        "to": "collaboratore047"
    },
    {
        "from": "collaboratore065",
        "to": "collaboratore049"
    },
    {
        "from": "collaboratore065",
        "to": "collaboratore102"
    },
    {
        "from": "collaboratore065",
        "to": "collaboratore159"
    },
    {
        "from": "collaboratore065",
        "to": "collaboratore187"
    },
    {
        "from": "collaboratore065",
        "to": "collaboratore227"
    },
    {
        "from": "collaboratore065",
        "to": "collaboratore194"
    },
    {
        "from": "collaboratore065",
        "to": "collaboratore152"
    },
    {
        "from": "collaboratore065",
        "to": "collaboratore027"
    },
    {
        "from": "collaboratore149",
        "to": "collaboratore158"
    },
    {
        "from": "collaboratore149",
        "to": "collaboratore270"
    },
    {
        "from": "collaboratore149",
        "to": "collaboratore203"
    },
    {
        "from": "collaboratore149",
        "to": "collaboratore134"
    },
    {
        "from": "collaboratore149",
        "to": "collaboratore243"
    },
    {
        "from": "collaboratore149",
        "to": "collaboratore301"
    },
    {
        "from": "collaboratore149",
        "to": "collaboratore116"
    },
    {
        "from": "collaboratore171",
        "to": "collaboratore326"
    },
    {
        "from": "collaboratore171",
        "to": "collaboratore088"
    },
    {
        "from": "collaboratore171",
        "to": "collaboratore145"
    },
    {
        "from": "collaboratore171",
        "to": "collaboratore297"
    },
    {
        "from": "collaboratore171",
        "to": "collaboratore026"
    },
    {
        "from": "collaboratore171",
        "to": "collaboratore140"
    },
    {
        "from": "collaboratore171",
        "to": "collaboratore008"
    },
    {
        "from": "collaboratore171",
        "to": "collaboratore055"
    },
    {
        "from": "collaboratore171",
        "to": "collaboratore286"
    },
    {
        "from": "collaboratore171",
        "to": "collaboratore068"
    },
    {
        "from": "collaboratore094",
        "to": "collaboratore129"
    },
    {
        "from": "collaboratore094",
        "to": "collaboratore013"
    },
    {
        "from": "collaboratore094",
        "to": "collaboratore240"
    },
    {
        "from": "collaboratore094",
        "to": "collaboratore041"
    },
    {
        "from": "collaboratore094",
        "to": "collaboratore140"
    },
    {
        "from": "collaboratore094",
        "to": "collaboratore156"
    },
    {
        "from": "collaboratore094",
        "to": "collaboratore031"
    },
    {
        "from": "collaboratore094",
        "to": "collaboratore050"
    },
    {
        "from": "collaboratore094",
        "to": "collaboratore040"
    },
    {
        "from": "collaboratore319",
        "to": "collaboratore315"
    },
    {
        "from": "collaboratore319",
        "to": "collaboratore192"
    },
    {
        "from": "collaboratore319",
        "to": "collaboratore026"
    },
    {
        "from": "collaboratore319",
        "to": "collaboratore011"
    },
    {
        "from": "collaboratore319",
        "to": "collaboratore130"
    },
    {
        "from": "collaboratore319",
        "to": "collaboratore102"
    },
    {
        "from": "collaboratore319",
        "to": "collaboratore242"
    },
    {
        "from": "collaboratore319",
        "to": "collaboratore291"
    },
    {
        "from": "collaboratore319",
        "to": "collaboratore014"
    },
    {
        "from": "collaboratore319",
        "to": "collaboratore027"
    },
    {
        "from": "collaboratore226",
        "to": "collaboratore214"
    },
    {
        "from": "collaboratore226",
        "to": "collaboratore115"
    },
    {
        "from": "collaboratore226",
        "to": "collaboratore241"
    },
    {
        "from": "collaboratore226",
        "to": "collaboratore304"
    },
    {
        "from": "collaboratore226",
        "to": "collaboratore095"
    },
    {
        "from": "collaboratore226",
        "to": "collaboratore238"
    },
    {
        "from": "collaboratore226",
        "to": "collaboratore247"
    },
    {
        "from": "collaboratore226",
        "to": "collaboratore157"
    },
    {
        "from": "collaboratore226",
        "to": "collaboratore117"
    },
    {
        "from": "collaboratore226",
        "to": "collaboratore087"
    },
    {
        "from": "collaboratore207",
        "to": "collaboratore126"
    },
    {
        "from": "collaboratore207",
        "to": "collaboratore070"
    },
    {
        "from": "collaboratore207",
        "to": "collaboratore005"
    },
    {
        "from": "collaboratore207",
        "to": "collaboratore234"
    },
    {
        "from": "collaboratore207",
        "to": "collaboratore088"
    },
    {
        "from": "collaboratore207",
        "to": "collaboratore200"
    },
    {
        "from": "collaboratore207",
        "to": "collaboratore145"
    },
    {
        "from": "collaboratore207",
        "to": "collaboratore297"
    },
    {
        "from": "collaboratore207",
        "to": "collaboratore286"
    },
    {
        "from": "collaboratore207",
        "to": "collaboratore034"
    },
    {
        "from": "collaboratore299",
        "to": "collaboratore326"
    },
    {
        "from": "collaboratore299",
        "to": "collaboratore088"
    },
    {
        "from": "collaboratore299",
        "to": "collaboratore055"
    },
    {
        "from": "collaboratore299",
        "to": "collaboratore156"
    },
    {
        "from": "collaboratore299",
        "to": "collaboratore068"
    },
    {
        "from": "collaboratore299",
        "to": "collaboratore104"
    },
    {
        "from": "collaboratore022",
        "to": "collaboratore162"
    },
    {
        "from": "collaboratore022",
        "to": "collaboratore134"
    },
    {
        "from": "collaboratore022",
        "to": "collaboratore298"
    },
    {
        "from": "collaboratore022",
        "to": "collaboratore185"
    },
    {
        "from": "collaboratore022",
        "to": "collaboratore241"
    },
    {
        "from": "collaboratore022",
        "to": "collaboratore159"
    },
    {
        "from": "collaboratore022",
        "to": "collaboratore187"
    },
    {
        "from": "collaboratore022",
        "to": "collaboratore276"
    },
    {
        "from": "collaboratore022",
        "to": "collaboratore233"
    },
    {
        "from": "collaboratore022",
        "to": "collaboratore122"
    },
    {
        "from": "collaboratore125",
        "to": "collaboratore326"
    },
    {
        "from": "collaboratore125",
        "to": "collaboratore088"
    },
    {
        "from": "collaboratore125",
        "to": "collaboratore055"
    },
    {
        "from": "collaboratore125",
        "to": "collaboratore068"
    },
    {
        "from": "collaboratore125",
        "to": "collaboratore299"
    },
    {
        "from": "collaboratore125",
        "to": "collaboratore104"
    },
    {
        "from": "collaboratore254",
        "to": "collaboratore057"
    },
    {
        "from": "collaboratore254",
        "to": "collaboratore093"
    },
    {
        "from": "collaboratore254",
        "to": "collaboratore024"
    },
    {
        "from": "collaboratore254",
        "to": "collaboratore073"
    },
    {
        "from": "collaboratore254",
        "to": "collaboratore102"
    },
    {
        "from": "collaboratore254",
        "to": "collaboratore264"
    },
    {
        "from": "collaboratore254",
        "to": "collaboratore170"
    },
    {
        "from": "collaboratore254",
        "to": "collaboratore193"
    },
    {
        "from": "collaboratore254",
        "to": "collaboratore152"
    },
    {
        "from": "collaboratore254",
        "to": "collaboratore056"
    },
    {
        "from": "collaboratore158",
        "to": "collaboratore218"
    },
    {
        "from": "collaboratore158",
        "to": "collaboratore042"
    },
    {
        "from": "collaboratore158",
        "to": "collaboratore172"
    },
    {
        "from": "collaboratore158",
        "to": "collaboratore139"
    },
    {
        "from": "collaboratore158",
        "to": "collaboratore196"
    },
    {
        "from": "collaboratore158",
        "to": "collaboratore243"
    },
    {
        "from": "collaboratore158",
        "to": "collaboratore159"
    },
    {
        "from": "collaboratore158",
        "to": "collaboratore116"
    },
    {
        "from": "collaboratore158",
        "to": "collaboratore178"
    },
    {
        "from": "collaboratore158",
        "to": "collaboratore146"
    },
    {
        "from": "collaboratore172",
        "to": "collaboratore158"
    },
    {
        "from": "collaboratore172",
        "to": "collaboratore042"
    },
    {
        "from": "collaboratore172",
        "to": "collaboratore030"
    },
    {
        "from": "collaboratore172",
        "to": "collaboratore090"
    },
    {
        "from": "collaboratore172",
        "to": "collaboratore061"
    },
    {
        "from": "collaboratore172",
        "to": "collaboratore159"
    },
    {
        "from": "collaboratore172",
        "to": "collaboratore311"
    },
    {
        "from": "collaboratore172",
        "to": "collaboratore075"
    },
    {
        "from": "collaboratore172",
        "to": "collaboratore255"
    },
    {
        "from": "collaboratore172",
        "to": "collaboratore178"
    },
    {
        "from": "collaboratore103",
        "to": "collaboratore229"
    },
    {
        "from": "collaboratore103",
        "to": "collaboratore200"
    },
    {
        "from": "collaboratore103",
        "to": "collaboratore268"
    },
    {
        "from": "collaboratore103",
        "to": "collaboratore297"
    },
    {
        "from": "collaboratore103",
        "to": "collaboratore223"
    },
    {
        "from": "collaboratore103",
        "to": "collaboratore276"
    },
    {
        "from": "collaboratore075",
        "to": "collaboratore042"
    },
    {
        "from": "collaboratore075",
        "to": "collaboratore213"
    },
    {
        "from": "collaboratore075",
        "to": "collaboratore060"
    },
    {
        "from": "collaboratore075",
        "to": "collaboratore172"
    },
    {
        "from": "collaboratore075",
        "to": "collaboratore112"
    },
    {
        "from": "collaboratore075",
        "to": "collaboratore012"
    },
    {
        "from": "collaboratore075",
        "to": "collaboratore159"
    },
    {
        "from": "collaboratore075",
        "to": "collaboratore255"
    },
    {
        "from": "collaboratore075",
        "to": "collaboratore001"
    },
    {
        "from": "collaboratore075",
        "to": "collaboratore178"
    },
    {
        "from": "collaboratore054",
        "to": "collaboratore003"
    },
    {
        "from": "collaboratore054",
        "to": "collaboratore004"
    },
    {
        "from": "collaboratore054",
        "to": "collaboratore060"
    },
    {
        "from": "collaboratore054",
        "to": "collaboratore113"
    },
    {
        "from": "collaboratore054",
        "to": "collaboratore090"
    },
    {
        "from": "collaboratore054",
        "to": "collaboratore196"
    },
    {
        "from": "collaboratore054",
        "to": "collaboratore243"
    },
    {
        "from": "collaboratore054",
        "to": "collaboratore110"
    },
    {
        "from": "collaboratore054",
        "to": "collaboratore278"
    },
    {
        "from": "collaboratore054",
        "to": "collaboratore106"
    },
    {
        "from": "collaboratore201",
        "to": "collaboratore107"
    },
    {
        "from": "collaboratore201",
        "to": "collaboratore090"
    },
    {
        "from": "collaboratore201",
        "to": "collaboratore298"
    },
    {
        "from": "collaboratore201",
        "to": "collaboratore308"
    },
    {
        "from": "collaboratore201",
        "to": "collaboratore256"
    },
    {
        "from": "collaboratore201",
        "to": "collaboratore307"
    },
    {
        "from": "collaboratore201",
        "to": "collaboratore233"
    },
    {
        "from": "collaboratore201",
        "to": "collaboratore081"
    },
    {
        "from": "collaboratore069",
        "to": "collaboratore186"
    },
    {
        "from": "collaboratore069",
        "to": "collaboratore007"
    },
    {
        "from": "collaboratore069",
        "to": "collaboratore242"
    },
    {
        "from": "collaboratore069",
        "to": "collaboratore165"
    },
    {
        "from": "collaboratore069",
        "to": "collaboratore014"
    },
    {
        "from": "collaboratore069",
        "to": "collaboratore296"
    },
    {
        "from": "collaboratore120",
        "to": "collaboratore195"
    },
    {
        "from": "collaboratore120",
        "to": "collaboratore007"
    },
    {
        "from": "collaboratore120",
        "to": "collaboratore316"
    },
    {
        "from": "collaboratore120",
        "to": "collaboratore090"
    },
    {
        "from": "collaboratore120",
        "to": "collaboratore246"
    },
    {
        "from": "collaboratore120",
        "to": "collaboratore242"
    },
    {
        "from": "collaboratore120",
        "to": "collaboratore256"
    },
    {
        "from": "collaboratore120",
        "to": "collaboratore291"
    },
    {
        "from": "collaboratore120",
        "to": "collaboratore194"
    },
    {
        "from": "collaboratore120",
        "to": "collaboratore128"
    },
    {
        "from": "collaboratore099",
        "to": "collaboratore093"
    },
    {
        "from": "collaboratore099",
        "to": "collaboratore164"
    },
    {
        "from": "collaboratore099",
        "to": "collaboratore216"
    },
    {
        "from": "collaboratore099",
        "to": "collaboratore049"
    },
    {
        "from": "collaboratore099",
        "to": "collaboratore102"
    },
    {
        "from": "collaboratore099",
        "to": "collaboratore149"
    },
    {
        "from": "collaboratore099",
        "to": "collaboratore264"
    },
    {
        "from": "collaboratore099",
        "to": "collaboratore071"
    },
    {
        "from": "collaboratore099",
        "to": "collaboratore194"
    },
    {
        "from": "collaboratore099",
        "to": "collaboratore152"
    },
    {
        "from": "collaboratore124",
        "to": "collaboratore273"
    },
    {
        "from": "collaboratore124",
        "to": "collaboratore265"
    },
    {
        "from": "collaboratore124",
        "to": "collaboratore004"
    },
    {
        "from": "collaboratore124",
        "to": "collaboratore030"
    },
    {
        "from": "collaboratore124",
        "to": "collaboratore306"
    },
    {
        "from": "collaboratore124",
        "to": "collaboratore192"
    },
    {
        "from": "collaboratore124",
        "to": "collaboratore262"
    },
    {
        "from": "collaboratore273",
        "to": "collaboratore072"
    },
    {
        "from": "collaboratore273",
        "to": "collaboratore133"
    },
    {
        "from": "collaboratore273",
        "to": "collaboratore236"
    },
    {
        "from": "collaboratore273",
        "to": "collaboratore273"
    },
    {
        "from": "collaboratore273",
        "to": "collaboratore265"
    },
    {
        "from": "collaboratore273",
        "to": "collaboratore259"
    },
    {
        "from": "collaboratore273",
        "to": "collaboratore143"
    },
    {
        "from": "collaboratore273",
        "to": "collaboratore291"
    },
    {
        "from": "collaboratore273",
        "to": "collaboratore285"
    },
    {
        "from": "collaboratore273",
        "to": "collaboratore002"
    },
    {
        "from": "collaboratore138",
        "to": "collaboratore326"
    },
    {
        "from": "collaboratore138",
        "to": "collaboratore289"
    },
    {
        "from": "collaboratore138",
        "to": "collaboratore088"
    },
    {
        "from": "collaboratore138",
        "to": "collaboratore145"
    },
    {
        "from": "collaboratore138",
        "to": "collaboratore240"
    },
    {
        "from": "collaboratore138",
        "to": "collaboratore262"
    },
    {
        "from": "collaboratore138",
        "to": "collaboratore055"
    },
    {
        "from": "collaboratore138",
        "to": "collaboratore171"
    },
    {
        "from": "collaboratore138",
        "to": "collaboratore068"
    },
    {
        "from": "collaboratore138",
        "to": "collaboratore106"
    },
    {
        "from": "collaboratore241",
        "to": "collaboratore080"
    },
    {
        "from": "collaboratore241",
        "to": "collaboratore015"
    },
    {
        "from": "collaboratore241",
        "to": "collaboratore263"
    },
    {
        "from": "collaboratore241",
        "to": "collaboratore298"
    },
    {
        "from": "collaboratore241",
        "to": "collaboratore323"
    },
    {
        "from": "collaboratore241",
        "to": "collaboratore221"
    },
    {
        "from": "collaboratore241",
        "to": "collaboratore100"
    },
    {
        "from": "collaboratore241",
        "to": "collaboratore116"
    },
    {
        "from": "collaboratore241",
        "to": "collaboratore141"
    },
    {
        "from": "collaboratore241",
        "to": "collaboratore022"
    },
    {
        "from": "collaboratore003",
        "to": "collaboratore306"
    },
    {
        "from": "collaboratore003",
        "to": "collaboratore054"
    },
    {
        "from": "collaboratore003",
        "to": "collaboratore289"
    },
    {
        "from": "collaboratore003",
        "to": "collaboratore060"
    },
    {
        "from": "collaboratore003",
        "to": "collaboratore113"
    },
    {
        "from": "collaboratore003",
        "to": "collaboratore230"
    },
    {
        "from": "collaboratore003",
        "to": "collaboratore196"
    },
    {
        "from": "collaboratore003",
        "to": "collaboratore255"
    },
    {
        "from": "collaboratore003",
        "to": "collaboratore001"
    },
    {
        "from": "collaboratore003",
        "to": "collaboratore154"
    },
    {
        "from": "collaboratore324",
        "to": "collaboratore162"
    },
    {
        "from": "collaboratore324",
        "to": "collaboratore319"
    },
    {
        "from": "collaboratore324",
        "to": "collaboratore262"
    },
    {
        "from": "collaboratore324",
        "to": "collaboratore298"
    },
    {
        "from": "collaboratore324",
        "to": "collaboratore185"
    },
    {
        "from": "collaboratore324",
        "to": "collaboratore159"
    },
    {
        "from": "collaboratore324",
        "to": "collaboratore187"
    },
    {
        "from": "collaboratore324",
        "to": "collaboratore089"
    },
    {
        "from": "collaboratore324",
        "to": "collaboratore157"
    },
    {
        "from": "collaboratore324",
        "to": "collaboratore141"
    },
    {
        "from": "collaboratore074",
        "to": "collaboratore070"
    },
    {
        "from": "collaboratore074",
        "to": "collaboratore173"
    },
    {
        "from": "collaboratore074",
        "to": "collaboratore234"
    },
    {
        "from": "collaboratore074",
        "to": "collaboratore064"
    },
    {
        "from": "collaboratore074",
        "to": "collaboratore092"
    },
    {
        "from": "collaboratore074",
        "to": "collaboratore078"
    },
    {
        "from": "collaboratore074",
        "to": "collaboratore018"
    },
    {
        "from": "collaboratore074",
        "to": "collaboratore169"
    },
    {
        "from": "collaboratore074",
        "to": "collaboratore238"
    },
    {
        "from": "collaboratore074",
        "to": "collaboratore183"
    },
    {
        "from": "collaboratore038",
        "to": "collaboratore320"
    },
    {
        "from": "collaboratore038",
        "to": "collaboratore105"
    },
    {
        "from": "collaboratore038",
        "to": "collaboratore010"
    },
    {
        "from": "collaboratore038",
        "to": "collaboratore063"
    },
    {
        "from": "collaboratore038",
        "to": "collaboratore217"
    },
    {
        "from": "collaboratore038",
        "to": "collaboratore108"
    },
    {
        "from": "collaboratore038",
        "to": "collaboratore287"
    },
    {
        "from": "collaboratore095",
        "to": "collaboratore263"
    },
    {
        "from": "collaboratore095",
        "to": "collaboratore008"
    },
    {
        "from": "collaboratore095",
        "to": "collaboratore185"
    },
    {
        "from": "collaboratore095",
        "to": "collaboratore238"
    },
    {
        "from": "collaboratore095",
        "to": "collaboratore247"
    },
    {
        "from": "collaboratore095",
        "to": "collaboratore157"
    },
    {
        "from": "collaboratore095",
        "to": "collaboratore076"
    },
    {
        "from": "collaboratore095",
        "to": "collaboratore290"
    },
    {
        "from": "collaboratore095",
        "to": "collaboratore314"
    },
    {
        "from": "collaboratore095",
        "to": "collaboratore249"
    },
    {
        "from": "collaboratore312",
        "to": "collaboratore030"
    },
    {
        "from": "collaboratore312",
        "to": "collaboratore289"
    },
    {
        "from": "collaboratore312",
        "to": "collaboratore060"
    },
    {
        "from": "collaboratore312",
        "to": "collaboratore138"
    },
    {
        "from": "collaboratore312",
        "to": "collaboratore230"
    },
    {
        "from": "collaboratore312",
        "to": "collaboratore055"
    },
    {
        "from": "collaboratore312",
        "to": "collaboratore155"
    },
    {
        "from": "collaboratore312",
        "to": "collaboratore068"
    },
    {
        "from": "collaboratore312",
        "to": "collaboratore075"
    },
    {
        "from": "collaboratore312",
        "to": "collaboratore255"
    },
    {
        "from": "collaboratore291",
        "to": "collaboratore186"
    },
    {
        "from": "collaboratore291",
        "to": "collaboratore007"
    },
    {
        "from": "collaboratore291",
        "to": "collaboratore046"
    },
    {
        "from": "collaboratore291",
        "to": "collaboratore032"
    },
    {
        "from": "collaboratore291",
        "to": "collaboratore246"
    },
    {
        "from": "collaboratore291",
        "to": "collaboratore225"
    },
    {
        "from": "collaboratore291",
        "to": "collaboratore242"
    },
    {
        "from": "collaboratore291",
        "to": "collaboratore014"
    },
    {
        "from": "collaboratore291",
        "to": "collaboratore027"
    },
    {
        "from": "collaboratore291",
        "to": "collaboratore122"
    },
    {
        "from": "collaboratore008",
        "to": "collaboratore218"
    },
    {
        "from": "collaboratore008",
        "to": "collaboratore263"
    },
    {
        "from": "collaboratore008",
        "to": "collaboratore179"
    },
    {
        "from": "collaboratore008",
        "to": "collaboratore221"
    },
    {
        "from": "collaboratore008",
        "to": "collaboratore153"
    },
    {
        "from": "collaboratore008",
        "to": "collaboratore095"
    },
    {
        "from": "collaboratore008",
        "to": "collaboratore071"
    },
    {
        "from": "collaboratore008",
        "to": "collaboratore157"
    },
    {
        "from": "collaboratore008",
        "to": "collaboratore314"
    },
    {
        "from": "collaboratore008",
        "to": "collaboratore122"
    },
    {
        "from": "collaboratore112",
        "to": "collaboratore060"
    },
    {
        "from": "collaboratore112",
        "to": "collaboratore012"
    },
    {
        "from": "collaboratore112",
        "to": "collaboratore110"
    },
    {
        "from": "collaboratore112",
        "to": "collaboratore075"
    },
    {
        "from": "collaboratore112",
        "to": "collaboratore255"
    },
    {
        "from": "collaboratore112",
        "to": "collaboratore001"
    },
    {
        "from": "collaboratore175",
        "to": "collaboratore271"
    },
    {
        "from": "collaboratore175",
        "to": "collaboratore324"
    },
    {
        "from": "collaboratore175",
        "to": "collaboratore262"
    },
    {
        "from": "collaboratore175",
        "to": "collaboratore283"
    },
    {
        "from": "collaboratore175",
        "to": "collaboratore151"
    },
    {
        "from": "collaboratore175",
        "to": "collaboratore276"
    },
    {
        "from": "collaboratore175",
        "to": "collaboratore067"
    },
    {
        "from": "collaboratore175",
        "to": "collaboratore116"
    },
    {
        "from": "collaboratore175",
        "to": "collaboratore122"
    },
    {
        "from": "collaboratore175",
        "to": "collaboratore002"
    },
    {
        "from": "collaboratore249",
        "to": "collaboratore305"
    },
    {
        "from": "collaboratore249",
        "to": "collaboratore107"
    },
    {
        "from": "collaboratore249",
        "to": "collaboratore090"
    },
    {
        "from": "collaboratore249",
        "to": "collaboratore214"
    },
    {
        "from": "collaboratore249",
        "to": "collaboratore058"
    },
    {
        "from": "collaboratore249",
        "to": "collaboratore095"
    },
    {
        "from": "collaboratore249",
        "to": "collaboratore276"
    },
    {
        "from": "collaboratore249",
        "to": "collaboratore157"
    },
    {
        "from": "collaboratore249",
        "to": "collaboratore076"
    },
    {
        "from": "collaboratore249",
        "to": "collaboratore059"
    },
    {
        "from": "collaboratore163",
        "to": "collaboratore107"
    },
    {
        "from": "collaboratore163",
        "to": "collaboratore200"
    },
    {
        "from": "collaboratore163",
        "to": "collaboratore145"
    },
    {
        "from": "collaboratore163",
        "to": "collaboratore297"
    },
    {
        "from": "collaboratore163",
        "to": "collaboratore233"
    },
    {
        "from": "collaboratore163",
        "to": "collaboratore081"
    },
    {
        "from": "collaboratore048",
        "to": "collaboratore320"
    },
    {
        "from": "collaboratore048",
        "to": "collaboratore105"
    },
    {
        "from": "collaboratore048",
        "to": "collaboratore270"
    },
    {
        "from": "collaboratore048",
        "to": "collaboratore063"
    },
    {
        "from": "collaboratore048",
        "to": "collaboratore316"
    },
    {
        "from": "collaboratore048",
        "to": "collaboratore159"
    },
    {
        "from": "collaboratore048",
        "to": "collaboratore110"
    },
    {
        "from": "collaboratore048",
        "to": "collaboratore287"
    },
    {
        "from": "collaboratore048",
        "to": "collaboratore194"
    },
    {
        "from": "collaboratore048",
        "to": "collaboratore084"
    },
    {
        "from": "collaboratore114",
        "to": "collaboratore126"
    },
    {
        "from": "collaboratore114",
        "to": "collaboratore025"
    },
    {
        "from": "collaboratore114",
        "to": "collaboratore251"
    },
    {
        "from": "collaboratore114",
        "to": "collaboratore190"
    },
    {
        "from": "collaboratore114",
        "to": "collaboratore008"
    },
    {
        "from": "collaboratore114",
        "to": "collaboratore252"
    },
    {
        "from": "collaboratore114",
        "to": "collaboratore095"
    },
    {
        "from": "collaboratore114",
        "to": "collaboratore169"
    },
    {
        "from": "collaboratore114",
        "to": "collaboratore157"
    },
    {
        "from": "collaboratore114",
        "to": "collaboratore314"
    },
    {
        "from": "collaboratore055",
        "to": "collaboratore005"
    },
    {
        "from": "collaboratore055",
        "to": "collaboratore326"
    },
    {
        "from": "collaboratore055",
        "to": "collaboratore088"
    },
    {
        "from": "collaboratore055",
        "to": "collaboratore138"
    },
    {
        "from": "collaboratore055",
        "to": "collaboratore286"
    },
    {
        "from": "collaboratore055",
        "to": "collaboratore291"
    },
    {
        "from": "collaboratore055",
        "to": "collaboratore062"
    },
    {
        "from": "collaboratore055",
        "to": "collaboratore299"
    },
    {
        "from": "collaboratore055",
        "to": "collaboratore104"
    },
    {
        "from": "collaboratore321",
        "to": "collaboratore026"
    },
    {
        "from": "collaboratore321",
        "to": "collaboratore325"
    },
    {
        "from": "collaboratore321",
        "to": "collaboratore301"
    },
    {
        "from": "collaboratore321",
        "to": "collaboratore130"
    },
    {
        "from": "collaboratore321",
        "to": "collaboratore100"
    },
    {
        "from": "collaboratore321",
        "to": "collaboratore153"
    },
    {
        "from": "collaboratore142",
        "to": "collaboratore005"
    },
    {
        "from": "collaboratore142",
        "to": "collaboratore136"
    },
    {
        "from": "collaboratore142",
        "to": "collaboratore033"
    },
    {
        "from": "collaboratore142",
        "to": "collaboratore212"
    },
    {
        "from": "collaboratore142",
        "to": "collaboratore297"
    },
    {
        "from": "collaboratore142",
        "to": "collaboratore224"
    },
    {
        "from": "collaboratore142",
        "to": "collaboratore156"
    },
    {
        "from": "collaboratore142",
        "to": "collaboratore036"
    },
    {
        "from": "collaboratore142",
        "to": "collaboratore284"
    },
    {
        "from": "collaboratore142",
        "to": "collaboratore045"
    },
    {
        "from": "collaboratore104",
        "to": "collaboratore094"
    },
    {
        "from": "collaboratore104",
        "to": "collaboratore326"
    },
    {
        "from": "collaboratore104",
        "to": "collaboratore131"
    },
    {
        "from": "collaboratore104",
        "to": "collaboratore055"
    },
    {
        "from": "collaboratore104",
        "to": "collaboratore125"
    },
    {
        "from": "collaboratore104",
        "to": "collaboratore299"
    },
    {
        "from": "collaboratore277",
        "to": "collaboratore126"
    },
    {
        "from": "collaboratore277",
        "to": "collaboratore070"
    },
    {
        "from": "collaboratore277",
        "to": "collaboratore293"
    },
    {
        "from": "collaboratore277",
        "to": "collaboratore173"
    },
    {
        "from": "collaboratore277",
        "to": "collaboratore234"
    },
    {
        "from": "collaboratore277",
        "to": "collaboratore270"
    },
    {
        "from": "collaboratore277",
        "to": "collaboratore092"
    },
    {
        "from": "collaboratore277",
        "to": "collaboratore279"
    },
    {
        "from": "collaboratore277",
        "to": "collaboratore204"
    },
    {
        "from": "collaboratore277",
        "to": "collaboratore183"
    },
    {
        "from": "collaboratore286",
        "to": "collaboratore005"
    },
    {
        "from": "collaboratore286",
        "to": "collaboratore326"
    },
    {
        "from": "collaboratore286",
        "to": "collaboratore088"
    },
    {
        "from": "collaboratore286",
        "to": "collaboratore138"
    },
    {
        "from": "collaboratore286",
        "to": "collaboratore297"
    },
    {
        "from": "collaboratore286",
        "to": "collaboratore055"
    },
    {
        "from": "collaboratore286",
        "to": "collaboratore291"
    },
    {
        "from": "collaboratore286",
        "to": "collaboratore034"
    },
    {
        "from": "collaboratore286",
        "to": "collaboratore068"
    },
    {
        "from": "collaboratore286",
        "to": "collaboratore062"
    },
    {
        "from": "collaboratore073",
        "to": "collaboratore024"
    },
    {
        "from": "collaboratore073",
        "to": "collaboratore192"
    },
    {
        "from": "collaboratore073",
        "to": "collaboratore254"
    },
    {
        "from": "collaboratore073",
        "to": "collaboratore225"
    },
    {
        "from": "collaboratore073",
        "to": "collaboratore102"
    },
    {
        "from": "collaboratore073",
        "to": "collaboratore170"
    },
    {
        "from": "collaboratore073",
        "to": "collaboratore193"
    },
    {
        "from": "collaboratore073",
        "to": "collaboratore002"
    },
    {
        "from": "collaboratore283",
        "to": "collaboratore271"
    },
    {
        "from": "collaboratore283",
        "to": "collaboratore162"
    },
    {
        "from": "collaboratore283",
        "to": "collaboratore151"
    },
    {
        "from": "collaboratore283",
        "to": "collaboratore185"
    },
    {
        "from": "collaboratore283",
        "to": "collaboratore116"
    },
    {
        "from": "collaboratore283",
        "to": "collaboratore022"
    },
    {
        "from": "collaboratore304",
        "to": "collaboratore018"
    },
    {
        "from": "collaboratore304",
        "to": "collaboratore214"
    },
    {
        "from": "collaboratore304",
        "to": "collaboratore115"
    },
    {
        "from": "collaboratore304",
        "to": "collaboratore052"
    },
    {
        "from": "collaboratore304",
        "to": "collaboratore292"
    },
    {
        "from": "collaboratore304",
        "to": "collaboratore238"
    },
    {
        "from": "collaboratore304",
        "to": "collaboratore247"
    },
    {
        "from": "collaboratore304",
        "to": "collaboratore226"
    },
    {
        "from": "collaboratore304",
        "to": "collaboratore117"
    },
    {
        "from": "collaboratore304",
        "to": "collaboratore222"
    },
    {
        "from": "collaboratore208",
        "to": "collaboratore158"
    },
    {
        "from": "collaboratore208",
        "to": "collaboratore218"
    },
    {
        "from": "collaboratore208",
        "to": "collaboratore042"
    },
    {
        "from": "collaboratore208",
        "to": "collaboratore136"
    },
    {
        "from": "collaboratore208",
        "to": "collaboratore139"
    },
    {
        "from": "collaboratore208",
        "to": "collaboratore297"
    },
    {
        "from": "collaboratore208",
        "to": "collaboratore146"
    },
    {
        "from": "collaboratore001",
        "to": "collaboratore042"
    },
    {
        "from": "collaboratore001",
        "to": "collaboratore306"
    },
    {
        "from": "collaboratore001",
        "to": "collaboratore113"
    },
    {
        "from": "collaboratore001",
        "to": "collaboratore079"
    },
    {
        "from": "collaboratore001",
        "to": "collaboratore196"
    },
    {
        "from": "collaboratore001",
        "to": "collaboratore012"
    },
    {
        "from": "collaboratore001",
        "to": "collaboratore075"
    },
    {
        "from": "collaboratore001",
        "to": "collaboratore255"
    },
    {
        "from": "collaboratore001",
        "to": "collaboratore211"
    },
    {
        "from": "collaboratore001",
        "to": "collaboratore154"
    },
    {
        "from": "collaboratore301",
        "to": "collaboratore320"
    },
    {
        "from": "collaboratore301",
        "to": "collaboratore105"
    },
    {
        "from": "collaboratore301",
        "to": "collaboratore010"
    },
    {
        "from": "collaboratore301",
        "to": "collaboratore110"
    },
    {
        "from": "collaboratore301",
        "to": "collaboratore108"
    },
    {
        "from": "collaboratore301",
        "to": "collaboratore287"
    },
    {
        "from": "collaboratore185",
        "to": "collaboratore263"
    },
    {
        "from": "collaboratore185",
        "to": "collaboratore262"
    },
    {
        "from": "collaboratore185",
        "to": "collaboratore161"
    },
    {
        "from": "collaboratore185",
        "to": "collaboratore283"
    },
    {
        "from": "collaboratore185",
        "to": "collaboratore298"
    },
    {
        "from": "collaboratore185",
        "to": "collaboratore272"
    },
    {
        "from": "collaboratore185",
        "to": "collaboratore116"
    },
    {
        "from": "collaboratore185",
        "to": "collaboratore122"
    },
    {
        "from": "collaboratore185",
        "to": "collaboratore141"
    },
    {
        "from": "collaboratore185",
        "to": "collaboratore022"
    },
    {
        "from": "collaboratore209",
        "to": "collaboratore074"
    },
    {
        "from": "collaboratore209",
        "to": "collaboratore064"
    },
    {
        "from": "collaboratore209",
        "to": "collaboratore092"
    },
    {
        "from": "collaboratore209",
        "to": "collaboratore078"
    },
    {
        "from": "collaboratore209",
        "to": "collaboratore018"
    },
    {
        "from": "collaboratore209",
        "to": "collaboratore238"
    },
    {
        "from": "collaboratore209",
        "to": "collaboratore226"
    },
    {
        "from": "collaboratore209",
        "to": "collaboratore117"
    },
    {
        "from": "collaboratore209",
        "to": "collaboratore087"
    },
    {
        "from": "collaboratore209",
        "to": "collaboratore222"
    },
    {
        "from": "collaboratore205",
        "to": "collaboratore265"
    },
    {
        "from": "collaboratore205",
        "to": "collaboratore321"
    },
    {
        "from": "collaboratore205",
        "to": "collaboratore318"
    },
    {
        "from": "collaboratore205",
        "to": "collaboratore315"
    },
    {
        "from": "collaboratore205",
        "to": "collaboratore282"
    },
    {
        "from": "collaboratore205",
        "to": "collaboratore026"
    },
    {
        "from": "collaboratore205",
        "to": "collaboratore021"
    },
    {
        "from": "collaboratore205",
        "to": "collaboratore231"
    },
    {
        "from": "collaboratore282",
        "to": "collaboratore318"
    },
    {
        "from": "collaboratore282",
        "to": "collaboratore315"
    },
    {
        "from": "collaboratore282",
        "to": "collaboratore026"
    },
    {
        "from": "collaboratore282",
        "to": "collaboratore323"
    },
    {
        "from": "collaboratore282",
        "to": "collaboratore225"
    },
    {
        "from": "collaboratore282",
        "to": "collaboratore205"
    },
    {
        "from": "collaboratore282",
        "to": "collaboratore233"
    },
    {
        "from": "collaboratore282",
        "to": "collaboratore081"
    },
    {
        "from": "collaboratore282",
        "to": "collaboratore021"
    },
    {
        "from": "collaboratore282",
        "to": "collaboratore231"
    },
    {
        "from": "collaboratore285",
        "to": "collaboratore070"
    },
    {
        "from": "collaboratore285",
        "to": "collaboratore107"
    },
    {
        "from": "collaboratore285",
        "to": "collaboratore090"
    },
    {
        "from": "collaboratore285",
        "to": "collaboratore011"
    },
    {
        "from": "collaboratore285",
        "to": "collaboratore256"
    },
    {
        "from": "collaboratore285",
        "to": "collaboratore187"
    },
    {
        "from": "collaboratore285",
        "to": "collaboratore307"
    },
    {
        "from": "collaboratore285",
        "to": "collaboratore116"
    },
    {
        "from": "collaboratore285",
        "to": "collaboratore081"
    },
    {
        "from": "collaboratore285",
        "to": "collaboratore084"
    },
    {
        "from": "collaboratore027",
        "to": "collaboratore093"
    },
    {
        "from": "collaboratore027",
        "to": "collaboratore319"
    },
    {
        "from": "collaboratore027",
        "to": "collaboratore263"
    },
    {
        "from": "collaboratore027",
        "to": "collaboratore164"
    },
    {
        "from": "collaboratore027",
        "to": "collaboratore049"
    },
    {
        "from": "collaboratore027",
        "to": "collaboratore130"
    },
    {
        "from": "collaboratore027",
        "to": "collaboratore102"
    },
    {
        "from": "collaboratore027",
        "to": "collaboratore152"
    },
    {
        "from": "collaboratore027",
        "to": "collaboratore065"
    },
    {
        "from": "collaboratore027",
        "to": "collaboratore122"
    },
    {
        "from": "collaboratore290",
        "to": "collaboratore139"
    },
    {
        "from": "collaboratore290",
        "to": "collaboratore192"
    },
    {
        "from": "collaboratore290",
        "to": "collaboratore078"
    },
    {
        "from": "collaboratore290",
        "to": "collaboratore029"
    },
    {
        "from": "collaboratore290",
        "to": "collaboratore153"
    },
    {
        "from": "collaboratore290",
        "to": "collaboratore095"
    },
    {
        "from": "collaboratore290",
        "to": "collaboratore247"
    },
    {
        "from": "collaboratore290",
        "to": "collaboratore157"
    },
    {
        "from": "collaboratore290",
        "to": "collaboratore076"
    },
    {
        "from": "collaboratore290",
        "to": "collaboratore059"
    },
    {
        "from": "collaboratore298",
        "to": "collaboratore262"
    },
    {
        "from": "collaboratore298",
        "to": "collaboratore144"
    },
    {
        "from": "collaboratore298",
        "to": "collaboratore216"
    },
    {
        "from": "collaboratore298",
        "to": "collaboratore185"
    },
    {
        "from": "collaboratore298",
        "to": "collaboratore011"
    },
    {
        "from": "collaboratore298",
        "to": "collaboratore066"
    },
    {
        "from": "collaboratore298",
        "to": "collaboratore028"
    },
    {
        "from": "collaboratore298",
        "to": "collaboratore307"
    },
    {
        "from": "collaboratore298",
        "to": "collaboratore116"
    },
    {
        "from": "collaboratore298",
        "to": "collaboratore141"
    },
    {
        "from": "collaboratore210",
        "to": "collaboratore248"
    },
    {
        "from": "collaboratore210",
        "to": "collaboratore202"
    },
    {
        "from": "collaboratore210",
        "to": "collaboratore305"
    },
    {
        "from": "collaboratore210",
        "to": "collaboratore186"
    },
    {
        "from": "collaboratore210",
        "to": "collaboratore077"
    },
    {
        "from": "collaboratore210",
        "to": "collaboratore011"
    },
    {
        "from": "collaboratore210",
        "to": "collaboratore242"
    },
    {
        "from": "collaboratore210",
        "to": "collaboratore177"
    },
    {
        "from": "collaboratore210",
        "to": "collaboratore266"
    },
    {
        "from": "collaboratore115",
        "to": "collaboratore214"
    },
    {
        "from": "collaboratore115",
        "to": "collaboratore052"
    },
    {
        "from": "collaboratore115",
        "to": "collaboratore292"
    },
    {
        "from": "collaboratore115",
        "to": "collaboratore304"
    },
    {
        "from": "collaboratore115",
        "to": "collaboratore238"
    },
    {
        "from": "collaboratore115",
        "to": "collaboratore247"
    },
    {
        "from": "collaboratore115",
        "to": "collaboratore226"
    },
    {
        "from": "collaboratore115",
        "to": "collaboratore117"
    },
    {
        "from": "collaboratore115",
        "to": "collaboratore087"
    },
    {
        "from": "collaboratore115",
        "to": "collaboratore222"
    },
    {
        "from": "collaboratore176",
        "to": "collaboratore042"
    },
    {
        "from": "collaboratore176",
        "to": "collaboratore270"
    },
    {
        "from": "collaboratore176",
        "to": "collaboratore113"
    },
    {
        "from": "collaboratore176",
        "to": "collaboratore203"
    },
    {
        "from": "collaboratore176",
        "to": "collaboratore079"
    },
    {
        "from": "collaboratore176",
        "to": "collaboratore149"
    },
    {
        "from": "collaboratore176",
        "to": "collaboratore197"
    },
    {
        "from": "collaboratore176",
        "to": "collaboratore287"
    },
    {
        "from": "collaboratore176",
        "to": "collaboratore001"
    },
    {
        "from": "collaboratore176",
        "to": "collaboratore211"
    },
    {
        "from": "collaboratore248",
        "to": "collaboratore202"
    },
    {
        "from": "collaboratore248",
        "to": "collaboratore201"
    },
    {
        "from": "collaboratore248",
        "to": "collaboratore297"
    },
    {
        "from": "collaboratore248",
        "to": "collaboratore241"
    },
    {
        "from": "collaboratore248",
        "to": "collaboratore011"
    },
    {
        "from": "collaboratore248",
        "to": "collaboratore242"
    },
    {
        "from": "collaboratore248",
        "to": "collaboratore247"
    },
    {
        "from": "collaboratore248",
        "to": "collaboratore135"
    },
    {
        "from": "collaboratore305",
        "to": "collaboratore046"
    },
    {
        "from": "collaboratore305",
        "to": "collaboratore039"
    },
    {
        "from": "collaboratore305",
        "to": "collaboratore049"
    },
    {
        "from": "collaboratore305",
        "to": "collaboratore189"
    },
    {
        "from": "collaboratore305",
        "to": "collaboratore032"
    },
    {
        "from": "collaboratore305",
        "to": "collaboratore102"
    },
    {
        "from": "collaboratore090",
        "to": "collaboratore070"
    },
    {
        "from": "collaboratore090",
        "to": "collaboratore251"
    },
    {
        "from": "collaboratore090",
        "to": "collaboratore004"
    },
    {
        "from": "collaboratore090",
        "to": "collaboratore107"
    },
    {
        "from": "collaboratore090",
        "to": "collaboratore011"
    },
    {
        "from": "collaboratore090",
        "to": "collaboratore187"
    },
    {
        "from": "collaboratore090",
        "to": "collaboratore307"
    },
    {
        "from": "collaboratore090",
        "to": "collaboratore285"
    },
    {
        "from": "collaboratore090",
        "to": "collaboratore141"
    },
    {
        "from": "collaboratore090",
        "to": "collaboratore084"
    },
    {
        "from": "collaboratore272",
        "to": "collaboratore293"
    },
    {
        "from": "collaboratore272",
        "to": "collaboratore047"
    },
    {
        "from": "collaboratore272",
        "to": "collaboratore016"
    },
    {
        "from": "collaboratore272",
        "to": "collaboratore168"
    },
    {
        "from": "collaboratore272",
        "to": "collaboratore223"
    },
    {
        "from": "collaboratore272",
        "to": "collaboratore298"
    },
    {
        "from": "collaboratore272",
        "to": "collaboratore185"
    },
    {
        "from": "collaboratore272",
        "to": "collaboratore311"
    },
    {
        "from": "collaboratore272",
        "to": "collaboratore017"
    },
    {
        "from": "collaboratore272",
        "to": "collaboratore135"
    },
    {
        "from": "collaboratore217",
        "to": "collaboratore101"
    },
    {
        "from": "collaboratore217",
        "to": "collaboratore162"
    },
    {
        "from": "collaboratore217",
        "to": "collaboratore105"
    },
    {
        "from": "collaboratore217",
        "to": "collaboratore010"
    },
    {
        "from": "collaboratore217",
        "to": "collaboratore063"
    },
    {
        "from": "collaboratore217",
        "to": "collaboratore301"
    },
    {
        "from": "collaboratore217",
        "to": "collaboratore159"
    },
    {
        "from": "collaboratore217",
        "to": "collaboratore110"
    },
    {
        "from": "collaboratore217",
        "to": "collaboratore194"
    },
    {
        "from": "collaboratore217",
        "to": "collaboratore237"
    },
    {
        "from": "collaboratore081",
        "to": "collaboratore070"
    },
    {
        "from": "collaboratore081",
        "to": "collaboratore107"
    },
    {
        "from": "collaboratore081",
        "to": "collaboratore011"
    },
    {
        "from": "collaboratore081",
        "to": "collaboratore256"
    },
    {
        "from": "collaboratore081",
        "to": "collaboratore307"
    },
    {
        "from": "collaboratore081",
        "to": "collaboratore233"
    },
    {
        "from": "collaboratore081",
        "to": "collaboratore322"
    },
    {
        "from": "collaboratore133",
        "to": "collaboratore235"
    },
    {
        "from": "collaboratore133",
        "to": "collaboratore236"
    },
    {
        "from": "collaboratore133",
        "to": "collaboratore109"
    },
    {
        "from": "collaboratore133",
        "to": "collaboratore148"
    },
    {
        "from": "collaboratore133",
        "to": "collaboratore121"
    },
    {
        "from": "collaboratore133",
        "to": "collaboratore014"
    },
    {
        "from": "collaboratore170",
        "to": "collaboratore057"
    },
    {
        "from": "collaboratore170",
        "to": "collaboratore024"
    },
    {
        "from": "collaboratore170",
        "to": "collaboratore073"
    },
    {
        "from": "collaboratore170",
        "to": "collaboratore192"
    },
    {
        "from": "collaboratore170",
        "to": "collaboratore049"
    },
    {
        "from": "collaboratore170",
        "to": "collaboratore254"
    },
    {
        "from": "collaboratore170",
        "to": "collaboratore102"
    },
    {
        "from": "collaboratore170",
        "to": "collaboratore228"
    },
    {
        "from": "collaboratore170",
        "to": "collaboratore193"
    },
    {
        "from": "collaboratore170",
        "to": "collaboratore056"
    },
    {
        "from": "collaboratore234",
        "to": "collaboratore070"
    },
    {
        "from": "collaboratore234",
        "to": "collaboratore114"
    },
    {
        "from": "collaboratore234",
        "to": "collaboratore173"
    },
    {
        "from": "collaboratore234",
        "to": "collaboratore251"
    },
    {
        "from": "collaboratore234",
        "to": "collaboratore074"
    },
    {
        "from": "collaboratore234",
        "to": "collaboratore201"
    },
    {
        "from": "collaboratore234",
        "to": "collaboratore190"
    },
    {
        "from": "collaboratore234",
        "to": "collaboratore018"
    },
    {
        "from": "collaboratore234",
        "to": "collaboratore090"
    },
    {
        "from": "collaboratore234",
        "to": "collaboratore081"
    },
    {
        "from": "collaboratore134",
        "to": "collaboratore161"
    },
    {
        "from": "collaboratore134",
        "to": "collaboratore168"
    },
    {
        "from": "collaboratore134",
        "to": "collaboratore283"
    },
    {
        "from": "collaboratore134",
        "to": "collaboratore216"
    },
    {
        "from": "collaboratore134",
        "to": "collaboratore151"
    },
    {
        "from": "collaboratore134",
        "to": "collaboratore298"
    },
    {
        "from": "collaboratore134",
        "to": "collaboratore149"
    },
    {
        "from": "collaboratore134",
        "to": "collaboratore116"
    },
    {
        "from": "collaboratore134",
        "to": "collaboratore122"
    },
    {
        "from": "collaboratore134",
        "to": "collaboratore002"
    },
    {
        "from": "collaboratore014",
        "to": "collaboratore235"
    },
    {
        "from": "collaboratore014",
        "to": "collaboratore248"
    },
    {
        "from": "collaboratore014",
        "to": "collaboratore236"
    },
    {
        "from": "collaboratore014",
        "to": "collaboratore319"
    },
    {
        "from": "collaboratore014",
        "to": "collaboratore046"
    },
    {
        "from": "collaboratore014",
        "to": "collaboratore032"
    },
    {
        "from": "collaboratore014",
        "to": "collaboratore156"
    },
    {
        "from": "collaboratore014",
        "to": "collaboratore130"
    },
    {
        "from": "collaboratore014",
        "to": "collaboratore291"
    },
    {
        "from": "collaboratore014",
        "to": "collaboratore121"
    },
    {
        "from": "collaboratore180",
        "to": "collaboratore271"
    },
    {
        "from": "collaboratore180",
        "to": "collaboratore162"
    },
    {
        "from": "collaboratore180",
        "to": "collaboratore274"
    },
    {
        "from": "collaboratore180",
        "to": "collaboratore161"
    },
    {
        "from": "collaboratore180",
        "to": "collaboratore283"
    },
    {
        "from": "collaboratore180",
        "to": "collaboratore134"
    },
    {
        "from": "collaboratore180",
        "to": "collaboratore185"
    },
    {
        "from": "collaboratore180",
        "to": "collaboratore086"
    },
    {
        "from": "collaboratore180",
        "to": "collaboratore116"
    },
    {
        "from": "collaboratore180",
        "to": "collaboratore122"
    },
    {
        "from": "collaboratore193",
        "to": "collaboratore057"
    },
    {
        "from": "collaboratore193",
        "to": "collaboratore093"
    },
    {
        "from": "collaboratore193",
        "to": "collaboratore319"
    },
    {
        "from": "collaboratore193",
        "to": "collaboratore321"
    },
    {
        "from": "collaboratore193",
        "to": "collaboratore024"
    },
    {
        "from": "collaboratore193",
        "to": "collaboratore254"
    },
    {
        "from": "collaboratore193",
        "to": "collaboratore102"
    },
    {
        "from": "collaboratore193",
        "to": "collaboratore264"
    },
    {
        "from": "collaboratore193",
        "to": "collaboratore170"
    },
    {
        "from": "collaboratore193",
        "to": "collaboratore027"
    },
    {
        "from": "collaboratore009",
        "to": "collaboratore302"
    },
    {
        "from": "collaboratore009",
        "to": "collaboratore164"
    },
    {
        "from": "collaboratore009",
        "to": "collaboratore032"
    },
    {
        "from": "collaboratore009",
        "to": "collaboratore241"
    },
    {
        "from": "collaboratore009",
        "to": "collaboratore242"
    },
    {
        "from": "collaboratore009",
        "to": "collaboratore307"
    },
    {
        "from": "collaboratore009",
        "to": "collaboratore291"
    },
    {
        "from": "collaboratore009",
        "to": "collaboratore135"
    },
    {
        "from": "collaboratore063",
        "to": "collaboratore101"
    },
    {
        "from": "collaboratore063",
        "to": "collaboratore320"
    },
    {
        "from": "collaboratore063",
        "to": "collaboratore042"
    },
    {
        "from": "collaboratore063",
        "to": "collaboratore105"
    },
    {
        "from": "collaboratore063",
        "to": "collaboratore270"
    },
    {
        "from": "collaboratore063",
        "to": "collaboratore217"
    },
    {
        "from": "collaboratore063",
        "to": "collaboratore287"
    },
    {
        "from": "collaboratore063",
        "to": "collaboratore038"
    },
    {
        "from": "collaboratore063",
        "to": "collaboratore081"
    },
    {
        "from": "collaboratore063",
        "to": "collaboratore237"
    },
    {
        "from": "collaboratore126",
        "to": "collaboratore025"
    },
    {
        "from": "collaboratore126",
        "to": "collaboratore114"
    },
    {
        "from": "collaboratore126",
        "to": "collaboratore173"
    },
    {
        "from": "collaboratore126",
        "to": "collaboratore251"
    },
    {
        "from": "collaboratore126",
        "to": "collaboratore279"
    },
    {
        "from": "collaboratore126",
        "to": "collaboratore190"
    },
    {
        "from": "collaboratore126",
        "to": "collaboratore169"
    },
    {
        "from": "collaboratore126",
        "to": "collaboratore226"
    },
    {
        "from": "collaboratore126",
        "to": "collaboratore277"
    },
    {
        "from": "collaboratore126",
        "to": "collaboratore314"
    },
    {
        "from": "collaboratore052",
        "to": "collaboratore173"
    },
    {
        "from": "collaboratore052",
        "to": "collaboratore136"
    },
    {
        "from": "collaboratore052",
        "to": "collaboratore181"
    },
    {
        "from": "collaboratore052",
        "to": "collaboratore092"
    },
    {
        "from": "collaboratore052",
        "to": "collaboratore078"
    },
    {
        "from": "collaboratore052",
        "to": "collaboratore304"
    },
    {
        "from": "collaboratore052",
        "to": "collaboratore095"
    },
    {
        "from": "collaboratore052",
        "to": "collaboratore238"
    },
    {
        "from": "collaboratore052",
        "to": "collaboratore226"
    },
    {
        "from": "collaboratore052",
        "to": "collaboratore117"
    },
    {
        "from": "collaboratore147",
        "to": "collaboratore184"
    },
    {
        "from": "collaboratore147",
        "to": "collaboratore131"
    },
    {
        "from": "collaboratore147",
        "to": "collaboratore179"
    },
    {
        "from": "collaboratore147",
        "to": "collaboratore019"
    },
    {
        "from": "collaboratore147",
        "to": "collaboratore041"
    },
    {
        "from": "collaboratore147",
        "to": "collaboratore280"
    },
    {
        "from": "collaboratore147",
        "to": "collaboratore313"
    },
    {
        "from": "collaboratore147",
        "to": "collaboratore082"
    },
    {
        "from": "collaboratore147",
        "to": "collaboratore031"
    },
    {
        "from": "collaboratore147",
        "to": "collaboratore276"
    },
    {
        "from": "collaboratore297",
        "to": "collaboratore005"
    },
    {
        "from": "collaboratore297",
        "to": "collaboratore163"
    },
    {
        "from": "collaboratore297",
        "to": "collaboratore088"
    },
    {
        "from": "collaboratore297",
        "to": "collaboratore200"
    },
    {
        "from": "collaboratore297",
        "to": "collaboratore145"
    },
    {
        "from": "collaboratore297",
        "to": "collaboratore268"
    },
    {
        "from": "collaboratore297",
        "to": "collaboratore140"
    },
    {
        "from": "collaboratore297",
        "to": "collaboratore280"
    },
    {
        "from": "collaboratore297",
        "to": "collaboratore284"
    },
    {
        "from": "collaboratore297",
        "to": "collaboratore171"
    },
    {
        "from": "collaboratore105",
        "to": "collaboratore080"
    },
    {
        "from": "collaboratore105",
        "to": "collaboratore320"
    },
    {
        "from": "collaboratore105",
        "to": "collaboratore010"
    },
    {
        "from": "collaboratore105",
        "to": "collaboratore063"
    },
    {
        "from": "collaboratore105",
        "to": "collaboratore217"
    },
    {
        "from": "collaboratore105",
        "to": "collaboratore301"
    },
    {
        "from": "collaboratore105",
        "to": "collaboratore110"
    },
    {
        "from": "collaboratore105",
        "to": "collaboratore108"
    },
    {
        "from": "collaboratore105",
        "to": "collaboratore287"
    },
    {
        "from": "collaboratore105",
        "to": "collaboratore296"
    },
    {
        "from": "collaboratore218",
        "to": "collaboratore080"
    },
    {
        "from": "collaboratore218",
        "to": "collaboratore042"
    },
    {
        "from": "collaboratore218",
        "to": "collaboratore263"
    },
    {
        "from": "collaboratore218",
        "to": "collaboratore139"
    },
    {
        "from": "collaboratore218",
        "to": "collaboratore267"
    },
    {
        "from": "collaboratore218",
        "to": "collaboratore221"
    },
    {
        "from": "collaboratore218",
        "to": "collaboratore095"
    },
    {
        "from": "collaboratore218",
        "to": "collaboratore122"
    },
    {
        "from": "collaboratore218",
        "to": "collaboratore146"
    },
    {
        "from": "collaboratore263",
        "to": "collaboratore080"
    },
    {
        "from": "collaboratore263",
        "to": "collaboratore244"
    },
    {
        "from": "collaboratore263",
        "to": "collaboratore215"
    },
    {
        "from": "collaboratore263",
        "to": "collaboratore267"
    },
    {
        "from": "collaboratore263",
        "to": "collaboratore185"
    },
    {
        "from": "collaboratore263",
        "to": "collaboratore241"
    },
    {
        "from": "collaboratore263",
        "to": "collaboratore221"
    },
    {
        "from": "collaboratore263",
        "to": "collaboratore100"
    },
    {
        "from": "collaboratore263",
        "to": "collaboratore187"
    },
    {
        "from": "collaboratore263",
        "to": "collaboratore122"
    },
    {
        "from": "collaboratore262",
        "to": "collaboratore162"
    },
    {
        "from": "collaboratore262",
        "to": "collaboratore015"
    },
    {
        "from": "collaboratore262",
        "to": "collaboratore004"
    },
    {
        "from": "collaboratore262",
        "to": "collaboratore324"
    },
    {
        "from": "collaboratore262",
        "to": "collaboratore090"
    },
    {
        "from": "collaboratore262",
        "to": "collaboratore298"
    },
    {
        "from": "collaboratore262",
        "to": "collaboratore185"
    },
    {
        "from": "collaboratore262",
        "to": "collaboratore307"
    },
    {
        "from": "collaboratore262",
        "to": "collaboratore122"
    },
    {
        "from": "collaboratore262",
        "to": "collaboratore141"
    },
    {
        "from": "collaboratore023",
        "to": "collaboratore244"
    },
    {
        "from": "collaboratore023",
        "to": "collaboratore274"
    },
    {
        "from": "collaboratore023",
        "to": "collaboratore263"
    },
    {
        "from": "collaboratore023",
        "to": "collaboratore262"
    },
    {
        "from": "collaboratore023",
        "to": "collaboratore283"
    },
    {
        "from": "collaboratore023",
        "to": "collaboratore151"
    },
    {
        "from": "collaboratore023",
        "to": "collaboratore083"
    },
    {
        "from": "collaboratore023",
        "to": "collaboratore116"
    },
    {
        "from": "collaboratore040",
        "to": "collaboratore094"
    },
    {
        "from": "collaboratore040",
        "to": "collaboratore281"
    },
    {
        "from": "collaboratore040",
        "to": "collaboratore005"
    },
    {
        "from": "collaboratore040",
        "to": "collaboratore212"
    },
    {
        "from": "collaboratore040",
        "to": "collaboratore179"
    },
    {
        "from": "collaboratore040",
        "to": "collaboratore200"
    },
    {
        "from": "collaboratore040",
        "to": "collaboratore041"
    },
    {
        "from": "collaboratore040",
        "to": "collaboratore140"
    },
    {
        "from": "collaboratore040",
        "to": "collaboratore031"
    },
    {
        "from": "collaboratore040",
        "to": "collaboratore050"
    },
    {
        "from": "collaboratore281",
        "to": "collaboratore295"
    },
    {
        "from": "collaboratore281",
        "to": "collaboratore004"
    },
    {
        "from": "collaboratore281",
        "to": "collaboratore213"
    },
    {
        "from": "collaboratore281",
        "to": "collaboratore033"
    },
    {
        "from": "collaboratore281",
        "to": "collaboratore297"
    },
    {
        "from": "collaboratore281",
        "to": "collaboratore140"
    },
    {
        "from": "collaboratore281",
        "to": "collaboratore097"
    },
    {
        "from": "collaboratore281",
        "to": "collaboratore325"
    },
    {
        "from": "collaboratore281",
        "to": "collaboratore278"
    },
    {
        "from": "collaboratore281",
        "to": "collaboratore040"
    },
    {
        "from": "collaboratore071",
        "to": "collaboratore123"
    },
    {
        "from": "collaboratore071",
        "to": "collaboratore153"
    },
    {
        "from": "collaboratore071",
        "to": "collaboratore095"
    },
    {
        "from": "collaboratore071",
        "to": "collaboratore220"
    },
    {
        "from": "collaboratore071",
        "to": "collaboratore157"
    },
    {
        "from": "collaboratore071",
        "to": "collaboratore290"
    },
    {
        "from": "collaboratore060",
        "to": "collaboratore042"
    },
    {
        "from": "collaboratore060",
        "to": "collaboratore004"
    },
    {
        "from": "collaboratore060",
        "to": "collaboratore289"
    },
    {
        "from": "collaboratore060",
        "to": "collaboratore026"
    },
    {
        "from": "collaboratore060",
        "to": "collaboratore196"
    },
    {
        "from": "collaboratore060",
        "to": "collaboratore159"
    },
    {
        "from": "collaboratore060",
        "to": "collaboratore312"
    },
    {
        "from": "collaboratore060",
        "to": "collaboratore154"
    },
    {
        "from": "collaboratore177",
        "to": "collaboratore248"
    },
    {
        "from": "collaboratore177",
        "to": "collaboratore202"
    },
    {
        "from": "collaboratore177",
        "to": "collaboratore077"
    },
    {
        "from": "collaboratore177",
        "to": "collaboratore189"
    },
    {
        "from": "collaboratore177",
        "to": "collaboratore011"
    },
    {
        "from": "collaboratore177",
        "to": "collaboratore246"
    },
    {
        "from": "collaboratore177",
        "to": "collaboratore242"
    },
    {
        "from": "collaboratore177",
        "to": "collaboratore118"
    },
    {
        "from": "collaboratore177",
        "to": "collaboratore210"
    },
    {
        "from": "collaboratore177",
        "to": "collaboratore231"
    },
    {
        "from": "collaboratore307",
        "to": "collaboratore201"
    },
    {
        "from": "collaboratore307",
        "to": "collaboratore262"
    },
    {
        "from": "collaboratore307",
        "to": "collaboratore090"
    },
    {
        "from": "collaboratore307",
        "to": "collaboratore185"
    },
    {
        "from": "collaboratore307",
        "to": "collaboratore011"
    },
    {
        "from": "collaboratore307",
        "to": "collaboratore308"
    },
    {
        "from": "collaboratore307",
        "to": "collaboratore233"
    },
    {
        "from": "collaboratore307",
        "to": "collaboratore285"
    },
    {
        "from": "collaboratore307",
        "to": "collaboratore081"
    },
    {
        "from": "collaboratore307",
        "to": "collaboratore141"
    },
    {
        "from": "collaboratore101",
        "to": "collaboratore070"
    },
    {
        "from": "collaboratore101",
        "to": "collaboratore105"
    },
    {
        "from": "collaboratore101",
        "to": "collaboratore063"
    },
    {
        "from": "collaboratore101",
        "to": "collaboratore217"
    },
    {
        "from": "collaboratore101",
        "to": "collaboratore204"
    },
    {
        "from": "collaboratore101",
        "to": "collaboratore277"
    },
    {
        "from": "collaboratore026",
        "to": "collaboratore319"
    },
    {
        "from": "collaboratore026",
        "to": "collaboratore306"
    },
    {
        "from": "collaboratore026",
        "to": "collaboratore263"
    },
    {
        "from": "collaboratore026",
        "to": "collaboratore315"
    },
    {
        "from": "collaboratore026",
        "to": "collaboratore196"
    },
    {
        "from": "collaboratore026",
        "to": "collaboratore029"
    },
    {
        "from": "collaboratore026",
        "to": "collaboratore221"
    },
    {
        "from": "collaboratore026",
        "to": "collaboratore187"
    },
    {
        "from": "collaboratore026",
        "to": "collaboratore247"
    },
    {
        "from": "collaboratore026",
        "to": "collaboratore122"
    },
    {
        "from": "collaboratore284",
        "to": "collaboratore303"
    },
    {
        "from": "collaboratore284",
        "to": "collaboratore195"
    },
    {
        "from": "collaboratore284",
        "to": "collaboratore188"
    },
    {
        "from": "collaboratore284",
        "to": "collaboratore174"
    },
    {
        "from": "collaboratore284",
        "to": "collaboratore145"
    },
    {
        "from": "collaboratore284",
        "to": "collaboratore316"
    },
    {
        "from": "collaboratore284",
        "to": "collaboratore297"
    },
    {
        "from": "collaboratore284",
        "to": "collaboratore280"
    },
    {
        "from": "collaboratore284",
        "to": "collaboratore224"
    },
    {
        "from": "collaboratore284",
        "to": "collaboratore128"
    },
    {
        "from": "collaboratore221",
        "to": "collaboratore274"
    },
    {
        "from": "collaboratore221",
        "to": "collaboratore263"
    },
    {
        "from": "collaboratore221",
        "to": "collaboratore026"
    },
    {
        "from": "collaboratore221",
        "to": "collaboratore079"
    },
    {
        "from": "collaboratore221",
        "to": "collaboratore008"
    },
    {
        "from": "collaboratore221",
        "to": "collaboratore241"
    },
    {
        "from": "collaboratore221",
        "to": "collaboratore100"
    },
    {
        "from": "collaboratore221",
        "to": "collaboratore247"
    },
    {
        "from": "collaboratore221",
        "to": "collaboratore027"
    },
    {
        "from": "collaboratore221",
        "to": "collaboratore122"
    },
    {
        "from": "collaboratore106",
        "to": "collaboratore326"
    },
    {
        "from": "collaboratore106",
        "to": "collaboratore054"
    },
    {
        "from": "collaboratore106",
        "to": "collaboratore289"
    },
    {
        "from": "collaboratore106",
        "to": "collaboratore088"
    },
    {
        "from": "collaboratore106",
        "to": "collaboratore240"
    },
    {
        "from": "collaboratore106",
        "to": "collaboratore138"
    },
    {
        "from": "collaboratore106",
        "to": "collaboratore055"
    },
    {
        "from": "collaboratore106",
        "to": "collaboratore286"
    },
    {
        "from": "collaboratore106",
        "to": "collaboratore276"
    },
    {
        "from": "collaboratore106",
        "to": "collaboratore068"
    },
    {
        "from": "collaboratore318",
        "to": "collaboratore265"
    },
    {
        "from": "collaboratore318",
        "to": "collaboratore321"
    },
    {
        "from": "collaboratore318",
        "to": "collaboratore315"
    },
    {
        "from": "collaboratore318",
        "to": "collaboratore282"
    },
    {
        "from": "collaboratore318",
        "to": "collaboratore310"
    },
    {
        "from": "collaboratore318",
        "to": "collaboratore205"
    },
    {
        "from": "collaboratore318",
        "to": "collaboratore085"
    },
    {
        "from": "collaboratore318",
        "to": "collaboratore021"
    },
    {
        "from": "collaboratore318",
        "to": "collaboratore317"
    },
    {
        "from": "collaboratore318",
        "to": "collaboratore231"
    },
    {
        "from": "collaboratore165",
        "to": "collaboratore127"
    },
    {
        "from": "collaboratore165",
        "to": "collaboratore005"
    },
    {
        "from": "collaboratore165",
        "to": "collaboratore263"
    },
    {
        "from": "collaboratore165",
        "to": "collaboratore212"
    },
    {
        "from": "collaboratore165",
        "to": "collaboratore302"
    },
    {
        "from": "collaboratore165",
        "to": "collaboratore145"
    },
    {
        "from": "collaboratore165",
        "to": "collaboratore078"
    },
    {
        "from": "collaboratore165",
        "to": "collaboratore018"
    },
    {
        "from": "collaboratore165",
        "to": "collaboratore297"
    },
    {
        "from": "collaboratore165",
        "to": "collaboratore226"
    },
    {
        "from": "collaboratore230",
        "to": "collaboratore003"
    },
    {
        "from": "collaboratore230",
        "to": "collaboratore004"
    },
    {
        "from": "collaboratore230",
        "to": "collaboratore213"
    },
    {
        "from": "collaboratore230",
        "to": "collaboratore306"
    },
    {
        "from": "collaboratore230",
        "to": "collaboratore289"
    },
    {
        "from": "collaboratore230",
        "to": "collaboratore060"
    },
    {
        "from": "collaboratore230",
        "to": "collaboratore097"
    },
    {
        "from": "collaboratore230",
        "to": "collaboratore243"
    },
    {
        "from": "collaboratore230",
        "to": "collaboratore312"
    },
    {
        "from": "collaboratore230",
        "to": "collaboratore278"
    },
    {
        "from": "collaboratore155",
        "to": "collaboratore295"
    },
    {
        "from": "collaboratore155",
        "to": "collaboratore281"
    },
    {
        "from": "collaboratore155",
        "to": "collaboratore013"
    },
    {
        "from": "collaboratore155",
        "to": "collaboratore136"
    },
    {
        "from": "collaboratore155",
        "to": "collaboratore326"
    },
    {
        "from": "collaboratore155",
        "to": "collaboratore138"
    },
    {
        "from": "collaboratore155",
        "to": "collaboratore140"
    },
    {
        "from": "collaboratore155",
        "to": "collaboratore156"
    },
    {
        "from": "collaboratore155",
        "to": "collaboratore104"
    },
    {
        "from": "collaboratore155",
        "to": "collaboratore040"
    },
    {
        "from": "collaboratore024",
        "to": "collaboratore057"
    },
    {
        "from": "collaboratore024",
        "to": "collaboratore192"
    },
    {
        "from": "collaboratore024",
        "to": "collaboratore049"
    },
    {
        "from": "collaboratore024",
        "to": "collaboratore029"
    },
    {
        "from": "collaboratore024",
        "to": "collaboratore254"
    },
    {
        "from": "collaboratore024",
        "to": "collaboratore102"
    },
    {
        "from": "collaboratore024",
        "to": "collaboratore170"
    },
    {
        "from": "collaboratore024",
        "to": "collaboratore152"
    },
    {
        "from": "collaboratore024",
        "to": "collaboratore027"
    },
    {
        "from": "collaboratore051",
        "to": "collaboratore094"
    },
    {
        "from": "collaboratore051",
        "to": "collaboratore136"
    },
    {
        "from": "collaboratore051",
        "to": "collaboratore184"
    },
    {
        "from": "collaboratore051",
        "to": "collaboratore131"
    },
    {
        "from": "collaboratore051",
        "to": "collaboratore179"
    },
    {
        "from": "collaboratore051",
        "to": "collaboratore019"
    },
    {
        "from": "collaboratore051",
        "to": "collaboratore280"
    },
    {
        "from": "collaboratore051",
        "to": "collaboratore147"
    },
    {
        "from": "collaboratore051",
        "to": "collaboratore313"
    },
    {
        "from": "collaboratore051",
        "to": "collaboratore219"
    },
    {
        "from": "collaboratore232",
        "to": "collaboratore072"
    },
    {
        "from": "collaboratore232",
        "to": "collaboratore111"
    },
    {
        "from": "collaboratore232",
        "to": "collaboratore273"
    },
    {
        "from": "collaboratore232",
        "to": "collaboratore300"
    },
    {
        "from": "collaboratore232",
        "to": "collaboratore259"
    },
    {
        "from": "collaboratore232",
        "to": "collaboratore091"
    },
    {
        "from": "collaboratore232",
        "to": "collaboratore011"
    },
    {
        "from": "collaboratore232",
        "to": "collaboratore225"
    },
    {
        "from": "collaboratore232",
        "to": "collaboratore232"
    },
    {
        "from": "collaboratore232",
        "to": "collaboratore081"
    },
    {
        "from": "collaboratore010",
        "to": "collaboratore105"
    },
    {
        "from": "collaboratore010",
        "to": "collaboratore139"
    },
    {
        "from": "collaboratore010",
        "to": "collaboratore063"
    },
    {
        "from": "collaboratore010",
        "to": "collaboratore217"
    },
    {
        "from": "collaboratore010",
        "to": "collaboratore224"
    },
    {
        "from": "collaboratore010",
        "to": "collaboratore301"
    },
    {
        "from": "collaboratore010",
        "to": "collaboratore058"
    },
    {
        "from": "collaboratore010",
        "to": "collaboratore108"
    },
    {
        "from": "collaboratore010",
        "to": "collaboratore287"
    },
    {
        "from": "collaboratore010",
        "to": "collaboratore038"
    },
    {
        "from": "collaboratore007",
        "to": "collaboratore072"
    },
    {
        "from": "collaboratore007",
        "to": "collaboratore305"
    },
    {
        "from": "collaboratore007",
        "to": "collaboratore244"
    },
    {
        "from": "collaboratore007",
        "to": "collaboratore186"
    },
    {
        "from": "collaboratore007",
        "to": "collaboratore143"
    },
    {
        "from": "collaboratore007",
        "to": "collaboratore246"
    },
    {
        "from": "collaboratore007",
        "to": "collaboratore225"
    },
    {
        "from": "collaboratore007",
        "to": "collaboratore242"
    },
    {
        "from": "collaboratore007",
        "to": "collaboratore120"
    },
    {
        "from": "collaboratore007",
        "to": "collaboratore291"
    },
    {
        "from": "collaboratore271",
        "to": "collaboratore126"
    },
    {
        "from": "collaboratore271",
        "to": "collaboratore175"
    },
    {
        "from": "collaboratore271",
        "to": "collaboratore161"
    },
    {
        "from": "collaboratore271",
        "to": "collaboratore283"
    },
    {
        "from": "collaboratore271",
        "to": "collaboratore151"
    },
    {
        "from": "collaboratore271",
        "to": "collaboratore134"
    },
    {
        "from": "collaboratore271",
        "to": "collaboratore185"
    },
    {
        "from": "collaboratore271",
        "to": "collaboratore180"
    },
    {
        "from": "collaboratore271",
        "to": "collaboratore116"
    },
    {
        "from": "collaboratore271",
        "to": "collaboratore002"
    },
    {
        "from": "collaboratore044",
        "to": "collaboratore111"
    },
    {
        "from": "collaboratore044",
        "to": "collaboratore186"
    },
    {
        "from": "collaboratore044",
        "to": "collaboratore015"
    },
    {
        "from": "collaboratore044",
        "to": "collaboratore300"
    },
    {
        "from": "collaboratore044",
        "to": "collaboratore046"
    },
    {
        "from": "collaboratore044",
        "to": "collaboratore144"
    },
    {
        "from": "collaboratore044",
        "to": "collaboratore091"
    },
    {
        "from": "collaboratore044",
        "to": "collaboratore011"
    },
    {
        "from": "collaboratore044",
        "to": "collaboratore116"
    },
    {
        "from": "collaboratore044",
        "to": "collaboratore322"
    },
    {
        "from": "collaboratore064",
        "to": "collaboratore173"
    },
    {
        "from": "collaboratore064",
        "to": "collaboratore074"
    },
    {
        "from": "collaboratore064",
        "to": "collaboratore078"
    },
    {
        "from": "collaboratore064",
        "to": "collaboratore018"
    },
    {
        "from": "collaboratore064",
        "to": "collaboratore209"
    },
    {
        "from": "collaboratore064",
        "to": "collaboratore238"
    },
    {
        "from": "collaboratore064",
        "to": "collaboratore183"
    },
    {
        "from": "collaboratore203",
        "to": "collaboratore042"
    },
    {
        "from": "collaboratore203",
        "to": "collaboratore140"
    },
    {
        "from": "collaboratore203",
        "to": "collaboratore079"
    },
    {
        "from": "collaboratore203",
        "to": "collaboratore176"
    },
    {
        "from": "collaboratore203",
        "to": "collaboratore086"
    },
    {
        "from": "collaboratore203",
        "to": "collaboratore159"
    },
    {
        "from": "collaboratore203",
        "to": "collaboratore288"
    },
    {
        "from": "collaboratore203",
        "to": "collaboratore149"
    },
    {
        "from": "collaboratore203",
        "to": "collaboratore197"
    },
    {
        "from": "collaboratore203",
        "to": "collaboratore211"
    },
    {
        "from": "collaboratore092",
        "to": "collaboratore074"
    },
    {
        "from": "collaboratore092",
        "to": "collaboratore107"
    },
    {
        "from": "collaboratore092",
        "to": "collaboratore078"
    },
    {
        "from": "collaboratore092",
        "to": "collaboratore018"
    },
    {
        "from": "collaboratore092",
        "to": "collaboratore214"
    },
    {
        "from": "collaboratore092",
        "to": "collaboratore032"
    },
    {
        "from": "collaboratore092",
        "to": "collaboratore052"
    },
    {
        "from": "collaboratore092",
        "to": "collaboratore238"
    },
    {
        "from": "collaboratore092",
        "to": "collaboratore309"
    },
    {
        "from": "collaboratore092",
        "to": "collaboratore150"
    },
    {
        "from": "collaboratore167",
        "to": "collaboratore261"
    },
    {
        "from": "collaboratore167",
        "to": "collaboratore070"
    },
    {
        "from": "collaboratore167",
        "to": "collaboratore173"
    },
    {
        "from": "collaboratore167",
        "to": "collaboratore234"
    },
    {
        "from": "collaboratore167",
        "to": "collaboratore279"
    },
    {
        "from": "collaboratore167",
        "to": "collaboratore204"
    },
    {
        "from": "collaboratore167",
        "to": "collaboratore252"
    },
    {
        "from": "collaboratore167",
        "to": "collaboratore169"
    },
    {
        "from": "collaboratore167",
        "to": "collaboratore071"
    },
    {
        "from": "collaboratore167",
        "to": "collaboratore314"
    },
    {
        "from": "collaboratore061",
        "to": "collaboratore042"
    },
    {
        "from": "collaboratore061",
        "to": "collaboratore030"
    },
    {
        "from": "collaboratore061",
        "to": "collaboratore172"
    },
    {
        "from": "collaboratore061",
        "to": "collaboratore159"
    },
    {
        "from": "collaboratore061",
        "to": "collaboratore075"
    },
    {
        "from": "collaboratore061",
        "to": "collaboratore178"
    },
    {
        "from": "collaboratore159",
        "to": "collaboratore042"
    },
    {
        "from": "collaboratore159",
        "to": "collaboratore105"
    },
    {
        "from": "collaboratore159",
        "to": "collaboratore270"
    },
    {
        "from": "collaboratore159",
        "to": "collaboratore030"
    },
    {
        "from": "collaboratore159",
        "to": "collaboratore060"
    },
    {
        "from": "collaboratore159",
        "to": "collaboratore203"
    },
    {
        "from": "collaboratore159",
        "to": "collaboratore287"
    },
    {
        "from": "collaboratore159",
        "to": "collaboratore075"
    },
    {
        "from": "collaboratore159",
        "to": "collaboratore178"
    },
    {
        "from": "collaboratore159",
        "to": "collaboratore154"
    },
    {
        "from": "collaboratore300",
        "to": "collaboratore072"
    },
    {
        "from": "collaboratore300",
        "to": "collaboratore111"
    },
    {
        "from": "collaboratore300",
        "to": "collaboratore162"
    },
    {
        "from": "collaboratore300",
        "to": "collaboratore273"
    },
    {
        "from": "collaboratore300",
        "to": "collaboratore044"
    },
    {
        "from": "collaboratore300",
        "to": "collaboratore259"
    },
    {
        "from": "collaboratore300",
        "to": "collaboratore091"
    },
    {
        "from": "collaboratore300",
        "to": "collaboratore189"
    },
    {
        "from": "collaboratore300",
        "to": "collaboratore011"
    },
    {
        "from": "collaboratore300",
        "to": "collaboratore232"
    },
    {
        "from": "collaboratore214",
        "to": "collaboratore226"
    },
    {
        "from": "collaboratore214",
        "to": "collaboratore117"
    },
    {
        "from": "collaboratore214",
        "to": "collaboratore233"
    },
    {
        "from": "collaboratore214",
        "to": "collaboratore087"
    },
    {
        "from": "collaboratore214",
        "to": "collaboratore076"
    },
    {
        "from": "collaboratore214",
        "to": "collaboratore121"
    },
    {
        "from": "collaboratore016",
        "to": "collaboratore047"
    },
    {
        "from": "collaboratore016",
        "to": "collaboratore185"
    },
    {
        "from": "collaboratore016",
        "to": "collaboratore311"
    },
    {
        "from": "collaboratore016",
        "to": "collaboratore272"
    },
    {
        "from": "collaboratore016",
        "to": "collaboratore116"
    },
    {
        "from": "collaboratore016",
        "to": "collaboratore122"
    },
    {
        "from": "collaboratore257",
        "to": "collaboratore324"
    },
    {
        "from": "collaboratore257",
        "to": "collaboratore262"
    },
    {
        "from": "collaboratore257",
        "to": "collaboratore198"
    },
    {
        "from": "collaboratore257",
        "to": "collaboratore187"
    },
    {
        "from": "collaboratore257",
        "to": "collaboratore089"
    },
    {
        "from": "collaboratore257",
        "to": "collaboratore276"
    },
    {
        "from": "collaboratore257",
        "to": "collaboratore084"
    },
    {
        "from": "collaboratore141",
        "to": "collaboratore162"
    },
    {
        "from": "collaboratore141",
        "to": "collaboratore015"
    },
    {
        "from": "collaboratore141",
        "to": "collaboratore263"
    },
    {
        "from": "collaboratore141",
        "to": "collaboratore262"
    },
    {
        "from": "collaboratore141",
        "to": "collaboratore298"
    },
    {
        "from": "collaboratore141",
        "to": "collaboratore185"
    },
    {
        "from": "collaboratore141",
        "to": "collaboratore100"
    },
    {
        "from": "collaboratore141",
        "to": "collaboratore095"
    },
    {
        "from": "collaboratore141",
        "to": "collaboratore307"
    },
    {
        "from": "collaboratore141",
        "to": "collaboratore272"
    },
    {
        "from": "collaboratore070",
        "to": "collaboratore261"
    },
    {
        "from": "collaboratore070",
        "to": "collaboratore173"
    },
    {
        "from": "collaboratore070",
        "to": "collaboratore234"
    },
    {
        "from": "collaboratore070",
        "to": "collaboratore251"
    },
    {
        "from": "collaboratore070",
        "to": "collaboratore074"
    },
    {
        "from": "collaboratore070",
        "to": "collaboratore201"
    },
    {
        "from": "collaboratore070",
        "to": "collaboratore018"
    },
    {
        "from": "collaboratore070",
        "to": "collaboratore252"
    },
    {
        "from": "collaboratore070",
        "to": "collaboratore081"
    },
    {
        "from": "collaboratore070",
        "to": "collaboratore314"
    },
    {
        "from": "collaboratore311",
        "to": "collaboratore244"
    },
    {
        "from": "collaboratore311",
        "to": "collaboratore015"
    },
    {
        "from": "collaboratore311",
        "to": "collaboratore047"
    },
    {
        "from": "collaboratore311",
        "to": "collaboratore016"
    },
    {
        "from": "collaboratore311",
        "to": "collaboratore090"
    },
    {
        "from": "collaboratore311",
        "to": "collaboratore223"
    },
    {
        "from": "collaboratore311",
        "to": "collaboratore185"
    },
    {
        "from": "collaboratore311",
        "to": "collaboratore272"
    },
    {
        "from": "collaboratore311",
        "to": "collaboratore135"
    },
    {
        "from": "collaboratore161",
        "to": "collaboratore162"
    },
    {
        "from": "collaboratore161",
        "to": "collaboratore283"
    },
    {
        "from": "collaboratore161",
        "to": "collaboratore151"
    },
    {
        "from": "collaboratore161",
        "to": "collaboratore134"
    },
    {
        "from": "collaboratore161",
        "to": "collaboratore185"
    },
    {
        "from": "collaboratore161",
        "to": "collaboratore086"
    },
    {
        "from": "collaboratore161",
        "to": "collaboratore012"
    },
    {
        "from": "collaboratore161",
        "to": "collaboratore180"
    },
    {
        "from": "collaboratore161",
        "to": "collaboratore122"
    },
    {
        "from": "collaboratore005",
        "to": "collaboratore263"
    },
    {
        "from": "collaboratore005",
        "to": "collaboratore289"
    },
    {
        "from": "collaboratore005",
        "to": "collaboratore200"
    },
    {
        "from": "collaboratore005",
        "to": "collaboratore145"
    },
    {
        "from": "collaboratore005",
        "to": "collaboratore138"
    },
    {
        "from": "collaboratore005",
        "to": "collaboratore297"
    },
    {
        "from": "collaboratore005",
        "to": "collaboratore325"
    },
    {
        "from": "collaboratore005",
        "to": "collaboratore221"
    },
    {
        "from": "collaboratore005",
        "to": "collaboratore312"
    },
    {
        "from": "collaboratore005",
        "to": "collaboratore122"
    },
    {
        "from": "collaboratore145",
        "to": "collaboratore005"
    },
    {
        "from": "collaboratore145",
        "to": "collaboratore042"
    },
    {
        "from": "collaboratore145",
        "to": "collaboratore088"
    },
    {
        "from": "collaboratore145",
        "to": "collaboratore297"
    },
    {
        "from": "collaboratore145",
        "to": "collaboratore140"
    },
    {
        "from": "collaboratore145",
        "to": "collaboratore325"
    },
    {
        "from": "collaboratore145",
        "to": "collaboratore280"
    },
    {
        "from": "collaboratore145",
        "to": "collaboratore130"
    },
    {
        "from": "collaboratore145",
        "to": "collaboratore095"
    },
    {
        "from": "collaboratore145",
        "to": "collaboratore284"
    },
    {
        "from": "collaboratore116",
        "to": "collaboratore271"
    },
    {
        "from": "collaboratore116",
        "to": "collaboratore162"
    },
    {
        "from": "collaboratore116",
        "to": "collaboratore107"
    },
    {
        "from": "collaboratore116",
        "to": "collaboratore283"
    },
    {
        "from": "collaboratore116",
        "to": "collaboratore151"
    },
    {
        "from": "collaboratore116",
        "to": "collaboratore298"
    },
    {
        "from": "collaboratore116",
        "to": "collaboratore185"
    },
    {
        "from": "collaboratore116",
        "to": "collaboratore081"
    },
    {
        "from": "collaboratore116",
        "to": "collaboratore122"
    },
    {
        "from": "collaboratore116",
        "to": "collaboratore002"
    },
    {
        "from": "collaboratore280",
        "to": "collaboratore184"
    },
    {
        "from": "collaboratore280",
        "to": "collaboratore051"
    },
    {
        "from": "collaboratore280",
        "to": "collaboratore179"
    },
    {
        "from": "collaboratore280",
        "to": "collaboratore019"
    },
    {
        "from": "collaboratore280",
        "to": "collaboratore145"
    },
    {
        "from": "collaboratore280",
        "to": "collaboratore147"
    },
    {
        "from": "collaboratore280",
        "to": "collaboratore313"
    },
    {
        "from": "collaboratore280",
        "to": "collaboratore082"
    },
    {
        "from": "collaboratore280",
        "to": "collaboratore219"
    },
    {
        "from": "collaboratore050",
        "to": "collaboratore094"
    },
    {
        "from": "collaboratore050",
        "to": "collaboratore129"
    },
    {
        "from": "collaboratore050",
        "to": "collaboratore281"
    },
    {
        "from": "collaboratore050",
        "to": "collaboratore200"
    },
    {
        "from": "collaboratore050",
        "to": "collaboratore240"
    },
    {
        "from": "collaboratore050",
        "to": "collaboratore041"
    },
    {
        "from": "collaboratore050",
        "to": "collaboratore140"
    },
    {
        "from": "collaboratore050",
        "to": "collaboratore031"
    },
    {
        "from": "collaboratore050",
        "to": "collaboratore276"
    },
    {
        "from": "collaboratore050",
        "to": "collaboratore040"
    },
    {
        "from": "collaboratore206",
        "to": "collaboratore123"
    },
    {
        "from": "collaboratore206",
        "to": "collaboratore139"
    },
    {
        "from": "collaboratore206",
        "to": "collaboratore029"
    },
    {
        "from": "collaboratore206",
        "to": "collaboratore220"
    },
    {
        "from": "collaboratore206",
        "to": "collaboratore228"
    },
    {
        "from": "collaboratore206",
        "to": "collaboratore290"
    },
    {
        "from": "collaboratore028",
        "to": "collaboratore015"
    },
    {
        "from": "collaboratore028",
        "to": "collaboratore044"
    },
    {
        "from": "collaboratore028",
        "to": "collaboratore262"
    },
    {
        "from": "collaboratore028",
        "to": "collaboratore216"
    },
    {
        "from": "collaboratore028",
        "to": "collaboratore298"
    },
    {
        "from": "collaboratore028",
        "to": "collaboratore066"
    },
    {
        "from": "collaboratore028",
        "to": "collaboratore307"
    },
    {
        "from": "collaboratore028",
        "to": "collaboratore089"
    },
    {
        "from": "collaboratore028",
        "to": "collaboratore116"
    },
    {
        "from": "collaboratore028",
        "to": "collaboratore141"
    },
    {
        "from": "collaboratore219",
        "to": "collaboratore303"
    },
    {
        "from": "collaboratore219",
        "to": "collaboratore184"
    },
    {
        "from": "collaboratore219",
        "to": "collaboratore131"
    },
    {
        "from": "collaboratore219",
        "to": "collaboratore051"
    },
    {
        "from": "collaboratore219",
        "to": "collaboratore179"
    },
    {
        "from": "collaboratore219",
        "to": "collaboratore019"
    },
    {
        "from": "collaboratore219",
        "to": "collaboratore280"
    },
    {
        "from": "collaboratore219",
        "to": "collaboratore313"
    },
    {
        "from": "collaboratore219",
        "to": "collaboratore082"
    },
    {
        "from": "collaboratore219",
        "to": "collaboratore247"
    },
    {
        "from": "collaboratore029",
        "to": "collaboratore315"
    },
    {
        "from": "collaboratore029",
        "to": "collaboratore139"
    },
    {
        "from": "collaboratore029",
        "to": "collaboratore026"
    },
    {
        "from": "collaboratore029",
        "to": "collaboratore049"
    },
    {
        "from": "collaboratore029",
        "to": "collaboratore011"
    },
    {
        "from": "collaboratore029",
        "to": "collaboratore228"
    },
    {
        "from": "collaboratore029",
        "to": "collaboratore157"
    },
    {
        "from": "collaboratore029",
        "to": "collaboratore068"
    },
    {
        "from": "collaboratore029",
        "to": "collaboratore290"
    },
    {
        "from": "collaboratore029",
        "to": "collaboratore059"
    },
    {
        "from": "collaboratore119",
        "to": "collaboratore006"
    },
    {
        "from": "collaboratore119",
        "to": "collaboratore327"
    },
    {
        "from": "collaboratore119",
        "to": "collaboratore166"
    },
    {
        "from": "collaboratore119",
        "to": "collaboratore139"
    },
    {
        "from": "collaboratore119",
        "to": "collaboratore159"
    },
    {
        "from": "collaboratore119",
        "to": "collaboratore228"
    },
    {
        "from": "collaboratore119",
        "to": "collaboratore076"
    },
    {
        "from": "collaboratore119",
        "to": "collaboratore290"
    },
    {
        "from": "collaboratore119",
        "to": "collaboratore150"
    },
    {
        "from": "collaboratore098",
        "to": "collaboratore005"
    },
    {
        "from": "collaboratore098",
        "to": "collaboratore136"
    },
    {
        "from": "collaboratore098",
        "to": "collaboratore166"
    },
    {
        "from": "collaboratore098",
        "to": "collaboratore200"
    },
    {
        "from": "collaboratore098",
        "to": "collaboratore145"
    },
    {
        "from": "collaboratore098",
        "to": "collaboratore325"
    },
    {
        "from": "collaboratore098",
        "to": "collaboratore029"
    },
    {
        "from": "collaboratore098",
        "to": "collaboratore147"
    },
    {
        "from": "collaboratore098",
        "to": "collaboratore100"
    },
    {
        "from": "collaboratore098",
        "to": "collaboratore146"
    },
    {
        "from": "collaboratore322",
        "to": "collaboratore158"
    },
    {
        "from": "collaboratore322",
        "to": "collaboratore044"
    },
    {
        "from": "collaboratore322",
        "to": "collaboratore144"
    },
    {
        "from": "collaboratore322",
        "to": "collaboratore214"
    },
    {
        "from": "collaboratore322",
        "to": "collaboratore241"
    },
    {
        "from": "collaboratore322",
        "to": "collaboratore066"
    },
    {
        "from": "collaboratore322",
        "to": "collaboratore256"
    },
    {
        "from": "collaboratore322",
        "to": "collaboratore233"
    },
    {
        "from": "collaboratore322",
        "to": "collaboratore116"
    },
    {
        "from": "collaboratore322",
        "to": "collaboratore081"
    },
    {
        "from": "collaboratore132",
        "to": "collaboratore262"
    },
    {
        "from": "collaboratore132",
        "to": "collaboratore144"
    },
    {
        "from": "collaboratore132",
        "to": "collaboratore078"
    },
    {
        "from": "collaboratore132",
        "to": "collaboratore216"
    },
    {
        "from": "collaboratore132",
        "to": "collaboratore134"
    },
    {
        "from": "collaboratore132",
        "to": "collaboratore298"
    },
    {
        "from": "collaboratore132",
        "to": "collaboratore153"
    },
    {
        "from": "collaboratore213",
        "to": "collaboratore281"
    },
    {
        "from": "collaboratore213",
        "to": "collaboratore289"
    },
    {
        "from": "collaboratore213",
        "to": "collaboratore060"
    },
    {
        "from": "collaboratore213",
        "to": "collaboratore230"
    },
    {
        "from": "collaboratore213",
        "to": "collaboratore097"
    },
    {
        "from": "collaboratore213",
        "to": "collaboratore238"
    },
    {
        "from": "collaboratore213",
        "to": "collaboratore228"
    },
    {
        "from": "collaboratore213",
        "to": "collaboratore278"
    },
    {
        "from": "collaboratore213",
        "to": "collaboratore001"
    },
    {
        "from": "collaboratore314",
        "to": "collaboratore126"
    },
    {
        "from": "collaboratore314",
        "to": "collaboratore025"
    },
    {
        "from": "collaboratore314",
        "to": "collaboratore251"
    },
    {
        "from": "collaboratore314",
        "to": "collaboratore279"
    },
    {
        "from": "collaboratore314",
        "to": "collaboratore201"
    },
    {
        "from": "collaboratore314",
        "to": "collaboratore190"
    },
    {
        "from": "collaboratore314",
        "to": "collaboratore204"
    },
    {
        "from": "collaboratore314",
        "to": "collaboratore252"
    },
    {
        "from": "collaboratore314",
        "to": "collaboratore169"
    },
    {
        "from": "collaboratore314",
        "to": "collaboratore053"
    },
    {
        "from": "collaboratore131",
        "to": "collaboratore184"
    },
    {
        "from": "collaboratore131",
        "to": "collaboratore051"
    },
    {
        "from": "collaboratore131",
        "to": "collaboratore179"
    },
    {
        "from": "collaboratore131",
        "to": "collaboratore019"
    },
    {
        "from": "collaboratore131",
        "to": "collaboratore280"
    },
    {
        "from": "collaboratore131",
        "to": "collaboratore147"
    },
    {
        "from": "collaboratore131",
        "to": "collaboratore313"
    },
    {
        "from": "collaboratore131",
        "to": "collaboratore082"
    },
    {
        "from": "collaboratore131",
        "to": "collaboratore219"
    },
    {
        "from": "collaboratore212",
        "to": "collaboratore142"
    },
    {
        "from": "collaboratore212",
        "to": "collaboratore005"
    },
    {
        "from": "collaboratore212",
        "to": "collaboratore136"
    },
    {
        "from": "collaboratore212",
        "to": "collaboratore033"
    },
    {
        "from": "collaboratore212",
        "to": "collaboratore088"
    },
    {
        "from": "collaboratore212",
        "to": "collaboratore019"
    },
    {
        "from": "collaboratore212",
        "to": "collaboratore297"
    },
    {
        "from": "collaboratore212",
        "to": "collaboratore224"
    },
    {
        "from": "collaboratore212",
        "to": "collaboratore045"
    },
    {
        "from": "collaboratore212",
        "to": "collaboratore040"
    },
    {
        "from": "collaboratore083",
        "to": "collaboratore256"
    },
    {
        "from": "collaboratore083",
        "to": "collaboratore233"
    },
    {
        "from": "collaboratore083",
        "to": "collaboratore285"
    },
    {
        "from": "collaboratore083",
        "to": "collaboratore322"
    },
    {
        "from": "collaboratore083",
        "to": "collaboratore081"
    },
    {
        "from": "collaboratore083",
        "to": "collaboratore002"
    },
    {
        "from": "collaboratore258",
        "to": "collaboratore213"
    },
    {
        "from": "collaboratore258",
        "to": "collaboratore327"
    },
    {
        "from": "collaboratore258",
        "to": "collaboratore306"
    },
    {
        "from": "collaboratore258",
        "to": "collaboratore139"
    },
    {
        "from": "collaboratore258",
        "to": "collaboratore037"
    },
    {
        "from": "collaboratore258",
        "to": "collaboratore228"
    },
    {
        "from": "collaboratore258",
        "to": "collaboratore290"
    },
    {
        "from": "collaboratore238",
        "to": "collaboratore173"
    },
    {
        "from": "collaboratore238",
        "to": "collaboratore137"
    },
    {
        "from": "collaboratore238",
        "to": "collaboratore274"
    },
    {
        "from": "collaboratore238",
        "to": "collaboratore052"
    },
    {
        "from": "collaboratore238",
        "to": "collaboratore304"
    },
    {
        "from": "collaboratore238",
        "to": "collaboratore095"
    },
    {
        "from": "collaboratore238",
        "to": "collaboratore247"
    },
    {
        "from": "collaboratore238",
        "to": "collaboratore157"
    },
    {
        "from": "collaboratore238",
        "to": "collaboratore226"
    },
    {
        "from": "collaboratore238",
        "to": "collaboratore117"
    }
]

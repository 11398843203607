import Filters from "../components/Filters";

const Analytics = () => {
    return (
        <>
            <div className="container-fluid px-0">
                <Filters filters={['Tasso di partecipazione', 'Tempi di compilazione', 'Distribuzione temporale delle risposte']}></Filters>
            </div>
        </>
    )
}

export default Analytics
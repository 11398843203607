const DistribuzioneRisposte = () => {
    return (
        <>
            <div className="container mt-3">
                <div className="box">
                    Distribuzione risposte
                </div>
            </div>
        </>
    )

}

export default DistribuzioneRisposte
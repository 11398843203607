export const benessere = [
    {
        "score": 3.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede2",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore064",
        "full_name": "collaboratore064"
    },
    {
        "score": 2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "Meno di un anno",
        "fullname": "collaboratore318",
        "full_name": "collaboratore318"
    },
    {
        "score": 3.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore148",
        "full_name": "collaboratore148"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore211",
        "full_name": "collaboratore211"
    },
    {
        "score": 2.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore123",
        "full_name": "collaboratore123"
    },
    {
        "score": 3,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore208",
        "full_name": "collaboratore208"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede2",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore096",
        "full_name": "collaboratore096"
    },
    {
        "score": 4.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro5",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore233",
        "full_name": "collaboratore233"
    },
    {
        "score": 2.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede2",
        "qualifica": "Quadro",
        "centro": "centro2",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore190",
        "full_name": "collaboratore190"
    },
    {
        "score": 2.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore121",
        "full_name": "collaboratore121"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore142",
        "full_name": "collaboratore142"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore055",
        "full_name": "collaboratore055"
    },
    {
        "score": 5,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore206",
        "full_name": "collaboratore206"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro9",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore016",
        "full_name": "collaboratore016"
    },
    {
        "score": 3.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro1",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore276",
        "full_name": "collaboratore276"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede2",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore101",
        "full_name": "collaboratore101"
    },
    {
        "score": 3,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro3",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore073",
        "full_name": "collaboratore073"
    },
    {
        "score": 3.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore128",
        "full_name": "collaboratore128"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro7",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore138",
        "full_name": "collaboratore138"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore021",
        "full_name": "collaboratore021"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro9",
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore022",
        "full_name": "collaboratore022"
    },
    {
        "score": 3.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro2",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore249",
        "full_name": "collaboratore249"
    },
    {
        "score": 5,
        "costrutto": "who5",
        "sede_di_lavoro": "sede2",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore126",
        "full_name": "collaboratore126"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore242",
        "full_name": "collaboratore242"
    },
    {
        "score": 4.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro4",
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore187",
        "full_name": "collaboratore187"
    },
    {
        "score": 6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro5",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore083",
        "full_name": "collaboratore083"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore063",
        "full_name": "collaboratore063"
    },
    {
        "score": 5,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore097",
        "full_name": "collaboratore097"
    },
    {
        "score": 3.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede2",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore251",
        "full_name": "collaboratore251"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro3",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore026",
        "full_name": "collaboratore026"
    },
    {
        "score": 3,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore213",
        "full_name": "collaboratore213"
    },
    {
        "score": 3.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore158",
        "full_name": "collaboratore158"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro4",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore215",
        "full_name": "collaboratore215"
    },
    {
        "score": 4.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore218",
        "full_name": "collaboratore218"
    },
    {
        "score": 5,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro1",
        "gender": "Maschile",
        "tenure": "Meno di un anno",
        "fullname": "collaboratore028",
        "full_name": "collaboratore028"
    },
    {
        "score": 5,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore008",
        "full_name": "collaboratore008"
    },
    {
        "score": 2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede2",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore035",
        "full_name": "collaboratore035"
    },
    {
        "score": 4.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore255",
        "full_name": "collaboratore255"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro4",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore009",
        "full_name": "collaboratore009"
    },
    {
        "score": 2.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore149",
        "full_name": "collaboratore149"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro1",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore144",
        "full_name": "collaboratore144"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore004",
        "full_name": "collaboratore004"
    },
    {
        "score": 2.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro7",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore140",
        "full_name": "collaboratore140"
    },
    {
        "score": 4.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore127",
        "full_name": "collaboratore127"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede2",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore025",
        "full_name": "collaboratore025"
    },
    {
        "score": 4.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede2",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore074",
        "full_name": "collaboratore074"
    },
    {
        "score": 3.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore273",
        "full_name": "collaboratore273"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore278",
        "full_name": "collaboratore278"
    },
    {
        "score": 3.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro1",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore175",
        "full_name": "collaboratore175"
    },
    {
        "score": 4.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore219",
        "full_name": "collaboratore219"
    },
    {
        "score": 4.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore319",
        "full_name": "collaboratore319"
    },
    {
        "score": 5,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore003",
        "full_name": "collaboratore003"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore258",
        "full_name": "collaboratore258"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore104",
        "full_name": "collaboratore104"
    },
    {
        "score": 3.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore326",
        "full_name": "collaboratore326"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro9",
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "fullname": "collaboratore116",
        "full_name": "collaboratore116"
    },
    {
        "score": 3.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore106",
        "full_name": "collaboratore106"
    },
    {
        "score": 2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore152",
        "full_name": "collaboratore152"
    },
    {
        "score": 2.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore226",
        "full_name": "collaboratore226"
    },
    {
        "score": 3.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro2",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore220",
        "full_name": "collaboratore220"
    },
    {
        "score": 3.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore191",
        "full_name": "collaboratore191"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore109",
        "full_name": "collaboratore109"
    },
    {
        "score": 6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore292",
        "full_name": "collaboratore292"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore299",
        "full_name": "collaboratore299"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore304",
        "full_name": "collaboratore304"
    },
    {
        "score": 4.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore120",
        "full_name": "collaboratore120"
    },
    {
        "score": 5.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore214",
        "full_name": "collaboratore214"
    },
    {
        "score": 3.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore178",
        "full_name": "collaboratore178"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro4",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore080",
        "full_name": "collaboratore080"
    },
    {
        "score": 3.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore176",
        "full_name": "collaboratore176"
    },
    {
        "score": 5.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore133",
        "full_name": "collaboratore133"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "fullname": "collaboratore072",
        "full_name": "collaboratore072"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede2",
        "qualifica": "Impiegato",
        "centro": "centro5",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore070",
        "full_name": "collaboratore070"
    },
    {
        "score": 3.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore247",
        "full_name": "collaboratore247"
    },
    {
        "score": 4.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Personale non subordinato",
        "centro": "centro5",
        "gender": "Maschile",
        "tenure": "Meno di un anno",
        "fullname": "collaboratore322",
        "full_name": "collaboratore322"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore289",
        "full_name": "collaboratore289"
    },
    {
        "score": 5.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore312",
        "full_name": "collaboratore312"
    },
    {
        "score": 4.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro9",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore122",
        "full_name": "collaboratore122"
    },
    {
        "score": 4.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "fullname": "collaboratore313",
        "full_name": "collaboratore313"
    },
    {
        "score": 5,
        "costrutto": "who5",
        "sede_di_lavoro": "sede2",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore167",
        "full_name": "collaboratore167"
    },
    {
        "score": 1.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore103",
        "full_name": "collaboratore103"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore062",
        "full_name": "collaboratore062"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede2",
        "qualifica": "Impiegato",
        "centro": "centro5",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore234",
        "full_name": "collaboratore234"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro5",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore285",
        "full_name": "collaboratore285"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro9",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore002",
        "full_name": "collaboratore002"
    },
    {
        "score": 3.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro9",
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore135",
        "full_name": "collaboratore135"
    },
    {
        "score": 2.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore188",
        "full_name": "collaboratore188"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore287",
        "full_name": "collaboratore287"
    },
    {
        "score": 5,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore060",
        "full_name": "collaboratore060"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore171",
        "full_name": "collaboratore171"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore029",
        "full_name": "collaboratore029"
    },
    {
        "score": 3.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro9",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore272",
        "full_name": "collaboratore272"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore005",
        "full_name": "collaboratore005"
    },
    {
        "score": 3,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore119",
        "full_name": "collaboratore119"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore075",
        "full_name": "collaboratore075"
    },
    {
        "score": 3.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro8",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore159",
        "full_name": "collaboratore159"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro8",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore306",
        "full_name": "collaboratore306"
    },
    {
        "score": 5,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore007",
        "full_name": "collaboratore007"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore212",
        "full_name": "collaboratore212"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore087",
        "full_name": "collaboratore087"
    },
    {
        "score": 2.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro9",
        "gender": "Femminile",
        "tenure": "Meno di un anno",
        "fullname": "collaboratore180",
        "full_name": "collaboratore180"
    },
    {
        "score": 4.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro5",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore201",
        "full_name": "collaboratore201"
    },
    {
        "score": 5,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Dirigente",
        "centro": "centro1",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore262",
        "full_name": "collaboratore262"
    },
    {
        "score": 6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro3",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore046",
        "full_name": "collaboratore046"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore102",
        "full_name": "collaboratore102"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore010",
        "full_name": "collaboratore010"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore001",
        "full_name": "collaboratore001"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore290",
        "full_name": "collaboratore290"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore163",
        "full_name": "collaboratore163"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore321",
        "full_name": "collaboratore321"
    },
    {
        "score": 3.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore192",
        "full_name": "collaboratore192"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro7",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore297",
        "full_name": "collaboratore297"
    },
    {
        "score": 3.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore086",
        "full_name": "collaboratore086"
    },
    {
        "score": 4.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore056",
        "full_name": "collaboratore056"
    },
    {
        "score": 4.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro9",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore271",
        "full_name": "collaboratore271"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore284",
        "full_name": "collaboratore284"
    },
    {
        "score": 3.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore210",
        "full_name": "collaboratore210"
    },
    {
        "score": 4.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro7",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore268",
        "full_name": "collaboratore268"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro9",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore283",
        "full_name": "collaboratore283"
    },
    {
        "score": 4.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Operaio",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore111",
        "full_name": "collaboratore111"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore093",
        "full_name": "collaboratore093"
    },
    {
        "score": 3.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore037",
        "full_name": "collaboratore037"
    },
    {
        "score": 3.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede2",
        "qualifica": "Impiegato",
        "centro": "centro5",
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore277",
        "full_name": "collaboratore277"
    },
    {
        "score": 3,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro1",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore298",
        "full_name": "collaboratore298"
    },
    {
        "score": 3.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro4",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore241",
        "full_name": "collaboratore241"
    },
    {
        "score": 2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore237",
        "full_name": "collaboratore237"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede2",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore252",
        "full_name": "collaboratore252"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede2",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore114",
        "full_name": "collaboratore114"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore238",
        "full_name": "collaboratore238"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore217",
        "full_name": "collaboratore217"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore291",
        "full_name": "collaboratore291"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore181",
        "full_name": "collaboratore181"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore225",
        "full_name": "collaboratore225"
    },
    {
        "score": 2.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore117",
        "full_name": "collaboratore117"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro6",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore141",
        "full_name": "collaboratore141"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore124",
        "full_name": "collaboratore124"
    },
    {
        "score": 3,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro1",
        "gender": "Femminile",
        "tenure": "Meno di un anno",
        "fullname": "collaboratore089",
        "full_name": "collaboratore089"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore136",
        "full_name": "collaboratore136"
    },
    {
        "score": 4.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro1",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore324",
        "full_name": "collaboratore324"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore030",
        "full_name": "collaboratore030"
    },
    {
        "score": 2.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro9",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore047",
        "full_name": "collaboratore047"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "Meno di un anno",
        "fullname": "collaboratore098",
        "full_name": "collaboratore098"
    },
    {
        "score": 3.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro3",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore014",
        "full_name": "collaboratore014"
    },
    {
        "score": 4.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore309",
        "full_name": "collaboratore309"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore170",
        "full_name": "collaboratore170"
    },
    {
        "score": 3.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore280",
        "full_name": "collaboratore280"
    },
    {
        "score": 4.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro1",
        "gender": "Femminile",
        "tenure": "Meno di un anno",
        "fullname": "collaboratore257",
        "full_name": "collaboratore257"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore077",
        "full_name": "collaboratore077"
    },
    {
        "score": 4.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore254",
        "full_name": "collaboratore254"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro1",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore216",
        "full_name": "collaboratore216"
    },
    {
        "score": 4.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro5",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore256",
        "full_name": "collaboratore256"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Dirigente",
        "centro": "centro9",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore185",
        "full_name": "collaboratore185"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Dirigente",
        "centro": "centro4",
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "fullname": "collaboratore263",
        "full_name": "collaboratore263"
    },
    {
        "score": 4.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Dirigente",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore095",
        "full_name": "collaboratore095"
    },
    {
        "score": 3.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore147",
        "full_name": "collaboratore147"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore013",
        "full_name": "collaboratore013"
    },
    {
        "score": 4.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Femminile",
        "tenure": "Meno di un anno",
        "fullname": "collaboratore113",
        "full_name": "collaboratore113"
    },
    {
        "score": 4.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore099",
        "full_name": "collaboratore099"
    },
    {
        "score": 2.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro1",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore323",
        "full_name": "collaboratore323"
    },
    {
        "score": 2.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore068",
        "full_name": "collaboratore068"
    },
    {
        "score": 3.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore166",
        "full_name": "collaboratore166"
    },
    {
        "score": 4.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore193",
        "full_name": "collaboratore193"
    },
    {
        "score": 4.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore165",
        "full_name": "collaboratore165"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede2",
        "qualifica": "Quadro",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore314",
        "full_name": "collaboratore314"
    },
    {
        "score": 4.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore051",
        "full_name": "collaboratore051"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro4",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore244",
        "full_name": "collaboratore244"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore129",
        "full_name": "collaboratore129"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "Meno di un anno",
        "fullname": "collaboratore006",
        "full_name": "collaboratore006"
    },
    {
        "score": 4.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore065",
        "full_name": "collaboratore065"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore024",
        "full_name": "collaboratore024"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore327",
        "full_name": "collaboratore327"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore076",
        "full_name": "collaboratore076"
    },
    {
        "score": 3.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "Meno di un anno",
        "fullname": "collaboratore125",
        "full_name": "collaboratore125"
    },
    {
        "score": 3.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore031",
        "full_name": "collaboratore031"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore186",
        "full_name": "collaboratore186"
    },
    {
        "score": 2.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro9",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore134",
        "full_name": "collaboratore134"
    },
    {
        "score": 4.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore264",
        "full_name": "collaboratore264"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore179",
        "full_name": "collaboratore179"
    },
    {
        "score": 1.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore069",
        "full_name": "collaboratore069"
    },
    {
        "score": 4.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore115",
        "full_name": "collaboratore115"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro8",
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore320",
        "full_name": "collaboratore320"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore082",
        "full_name": "collaboratore082"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro3",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore032",
        "full_name": "collaboratore032"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "Meno di un anno",
        "fullname": "collaboratore038",
        "full_name": "collaboratore038"
    },
    {
        "score": 4.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore281",
        "full_name": "collaboratore281"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro5",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore090",
        "full_name": "collaboratore090"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore061",
        "full_name": "collaboratore061"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro9",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore161",
        "full_name": "collaboratore161"
    },
    {
        "score": 3.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore205",
        "full_name": "collaboratore205"
    },
    {
        "score": 2.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore059",
        "full_name": "collaboratore059"
    },
    {
        "score": 5.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Dirigente",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore145",
        "full_name": "collaboratore145"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore027",
        "full_name": "collaboratore027"
    },
    {
        "score": 4.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore154",
        "full_name": "collaboratore154"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "fullname": "collaboratore282",
        "full_name": "collaboratore282"
    },
    {
        "score": 3.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore048",
        "full_name": "collaboratore048"
    },
    {
        "score": 5,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro1",
        "gender": "Femminile",
        "tenure": "Meno di un anno",
        "fullname": "collaboratore066",
        "full_name": "collaboratore066"
    },
    {
        "score": 4.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro1",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore132",
        "full_name": "collaboratore132"
    },
    {
        "score": 5,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore301",
        "full_name": "collaboratore301"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede2",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore207",
        "full_name": "collaboratore207"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore044",
        "full_name": "collaboratore044"
    },
    {
        "score": 2.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore230",
        "full_name": "collaboratore230"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Dirigente",
        "centro": "centro5",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore307",
        "full_name": "collaboratore307"
    },
    {
        "score": 4.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede2",
        "qualifica": "Personale non subordinato",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "Meno di un anno",
        "fullname": "collaboratore209",
        "full_name": "collaboratore209"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro9",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore017",
        "full_name": "collaboratore017"
    },
    {
        "score": 5,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro7",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore184",
        "full_name": "collaboratore184"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore071",
        "full_name": "collaboratore071"
    },
    {
        "score": 3.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede2",
        "qualifica": "Quadro",
        "centro": "centro2",
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore173",
        "full_name": "collaboratore173"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore131",
        "full_name": "collaboratore131"
    },
    {
        "score": 5,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro4",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore023",
        "full_name": "collaboratore023"
    },
    {
        "score": 3.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore094",
        "full_name": "collaboratore094"
    },
    {
        "score": 3.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore240",
        "full_name": "collaboratore240"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore040",
        "full_name": "collaboratore040"
    },
    {
        "score": 2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore232",
        "full_name": "collaboratore232"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore177",
        "full_name": "collaboratore177"
    },
    {
        "score": 3.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore174",
        "full_name": "collaboratore174"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore305",
        "full_name": "collaboratore305"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore146",
        "full_name": "collaboratore146"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro5",
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore081",
        "full_name": "collaboratore081"
    },
    {
        "score": 3.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore196",
        "full_name": "collaboratore196"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore139",
        "full_name": "collaboratore139"
    },
    {
        "score": 4.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore303",
        "full_name": "collaboratore303"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore228",
        "full_name": "collaboratore228"
    },
    {
        "score": 4.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore224",
        "full_name": "collaboratore224"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro8",
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "fullname": "collaboratore203",
        "full_name": "collaboratore203"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore231",
        "full_name": "collaboratore231"
    },
    {
        "score": 1.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede2",
        "qualifica": "Quadro",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore261",
        "full_name": "collaboratore261"
    },
    {
        "score": 3.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro2",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore052",
        "full_name": "collaboratore052"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro5",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore107",
        "full_name": "collaboratore107"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore172",
        "full_name": "collaboratore172"
    },
    {
        "score": 2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore227",
        "full_name": "collaboratore227"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore079",
        "full_name": "collaboratore079"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede2",
        "qualifica": "Impiegato",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "fullname": "collaboratore018",
        "full_name": "collaboratore018"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore155",
        "full_name": "collaboratore155"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro2",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore078",
        "full_name": "collaboratore078"
    },
    {
        "score": 4.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore054",
        "full_name": "collaboratore054"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore143",
        "full_name": "collaboratore143"
    },
    {
        "score": 4.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore112",
        "full_name": "collaboratore112"
    },
    {
        "score": 3.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore012",
        "full_name": "collaboratore012"
    },
    {
        "score": 5,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore050",
        "full_name": "collaboratore050"
    },
    {
        "score": 3.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore105",
        "full_name": "collaboratore105"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore248",
        "full_name": "collaboratore248"
    },
    {
        "score": 4.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro5",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore308",
        "full_name": "collaboratore308"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro6",
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore015",
        "full_name": "collaboratore015"
    },
    {
        "score": 4.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore229",
        "full_name": "collaboratore229"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore295",
        "full_name": "collaboratore295"
    },
    {
        "score": 4.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro4",
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "fullname": "collaboratore100",
        "full_name": "collaboratore100"
    },
    {
        "score": 5,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro7",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore286",
        "full_name": "collaboratore286"
    },
    {
        "score": 3.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro2",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore092",
        "full_name": "collaboratore092"
    },
    {
        "score": 4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro7",
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "fullname": "collaboratore019",
        "full_name": "collaboratore019"
    },
    {
        "score": 5.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro4",
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore221",
        "full_name": "collaboratore221"
    },
    {
        "score": 3.4,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore108",
        "full_name": "collaboratore108"
    },
    {
        "score": 4.2,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro3",
        "gender": "Femminile",
        "tenure": "Meno di un anno",
        "fullname": "collaboratore057",
        "full_name": "collaboratore057"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Quadro",
        "centro": "centro8",
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "fullname": "collaboratore296",
        "full_name": "collaboratore296"
    },
    {
        "score": 3.8,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Operaio",
        "centro": "centro3",
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "fullname": "collaboratore300",
        "full_name": "collaboratore300"
    },
    {
        "score": 4.6,
        "costrutto": "who5",
        "sede_di_lavoro": "sede1",
        "qualifica": "Impiegato",
        "centro": "centro9",
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "fullname": "collaboratore311",
        "full_name": "collaboratore311"
    }
]


import {ButtonWithIcon} from "../components/ButtonWithIcon";

export const Presentazione = () => {
    return (
        <>
            <div className="container-fluid" style={{paddingTop: 120, paddingBottom: 50}}>
                <div className="row align-items-center">
                    <div className="col-lg-6 text-center">
                        <div className="m-auto" style={{maxWidth: 500}}>
                            <div className="number">35</div>
                            <div className="color-blue-dark"
                                 style={{fontSize: '4rem', fontWeight: 'bold', lineHeight: '1.2'}}>Partecipanti invitati
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 text-center">
                        <div className="color-blue-dark m-auto mt-5 mt-lg-0" style={{maxWidth: 500}}>
                            <div className="">
                                <b>Vuoi organizzare una presentazione online via zoom del progetto da parte di un nostro
                                    responsabile in collaborazione con la Direzione per aumentare l’engagement dei
                                    collaboratori?
                                </b>
                            </div>
                            <div className="mt-4">
                                Sarà organizzata una riunione di allineamento della durata di 1 ora e una presentazione
                                al personale (senza limiti di n. Di partecipanti) che potrà essere registrata per essere
                                distribuita off-line
                            </div>
                            <div className="text-center mt-4">
                                <ButtonWithIcon text={'Sì'} />
                                <div className="d-inline-block ms-3">
                                    <ButtonWithIcon bgColor={'transparent'} color={'#05438A'} borderColor={'#05438A'} text={'No'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
